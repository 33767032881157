import { observable, action, runInAction } from 'mobx';
import { IVehicleSummary } from './domain/vehicle-summary';
import { VehiclesApi } from '../services/vehicles-api';
import { Vehicle } from './domain/vehicle';
import notification from '../components/Notification/notification';
import { SuggestionItem } from '../components/Autocomplete/Autocomplete';
import { ScheduleAvailability } from './domain/schedule-availability';
import { DriverTransfer } from './domain/driver-transfer';

export class VehicleStore {

    constructor(private vehiclesApi: VehiclesApi) { }

    @observable public vehicles: IVehicleSummary[] = [];
    @observable public vehicle: Vehicle = new Vehicle({});

    public async searchSpareVehicles(vehicleRouteId: number, query: string) {
        const suggestions = await this.vehiclesApi.searchSpareVehicles(vehicleRouteId, query);
        return suggestions.map(s => {
            return { label: s.registration, value: { ...s, currentUnavailability: [] } } as SuggestionItem<IVehicleSummary>
        })
    }

    /**
     * Returns a list of Schedule Availabilities of available vehicles available for transfer.
     * @param driverTransfer - the driver transfer to query from.
     * @param query - A query string matching the vehicle registration.
     */
    public async searchTransferVehicles(driverTransfer: DriverTransfer, query: string): Promise<ScheduleAvailability[]> {
        const suggestions = await this.vehiclesApi.searchTransferVehicles(driverTransfer, query);
        return suggestions.map(r => ScheduleAvailability.fromResponse(r));
    }

    public async getSpareVehicles(runId: string) {
        const suggestions = await this.vehiclesApi.getSpareVehicles(runId);
        return suggestions.map(r => ScheduleAvailability.fromResponse(r));
    }

    @action
    public async getVehicles(): Promise<IVehicleSummary[]> {
        const vehicles = await this.vehiclesApi.getVehicles();
        runInAction(() => this.vehicles = vehicles);
        return this.vehicles;
    }

    @action
    public async loadVehicle(vehicleId: number): Promise<Vehicle> {
        const vehicle = await this.vehiclesApi.getVehicle(vehicleId);
        runInAction(() => {
            this.vehicle = Vehicle.fromResponse(vehicle);
            this.vehicle.track();
        });
        return this.vehicle;
    }

    @action
    public async deleteVehicle() {
        const ok = await this.vehiclesApi.deleteVehicle(this.vehicle.id);
        if (ok) {
            notification.showInfo('Vehicle deleted');
            this.unloadVehicle();
        }
        return ok;
    }

    @action
    public unloadVehicle() {
        this.vehicle = new Vehicle({});
    }

    public async searchAvailableVehicles(jobId: number, query: string, includeVehiclesWithoutDefaultDriver: boolean) {
        const suggestions = await this.vehiclesApi.searchAvailableVehicles(jobId, query, includeVehiclesWithoutDefaultDriver);
        return suggestions.map(s => {
            return { label: s.registration, value: { ...s } } as SuggestionItem<IVehicleSummary>
        })
    }

    public async createVehicle() {
        if (!this.vehicle.validate()) return;
        const ok = await this.vehiclesApi.createVehicle(this.vehicle);
        if (ok) {
            notification.showInfo('Vehicle created');
            this.vehicle.untrack();
        }
        return ok;
    }

    public async updateVehicle() {
        if (!this.vehicle.validate()) return;
        const ok = await this.vehiclesApi.updateVehicle(this.vehicle);
        if (ok) {
            notification.showInfo('Vehicle updated');
            this.vehicle.untrack();
        }
        return ok;
    }
}
import * as React from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';
import { InputProps } from '@material-ui/core/Input';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { StandardTextFieldProps, FilledTextFieldProps } from '@material-ui/core/TextField';
import { observable, action } from 'mobx';
import { InputLabelProps } from '@material-ui/core/InputLabel/InputLabel';

export interface INumberChangedEvent {
    parsedValue: number;
    target: string;
}

interface INumberFieldProps {
    value: number;
    error?: any;
    InputProps?: InputProps;
    onChange(e: INumberChangedEvent): void;
    textProps: StandardTextFieldProps | FilledTextFieldProps;
    InputLabelProps?: InputLabelProps;
    name: string;
}

@observer
class NumberField extends React.Component<INumberFieldProps> {

    @observable private maskedValue: string = '';
    
    constructor(props: INumberFieldProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.getNumberFormat = this.getNumberFormat.bind(this);
        this.setMaskedValueFromProps();
    }

    public componentDidUpdate(prevProps: INumberFieldProps) {
        if (prevProps.value === this.props.value) return;
        this.setMaskedValueFromProps();
    }

    public render() {
        return <TextField
            {
                ...this.props.textProps
            }
            name={this.props.name}
            InputLabelProps={this.props.InputLabelProps}
            InputProps={{
                disableUnderline: true,
                inputComponent: this.getNumberFormat,
                ...this.props.InputProps
            }}
            value={this.maskedValue}
            error={!!this.props.error}
            helperText={this.props.error || null}
        />
    }

    @action
    private setMaskedValue(value: string) {
        this.maskedValue = value;
    }

    private setMaskedValueFromProps() {
        this.setMaskedValue(this.props.value ? String(this.props.value) : '');
    }

    private handleChange(values: NumberFormatValues) {
        this.setMaskedValue(values.formattedValue);
        const parsedValue = Number(values.value);
        this.props.onChange({ parsedValue: isNaN(parsedValue) ? 0 : parsedValue, target: this.props.name } as INumberChangedEvent);
    }

    private getNumberFormat(props: any) {
        const { inputRef, ...other } = props;
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={this.handleChange}
                decimalScale={3}
            />
        );
    }
}

export default NumberField;
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { Header } from '../Header/Header';
import { UiStore } from '../../stores/ui-store';
import { JobStore } from '../../stores/job-store';
import tracker from '../Loader/loader-tracker';
import JobForm from './JobForm';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { ActionItem } from '../ActionsMenu/ActionItem';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import CheckCircle from '@material-ui/icons/CheckCircle';

interface IViewJobProps {
    jobStore?: JobStore
    uiStore?: UiStore
}

interface MatchParams {
    id: string;
}

type Props = IViewJobProps & RouteComponentProps<MatchParams>;

@inject('jobStore', 'uiStore')
@observer
class ViewJob extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.updateJob = this.updateJob.bind(this);
        this.completeJob = this.completeJob.bind(this);
    }

    public async componentDidMount() {
        this.props.uiStore!.jobFormState.initView();
        const jobId = this.props.match.params.id;
        await tracker.track(this.props.jobStore!.loadJob(Number(jobId)));
    }

    public render() {
        const { jobFormState } = this.props.uiStore!;
        const { job } = this.props.jobStore!
        return <div className="view-driver">
            <Header title={job ? job.title : 'Job'} tooltip={job ? `Job ${job.id}` : ""} />
            <JobForm {...this.props} />
            <ActionsMenu>
                {
                    jobFormState.readOnly
                    && <ActionItem
                        enabled={true}
                        label="Edit"
                        onClick={() => {
                            jobFormState.setReadOnly(false);
                            job.validate();
                        }}>
                        <Edit />
                    </ActionItem>
                }
                {
                    !jobFormState.readOnly
                    && <ActionItem
                        enabled={true}
                        label="Submit"
                        onClick={this.updateJob}>
                        <Save />
                    </ActionItem>
                }
                {
                    jobFormState.readOnly && job.subcontract
                    && <ActionItem
                        enabled={true}
                        label="Complete Job"
                        onClick={this.completeJob}>
                        <CheckCircle />
                    </ActionItem>
                }
            </ActionsMenu>
        </div>
    }

    private async updateJob() {
        const ok = await tracker.track(this.props.jobStore!.updateJob());
        if (ok) this.props.history.goBack();
    }

    private async completeJob() {
        const ok = await tracker.track(this.props.jobStore!.completeSubContractedJob());
        if (ok) this.props.history.goBack();
    }
}

export default ViewJob;
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { ScheduleStore } from '../../stores/schedule-store';
import { NoRecords } from '../NoRecords/NoRecords';
import { UiStore } from '../../stores/ui-store';
import { PublishStatus } from '../../stores/domain/schedule';
import { RouteComponentProps } from 'react-router';
import AbcReturnDialog from './AbcReturnDialog';
import SupplierReturnDialog from './SupplierReturnDialog';
import TaskList from './TaskList';

interface IScheduleListProps {
    scheduleStore?: ScheduleStore
    uiStore?: UiStore
}

type Props = IScheduleListProps & RouteComponentProps<{}>;

@inject('scheduleStore', 'uiStore')
@observer
class ScheduleList extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
        this.handleViewJob = this.handleViewJob.bind(this);
    }

    public render() {
        const { uiStore, scheduleStore } = this.props;
        const publishStatus = uiStore!.scheduleUiState.publishStatus;
        const schedule = publishStatus === PublishStatus.Live ? scheduleStore!.currentSchedule : scheduleStore!.currentDraftSchedule;
        return <div className="schedule-list">
            {
                schedule.vehicleRoutesWithTasks.map((vr, i) => (
                    <TaskList
                        key={i}
                        onViewJob={this.handleViewJob}
                        vehicleRoute={vr} />))
            }
            {schedule.vehicleRoutesWithTasks.length === 0 &&
                <div className="draft">
                    <NoRecords message="No Schedule Created" active />
                </div>
            }
            <AbcReturnDialog />
            {uiStore!.supplierReturnDialogState.taskId > 0 && <SupplierReturnDialog />}
        </div>
    };

    public closeDialog(): void {
        const { scheduleListState } = this.props.uiStore!;
        scheduleListState.resetCurrentAction();
    }

    public handleViewJob(jobId: number): void {
        this.props.history.push(`view-job/${jobId}`);
    }
}

export default ScheduleList;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';
import { BrowserRouter } from 'react-router-dom'
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import { RootStore } from './initStores';
import 'typeface-roboto';
import { Settings } from 'luxon';

if (window && window.config && window.config.clockOffsetSeconds) {
  Settings.now = () => Date.now() + (window.config.clockOffsetSeconds || 0) * 1000;
}

runWithAdal(authContext, () => {
    ReactDOM.render(
        <Provider {...new RootStore()}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>, document.getElementById('root'));

    configure({
        enforceActions: 'always'
    });
}, false);

serviceWorker.unregister();

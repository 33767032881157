import { observable, action } from 'mobx';

export class JobFormState {

    @observable public readOnly: boolean = true;
    @observable public currentImageIndex: number = 0;
    @observable public imageViewerOpen: boolean = false;
    @observable public signatureViewerOpen: boolean = false;

    @action
    public setReadOnly(readOnly: boolean) {
        this.readOnly = readOnly;
    }

    @action
    public initView() {
        this.readOnly = true;
    }

    @action
    public resetImageViewer() {
        this.currentImageIndex = 0;
        this.imageViewerOpen = false;
    }

    @action
    public openImageViewer(index : number){
        this.currentImageIndex = index;
        this.imageViewerOpen = true;
    }

    @action
    public toggleSignatureViewer() {
        this.signatureViewerOpen = !this.signatureViewerOpen;
    }
}
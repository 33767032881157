import * as React from 'react';
import { FormControl, FormLabel, FormGroup, Checkbox, FormControlLabel, Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { DayOfWeekFlags } from '../../stores/domain/day-of-week-flags';
import { globalStyles } from '../../theme';

interface DayOfWeekSelectorProps extends WithStyles<typeof styles> {
    label: string;
    onChange: (daysOfWeek: DayOfWeekFlags, checked: boolean) => void;
    value: DayOfWeekFlags;
}

class DayOfWeekSelector extends React.Component<DayOfWeekSelectorProps> {

    constructor(props: DayOfWeekSelectorProps) {
        super(props);
    }

    public render()
    {
        return <FormControl>
        <FormLabel>{this.props.label}</FormLabel>
            <FormGroup row
                className={this.props.classes.formGroup}>
                <FormControlLabel
                    className={this.props.classes.formControlLabel}
                    control={<Checkbox
                        onChange={(_, checked) => this.props.onChange(DayOfWeekFlags.Sunday, checked)}
                        checked={DayOfWeekFlags.Sunday === (this.props.value & DayOfWeekFlags.Sunday)} />}
                    label="Sun"
                    labelPlacement="top"
                />
                <FormControlLabel
                    className={this.props.classes.formControlLabel}
                    control={<Checkbox
                        onChange={(_, checked) => this.props.onChange(DayOfWeekFlags.Monday, checked)}
                        checked={DayOfWeekFlags.Monday === (this.props.value & DayOfWeekFlags.Monday)} />}
                    label="Mon"
                    labelPlacement="top"
                />
                <FormControlLabel
                    className={this.props.classes.formControlLabel}
                    control={<Checkbox
                        onChange={(_, checked) => this.props.onChange(DayOfWeekFlags.Tuesday, checked)}
                        checked={DayOfWeekFlags.Tuesday === (this.props.value & DayOfWeekFlags.Tuesday)} />}
                    label="Tue"
                    labelPlacement="top"
                />
                <FormControlLabel
                    className={this.props.classes.formControlLabel}
                    control={<Checkbox
                        onChange={(_, checked) => this.props.onChange(DayOfWeekFlags.Wednesday, checked)}
                        checked={DayOfWeekFlags.Wednesday === (this.props.value & DayOfWeekFlags.Wednesday)} />}
                    label="Wed"
                    labelPlacement="top"
                />
                <FormControlLabel
                    className={this.props.classes.formControlLabel}
                    control={<Checkbox
                        onChange={(_, checked) => this.props.onChange(DayOfWeekFlags.Thursday, checked)}
                        checked={DayOfWeekFlags.Thursday === (this.props.value & DayOfWeekFlags.Thursday)} />}
                    label="Thu"
                    labelPlacement="top"
                />
                <FormControlLabel
                    className={this.props.classes.formControlLabel}
                    control={<Checkbox
                        onChange={(_, checked) => this.props.onChange(DayOfWeekFlags.Friday, checked)}
                        checked={DayOfWeekFlags.Friday === (this.props.value & DayOfWeekFlags.Friday)} />}
                    label="Fri"
                    labelPlacement="top"
                />
                <FormControlLabel
                    className={this.props.classes.formControlLabel}
                    control={<Checkbox
                        onChange={(_, checked) => this.props.onChange(DayOfWeekFlags.Saturday, checked)}
                        checked={DayOfWeekFlags.Saturday === (this.props.value & DayOfWeekFlags.Saturday)} />}
                    label="Sat"
                    labelPlacement="top"
                />
            </FormGroup>
        </FormControl>
    };
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    formControlLabel: {
        marginLeft: 0,
        marginRight: 0
    },
    formGroup: {
        marginTop: '6px'
    }
});

export default withStyles(styles)(DayOfWeekSelector);
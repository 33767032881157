import * as React from 'react';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { JobStore } from '../../stores/job-store';
import Dialog from '../Dialog/Dialog';
import { CloseButton, SearchButton } from '../Dialog/Buttons/Buttons';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import { InlineDatePicker } from 'material-ui-pickers/DatePicker';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Close from '@material-ui/icons/Close';
import { dateFormatted } from '../../datetime';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { globalStyles } from '../../theme';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import { JobsFilter } from '../../stores/domain/jobs-filter';
import { observable, runInAction } from 'mobx';
import { TextField } from '@material-ui/core';

interface ISearchDialogProps extends WithStyles<typeof styles> {
    jobStore?: JobStore
    active: boolean;
    onClose: () => void;
    onSearch: () => void;
}

@inject('jobStore')
@observer
class SearchDialog extends React.Component<ISearchDialogProps> {

    @observable private jobsFilter: JobsFilter = new JobsFilter({});

    constructor(props: ISearchDialogProps) {
        super(props);
        this.getAddressSuggestions = this.getAddressSuggestions.bind(this);
        this.getCustomerNameSuggestions = this.getCustomerNameSuggestions.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.searchOnEnter = this.searchOnEnter.bind(this);
    }

    public render() {
        return <Dialog
            title="Search"
            type="slide"
            onClose={this.props.onClose}
            onOpen={() => {
                runInAction(() => this.jobsFilter = this.props.jobStore!.jobsFilter.clone());
            }}
            active={this.props.active}
            className="search-dialog">
            {{
                content: <React.Fragment>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textField}
                            InputProps={{ disableUnderline: true }}
                            label="Order #"
                            type="text"
                            name="orderNumber"
                            onKeyDown={this.searchOnEnter}
                            error={Boolean(this.jobsFilter.errors.orderNumber)}
                            helperText={this.jobsFilter.errors.orderNumber || null}
                            value={this.jobsFilter.orderNumber}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.jobsFilter.setOrderNumber(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textField}
                            InputProps={{ disableUnderline: true }}
                            label="Purchase Order #"
                            type="text"
                            name="purchaseOrderNumber"
                            onKeyDown={this.searchOnEnter}
                            error={Boolean(this.jobsFilter.errors.purchaseOrderNumber)}
                            helperText={this.jobsFilter.errors.purchaseOrderNumber || null}
                            value={this.jobsFilter.purchaseOrderNumber}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.jobsFilter.setPurchaseOrderNumber(e.target.value)}
                        />
                    </Grid>
                    
                    <Autocomplete
                        label="Address"
                        onSelect={(selected: SuggestionItem<string>) => this.jobsFilter.setAddress(selected.label)}
                        getSuggestions={this.getAddressSuggestions}
                        errorText={this.jobsFilter.errors.address}
                        value={this.jobsFilter.address}
                        onChange={(address: string) => this.jobsFilter.setAddress(address)}
                    />

                    <Autocomplete
                        label="Customer Name"
                        onSelect={(selected: SuggestionItem<string>) => this.jobsFilter.setCustomerName(selected.label)}
                        getSuggestions={this.getCustomerNameSuggestions}
                        errorText={this.jobsFilter.errors.customerName}
                        value={this.jobsFilter.customerName}
                        onChange={(customerName: string) => this.jobsFilter.setCustomerName(customerName)}
                    />

                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textField}
                            InputProps={{ disableUnderline: true }}
                            label="Driver"
                            type="text"
                            name="driver"
                            onKeyDown={this.searchOnEnter}
                            error={Boolean(this.jobsFilter.errors.driver)}
                            helperText={this.jobsFilter.errors.driver || null}
                            value={this.jobsFilter.driver}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.jobsFilter.setDriver(e.target.value)}
                        />
                    </Grid>

                    <FormGroup row>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <Grid container className={this.props.classes.clearableDateContainer} alignItems="flex-end">
                                <Grid item>
                                    <InlineDatePicker
                                        className={this.props.classes.inlineDateField}
                                        onlyCalendar
                                        label="Date from"
                                        value={this.jobsFilter.startDate}
                                        onChange={(date: DateTime) => this.jobsFilter.setStartDate(date)}
                                        labelFunc={(date: DateTime) => dateFormatted(date)}
                                        InputProps={{ disableUnderline: true }}
                                        leftArrowIcon={<KeyboardArrowLeft />}
                                        rightArrowIcon={<KeyboardArrowRight />}
                                        error={!!this.jobsFilter.errors.startDate}
                                        helperText={this.jobsFilter.errors.startDate || null}
                                    />
                                </Grid>
                                <Grid item>
                                    {
                                        this.jobsFilter.startDate
                                        && <Close className="clear-date action" onClick={() => this.jobsFilter.setStartDate(null)} />
                                    }
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <Grid container className={this.props.classes.clearableDateContainer} alignItems="flex-end">
                                <Grid item>
                                    <InlineDatePicker
                                        className={this.props.classes.inlineDateField}
                                        onlyCalendar
                                        label="Date to"
                                        value={this.jobsFilter.endDate}
                                        onChange={(date: DateTime) => this.jobsFilter.setEndDate(date)}
                                        labelFunc={(date: DateTime) => dateFormatted(date)}
                                        InputProps={{ disableUnderline: true }}
                                        leftArrowIcon={<KeyboardArrowLeft />}
                                        rightArrowIcon={<KeyboardArrowRight />}
                                        error={!!this.jobsFilter.errors.endDate}
                                        helperText={this.jobsFilter.errors.endDate || null}
                                    />
                                </Grid>
                                <Grid item>
                                    {
                                        this.jobsFilter.endDate
                                        && <Close className="clear-date action" onClick={() => this.jobsFilter.setEndDate(null)} />
                                    }
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </FormGroup>
                </React.Fragment>,
                actions: <React.Fragment>
                    <CloseButton onClick={this.props.onClose} />
                    <SearchButton onClick={this.handleSearch} />
                </React.Fragment>
            }}
        </Dialog>
    }

    private getAddressSuggestions(value: string): Promise<Array<SuggestionItem<string>>> {
        return this.props.jobStore!.searchDeliveryAddresses(value);
    }

    private getCustomerNameSuggestions(value: string): Promise<Array<SuggestionItem<string>>> {
        return this.props.jobStore!.searchCustomerNames(value);
    }

    private searchOnEnter(event: React.KeyboardEvent<HTMLDivElement>) {
        switch (event.key) {
            case 'Enter':
                this.handleSearch();
                break;
            default:
                break;
        }
    }


    private handleSearch() {
        if (!this.jobsFilter.validate()) return;
        this.props.jobStore!.updateJobsFilter(this.jobsFilter);
        this.props.onSearch();
        this.props.onClose();
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    clearableDateContainer: {
        width: '315px',
        marginBottom: 30
    },
    inlineDateField: {
        width: '245px'
    }
});

export default withStyles(styles)(SearchDialog);

import { AuthenticationContext, adalFetch } from 'react-adal';
import notification from './components/Notification/notification';
import { DateTime } from 'luxon';

export const clientId: string = window.config ? window.config.clientId : ' ';

export const authContext = new AuthenticationContext({
    cacheLocation: 'localStorage',
    clientId,
    endpoints: { api: clientId },
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    tenant: 'theabcgroup.onmicrosoft.com'
});

export const adalApiFetch = async (url: string, options: any = {}) => {
    if (options && options.method !== 'post')
        options.headers = { ...options.headers, ...{ 'pragma': 'no-cache', 'cache-control': 'no-cache' } }
    if (options && options.method !== 'get')
        options.headers = { ...options.headers, 'Content-Type': 'application/json' }
    return await adalFetch(authContext, clientId, fetch, `${window.config.apiUrl}/${url}`, options)
        .then((response) => {
            if (!response.ok) {
                response.json().then((problem: any) => {
                    if (problem.status == 503) {
                        notification.showError(`${problem.title}: ${problem.detail} (${problem.status}, trace identifier ${problem.traceId.substr(3,32)})`)
                    } else {
                        notification.showError(`An unexpected error occurred. Check the logs at ${DateTime.local().toFormat('yyyy-MM-dd HH:mm')} for trace identifier ${problem.traceId.substr(3,32)}. Error was: ${problem.status} ${problem.title}`)
                    }
                    console.error(JSON.stringify(problem))
                }, (error: Error) => {
                    notification.showError(`An error occurred. ${error.message}`)
                    console.error(error)
                })
            }
            return response;
        });
}
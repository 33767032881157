import * as React from 'react';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { UiStore } from '../../stores/ui-store';
import { Grid, withStyles, WithStyles } from '@material-ui/core';
import NumberField, { INumberChangedEvent } from '../NumberField/NumberField';
import { globalStyles } from '../../theme';
import { Task } from '../../stores/domain/task';
import { MoveButton } from '../Dialog/Buttons/Buttons';

interface IMoveTaskActionProps extends WithStyles<typeof globalStyles> {
    index: number;
    moveTask: (dragIndex: number, hoverIndex: number) => void;
    task: Task;
    uiStore?: UiStore;
}

@inject('uiStore')
@observer
class MoveTaskAction extends React.Component<IMoveTaskActionProps> {
    constructor(props: IMoveTaskActionProps) {
        super(props);

        this.moveTask = this.moveTask.bind(this);
    }

    public render() {
        const { index, task, uiStore } = this.props;
        const taskToMove = uiStore!.scheduleListState!.taskToMove;

        if (!taskToMove.guid || taskToMove.guid === '' || task.guid !== taskToMove.guid) {
            return <Grid item xs={1}>
                <span 
                    className="move-task-button"
                    onClick={() => uiStore!.scheduleListState.setTaskToMove(task.guid, index)}>
                    {index}</span>
            </Grid>
        }

        return (<Grid item xs={1}>
            <span 
                className="move-task-button"
                onClick={() => uiStore!.scheduleListState.setTaskToMove(task.guid, index)}>
                    {index}</span>
                <div className="move-task-dialog">
                    <NumberField
                        name="moveToPosition"
                        InputProps={{
                            disableUnderline: true,
                            onKeyDown: e => {
                                if (e.key === 'Enter') {
                                    this.moveTask();
                                }}
                        }}
                        InputLabelProps={{ shrink: true, error: true }}
                        value={taskToMove.index}
                            onChange={(e: INumberChangedEvent) => taskToMove.updateAndValidate('index', e.parsedValue)}
                            error={taskToMove.errors.index}
                            textProps={{
                                className: this.props.classes.numberFieldWidth,
                                label: 'Move To'
                            }}
                    />
                    <MoveButton className="inline-block"
                        onClick={this.moveTask} />
                </div>
        </Grid>);
    }

    public moveTask() {
        const taskToMove = this.props.uiStore!.scheduleListState.taskToMove;
        if (!taskToMove.validate()) {
            return;
        }
        let newIndex = taskToMove.index;
        if (newIndex <= 0) {
            newIndex = 1;
        }
        this.props.moveTask(taskToMove.startIndex, newIndex);
        this.props.uiStore!.scheduleListState.resetTaskToMove();
    }
}

export default withStyles(globalStyles)(MoveTaskAction);
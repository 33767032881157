import { Model } from './model';
import { action, observable, runInAction } from 'mobx';
import { Task } from './task';

export class UnassignedJobs extends Model {
    @observable public runId: string = '';
    @observable public jobIdsToRemove: number[] = [];
    @observable public routeGuid: string = '';

    constructor(obj: Partial<UnassignedJobs>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    @action
    public check(task: Task) {
        if (!task.job) return;
        const jobId = task.job!.id;
        const index = this.jobIdsToRemove.indexOf(jobId);

        if (index > -1) {
            this.jobIdsToRemove.splice(index, 1);
        } else {
            this.jobIdsToRemove.push(jobId);
        }
    }

    public isChecked = (task: Task) =>
        task.job ? this.jobIdsToRemove.indexOf(task.job!.id) > -1 : false;
}
import * as React from 'react';
import { Header } from '../Header/Header';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import DraftSchedule from './DraftSchedule';
import LiveSchedule from './LiveSchedule';
import { RouteComponentProps } from 'react-router';
import { ScheduleStore } from '../../stores/schedule-store';
import tracker from '../Loader/loader-tracker';
import { UiStore } from '../../stores/ui-store';
import { PublishStatus } from '../../stores/domain/schedule';
import { ScheduleDay } from '../../stores/ui-state/schedule-ui-state';
import { DateTime } from 'luxon';
import { JobStore } from '../../stores/job-store';
import { JobsFilter } from '../../stores/domain/jobs-filter';
import { SignalRClient } from '../../services/signalRClient';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { InlineDatePicker } from 'material-ui-pickers';
import LuxonUtils from '@date-io/luxon';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { WithStyles, withStyles, Grid, Badge } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { dateFormatted } from '../../datetime';
import scheduleTimerNotification, { TimerType } from '../ScheduleTimerNotification/schedule-timer-notification';
import ScheduleToRouteDialog from './ScheduleToRouteDialog';
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import notification from '../Notification/notification';

interface ISchedulesProps extends WithStyles<typeof globalStyles> {
    jobStore?: JobStore
    scheduleStore?: ScheduleStore
    uiStore?: UiStore
}
type Props = ISchedulesProps & RouteComponentProps<{}>;

@inject('jobStore', 'scheduleStore', 'uiStore')
@observer
class Schedules extends React.Component<Props> {
    private jobsFilter: JobsFilter = new JobsFilter({});
    private scheduleDate: DateTime = DateTime.local().startOf('day');
    private signalRClient: SignalRClient = new SignalRClient(window.config.apiUrl);

    constructor(props: Props) {
        super(props);

        this.jobsUpdated = this.jobsUpdated.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onOtherClick = this.onOtherClick.bind(this);
        this.setOtherScheduleDate = this.setOtherScheduleDate.bind(this);
        this.scheduleUpdated = this.scheduleUpdated.bind(this);
        this.scheduleError = this.scheduleError.bind(this);
        this.scheduleGenerationStarted = this.scheduleGenerationStarted.bind(this);
        this.scheduleUpdateStarted = this.scheduleUpdateStarted.bind(this);
    }

    public async componentDidMount() {
        await this.refresh();
        this.scheduleDate = this.props.uiStore!.scheduleUiState.scheduleDate;

        this.signalRClient.onJobsUpdate = this.jobsUpdated;
        this.signalRClient.onScheduleError = this.scheduleError;
        this.signalRClient.onScheduleGenerationStarted = this.scheduleGenerationStarted;
        this.signalRClient.onScheduleGenerated = this.scheduleUpdated;
        this.signalRClient.onScheduleUpdateStarted = this.scheduleUpdateStarted;

        this.signalRClient.start();
    }

    public async componentDidUpdate() {
        const { uiStore } = this.props;

        if (this.scheduleDate.toISODate() !== uiStore!.scheduleUiState.scheduleDate.toISODate()) {
            this.scheduleDate = this.props.uiStore!.scheduleUiState.scheduleDate;
            await this.refresh();
        }
    }

    public async componentWillUnmount() {
        this.props.jobStore!.updateJobsFilter(new JobsFilter({}));

        this.signalRClient.stop();

        this.props.uiStore!.resetDialogs();
    }

    public async jobsUpdated() {
        if (scheduleTimerNotification.active === false) {
            await this.refresh();            
        }
    }

    public async scheduleError(runId: string, error: string) {
        scheduleTimerNotification.close();
        notification.showError(error);
        await this.refresh();
    }

    public async scheduleGenerationStarted(timeOutInSeconds: number) {
        if (this.props.uiStore!.scheduleUiState.runTimeoutInSeconds) {
            timeOutInSeconds = this.props.uiStore!.scheduleUiState.runTimeoutInSeconds;
        }

        scheduleTimerNotification.show(timeOutInSeconds, TimerType.Generating, this.scheduleDate);
    }

    public async scheduleUpdateStarted(timeOutInSeconds: number) {
        scheduleTimerNotification.show(timeOutInSeconds, TimerType.Updating, this.scheduleDate);
    }

    public async scheduleUpdated() {
        this.props.scheduleStore!.closeNotifications();
        await this.refresh();
    }

    private async refresh() {
        const { jobStore, uiStore, scheduleStore } = this.props;
        this.jobsFilter.setStartDate(uiStore!.scheduleUiState.scheduleDate);
        this.jobsFilter.setEndDate(uiStore!.scheduleUiState.scheduleDate);
        jobStore!.updateJobsFilter(this.jobsFilter);
        await tracker.track(jobStore!.getJobs());
        await tracker.track(scheduleStore!.loadSchedules(uiStore!.scheduleUiState.scheduleDate));
    }

    public render() {
        const scheduleState = this.props.uiStore!.scheduleUiState;
        const isGenerating = this.props.scheduleStore!.isGenerating;
        
        return <div className="schedule">
            <Header title="Schedule"
                navAsTitleFullScreen />
                <div className="schedule-header">
                    <Grid container
                        className="schedule-date-select">
                        <Grid item xs={1} />
                        <Grid item xs={10}>
                            <ul>
                                <li>
                                    <a onClick={() => scheduleState.setScheduleDay(ScheduleDay.Today)}
                                        className={scheduleState.scheduleDay === ScheduleDay.Today ? 'active' : ''}>Today</a></li>
                                <li>
                                    <a onClick={() => scheduleState.setScheduleDay(ScheduleDay.Tomorrow)}
                                        className={scheduleState.scheduleDay === ScheduleDay.Tomorrow ? 'active' : ''}>Tomorrow</a></li>
                                <li>
                                    <a onClick={() => this.onOtherClick()}
                                        className={scheduleState.scheduleDay === ScheduleDay.Other ? 'active' : ''}>Custom</a></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid container
                        className="schedule-version-select">
                        <Grid item xs={1} />
                        <Grid item xs={10}>
                            <ul>
                                <li>
                                    {
                                        this.versionLinkWithBadge(this.props.jobStore!.scheduledJobsCount, PublishStatus.Live)
                                    }
                                </li>
                                <li>
                                    {
                                        isGenerating
                                        && this.versionLinkWithBadge(0, PublishStatus.Draft)
                                        || this.versionLinkWithBadge(this.props.jobStore!.unscheduledJobsCount, PublishStatus.Draft)
                                    }
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                    <div className="date-nav">
                        <Grid container>
                            <Grid item xs={1} />
                            <Grid className="date-container" item xs={8}>
                                <MuiPickersUtilsProvider utils={LuxonUtils}>
                                    <InlineDatePicker
                                        onlyCalendar
                                        value={scheduleState.scheduleDate}
                                        onChange={(date: DateTime) => this.setOtherScheduleDate(date)}
                                        labelFunc={(date: DateTime) => dateFormatted(date)}
                                        InputProps={{
                                            disableUnderline: true,
                                            className: `date-input`,
                                            endAdornment: scheduleState.scheduleDay !== ScheduleDay.Other ? null : <ArrowDropDown className="icon-action" />,
                                        }}
                                        className={scheduleState.scheduleDay === ScheduleDay.Other ? 'active' : ''}
                                        leftArrowIcon={<KeyboardArrowLeft />}
                                        rightArrowIcon={<KeyboardArrowRight />}
                                        disabled={scheduleState.scheduleDay !== ScheduleDay.Other}
                                    />

                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={1} />
                        </Grid>
                    </div>
            </div>
            {
                ((this.props.uiStore!.scheduleUiState.publishStatus === PublishStatus.Live && this.props.scheduleStore!.currentSchedule.invalidTasks) ||
                    (this.props.uiStore!.scheduleUiState.publishStatus === PublishStatus.Draft && this.props.scheduleStore!.currentDraftSchedule.invalidTasks)) &&
                <Grid container className="alert-text">
                    <Grid item xs={1} />
                    <Grid item xs={9}>One or more routes are not valid. Please review the tasks with exclamation marks.</Grid>
                    <Grid item xs={1} />
                </Grid>
            }
            <DndProvider backend={HTML5Backend}>
                {
                    this.props.uiStore!.scheduleUiState.publishStatus === PublishStatus.Draft
                    && <DraftSchedule {...this.props} />
                    || <LiveSchedule {...this.props} />
                }
            </DndProvider>
            <ScheduleToRouteDialog />
        </div>
    }

    private versionLinkWithBadge(badgeCount: number, activeStatus: PublishStatus) {
        const scheduleState = this.props.uiStore!.scheduleUiState;

        return <Badge color="primary" badgeContent={badgeCount}
            className="badge" max={999} invisible={!tracker.complete()}>
            <a onClick={() => scheduleState.setPublishStatus(activeStatus)}
                className={scheduleState.publishStatus === activeStatus ? 'active' : ''}>
                    {activeStatus === PublishStatus.Draft ? 'Draft' : 'Live'}
            </a>
        </Badge>
    }

    private setOtherScheduleDate(date: DateTime) {
        if (date < this.props.uiStore!.scheduleUiState.today) {
            this.props.uiStore!.scheduleUiState.setPublishStatus(PublishStatus.Live);
        }
        this.props.uiStore!.scheduleUiState.setOtherScheduleDate(date);
    }

    private onOtherClick() {
        if (this.props.uiStore!.scheduleUiState.otherScheduleDate < this.props.uiStore!.scheduleUiState.today) {
            this.props.uiStore!.scheduleUiState.setPublishStatus(PublishStatus.Live);
        }
        this.props.uiStore!.scheduleUiState.setScheduleDay(ScheduleDay.Other)
    }
}

export default withStyles(globalStyles)(Schedules);
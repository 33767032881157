import { adalApiFetch } from '../adalConfig';
import { JobsFilter } from '../stores/domain/jobs-filter';
import { Address } from '../stores/domain/address';
import { Job, TimeslotType, JobStatus, JobType, OrderApprovalStatus, JobDraftStatus } from '../stores/domain/job';
import { TransShip } from '../stores/domain/trans-ship';
import { PreLoad } from '../stores/domain/pre-load';
import { AbcReturn } from '../stores/domain/abc-return';
import { IValidationErrorsResponse } from '../types/IValidationErrorsResponse';

export class JobsApi {
    public async completeSubContractedJob(jobId: number): Promise<boolean> {
        const response = await adalApiFetch(`api/jobs/complete/${jobId}`, { method: 'patch'});
        return response.ok;
    }

    public async getJobs(filter: JobsFilter): Promise<IJobSummaryResponse[]> {
        const response = await adalApiFetch(`api/jobs/summary`, {
            method: 'post',
            body: filter.serialized
        });
        return (response.ok ? await response.json() : []) as IJobSummaryResponse[];
    }

    public async getJob(jobId: number) {
        const response = await adalApiFetch(`api/jobs/${jobId}`);
        return (response.ok ? await response.json() : undefined) as IJobResponse;
    }

    public async lockJob(jobId: number, lock: boolean) : Promise<boolean> {
        const response = await adalApiFetch(`api/jobs/lock`, {
            method: 'post',
            body: JSON.stringify({jobId, lock})
        });
        return response.ok;
    }
    
    async lockToVehicle(jobId: number, vehicleId: number | null) {
        const response = await adalApiFetch(`api/jobs/locktovehicle`, {
            method: 'post',
            body: JSON.stringify({jobId, vehicleId})
        });
        return response.ok;
    }

    public async searchAbcLocations(query: string): Promise<ILocationSummaryResponse[]> {
        const response = await adalApiFetch(`api/location/abc/${encodeURIComponent(query)}`);
        return (response.ok ? await response.json() : []) as ILocationSummaryResponse[];
    }

    public async searchCustomerNames(query: string): Promise<string[]> {
        const response = await adalApiFetch(`api/jobs/customernames/${encodeURIComponent(query)}`);
        return (response.ok ? await response.json() : []) as string[];
    }

    public async searchDeliveryAddresses(query: string): Promise<string[]> {
        const response = await adalApiFetch(`api/jobs/addresses/${encodeURIComponent(query)}`);
        return (response.ok ? await response.json() : []) as string[];
    }

    public async searchTransShipLocations(query: string): Promise<ILocationSummaryResponse[]> {
        const response = await adalApiFetch(`api/location/transship/${encodeURIComponent(query)}`);
        return (response.ok ? await response.json() : []) as ILocationSummaryResponse[];
    }

    public async updateJob(job: Job): Promise<boolean> {
        const response = await adalApiFetch(`api/jobs`, { method: 'put', body: job.serialized });
        return response.ok;
    }

    public async preLoad(preLoad: PreLoad) : Promise<IValidationErrorsResponse> {
        const response = await adalApiFetch(`api/jobs/preload`, {
            method: 'post',
            body: preLoad.serialized
        });
        return (response.ok ? await response.json() : {}) as IValidationErrorsResponse
    }

    public async returnToAbcAndRedeliver(abcReturn: AbcReturn) : Promise<boolean> {
        const response = await adalApiFetch(`api/jobs/returntoabcandredeliver`, {
            method: 'post',
            body: abcReturn.serialized
        });
        return response.ok;
    }

    public async returnToAbcAndUnload(abcReturn: AbcReturn) : Promise<boolean> {
        const response = await adalApiFetch(`api/jobs/returntoabcandunload`, {
            method: 'post',
            body: JSON.stringify({
                abcLocationId: abcReturn.abcLocationId,
                taskId: abcReturn.taskId
            })
        });
        return response.ok;
    }

    public async returnToSupplier(taskId: number) : Promise<boolean> {
        const response = await adalApiFetch(`api/jobs/returntosupplier`, {
            method: 'post',
            body: JSON.stringify({ taskId })
        });
        return response.ok;
    }

    public async transShip(transShip: TransShip) : Promise<IValidationErrorsResponse> {
        const response = await adalApiFetch(`api/jobs/transship`, {
            method: 'post',
            body: transShip.serialized
        });
        return (response.ok ? await response.json() : {}) as IValidationErrorsResponse
    }

    public async unassignLiveJob(jobId: number) : Promise<boolean> {
        const response = await adalApiFetch(`api/jobs/unassignlivejob`, {
            method: 'post',
            body: JSON.stringify({ jobId })
        });
        return response.ok;
    }

    public async undo(jobId: number) : Promise<boolean> {
        const response = await adalApiFetch(`api/jobs/undo`, {
            method: 'post',
            body: JSON.stringify({ jobId })
        });
        return response.ok;
    }
}

interface ILocationSummaryResponse {
    id: number;
    name: string;
}

interface IJobSummaryResponse {
    id: number;
    bodyTruck: boolean;
    customerName: string;
    customerReference: string;
    deliveryAddress: string;
    deliveryDate: string;
    deliveryTimeslot: TimeslotType;
    deliveryWindow: string;
    draftStatus: JobDraftStatus;
    exceedsShippingLimit: boolean;
    isCrane: boolean;
    isFreightOnly: boolean;
    isLocked: boolean;
    isMissingDemands: boolean;
    orderApprovalStatus: OrderApprovalStatus;
    orderNumber: string;
    packs?: number;
    pallets?: number;
    pickupAddress: string;
    pickupWindow: string;
    purchaseOrderNumber: string;
    status: JobStatus;
    type: JobType;
    scheduled: boolean;
    subcontract: boolean;
    updatedInstructions: boolean;
    expectedDistanceInKms: number;
    vehicleRegistration: string;
}

export interface IJobResponse {
    bodyTruck: boolean;
    customerContactName: string;
    customerContactPhone: string;
    customerName: string;
    customerReference: string;
    deliveryAddress: Address;
    deliveryComment: string;
    deliveryDate: string;
    deliveryTimeslot: number;
    deliveryWindow: string;
    draftStatus: JobDraftStatus;
    driverName: string;
    id: number;
    isCrane: boolean;
    isFreightOnly: boolean;
    jobAuditLogs: IJobAuditLogResponse[]; 
    jobItems: IJobItemResponse[];
    mojoeId: string;
    orderApprovalStatus: OrderApprovalStatus;
    orderNumber: string;
    parentJobId: number | null;
    pickupAddress: Address;
    pickupComment: string;
    pickupNumber: string;
    pickupWindow: string;
    pictures: IPicture[];
    purchaseOrderNumber: string;
    relatedJobs: IRelatedJobResponse[];
    signature: ISignature;
    specialInstruction: string;
    status: JobStatus;
    subcontract: boolean;
    type: JobType;
    updatedInstructions: boolean;
    vehicleRegistration: string;
}

export interface IJobAuditLogResponse {
    id: string;
    message: string;
    user: string;
    createdTimeUtc: string;
}

export interface IRelatedJobResponse {
    id: number;
    type: JobType;
}

export interface IPicture {
    id: string;
    filename: string;
}

export interface ISignature extends IPicture {
    signatory: string;
}


export interface IJobItemResponse {
    id: number;
    code: string;
    description: string;
    quantity: number;
    weightInTonnes: number;
    numberOfPallets: number;
    numberOfPacks: number;
    requiresDemand: boolean;
    unit: string;
}
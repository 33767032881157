import { observable, action } from 'mobx'
import { SuggestionItem } from '../../components/Autocomplete/Autocomplete';
import { IDriverSummary } from '../../stores/domain/driver-summary';
import { Vehicle } from '../domain/vehicle';

export class VehicleFormState {

    public initialDefaultDriverId: number | null = null;
    @observable public selectedDriver?: SuggestionItem<IDriverSummary>;
    @observable public readOnly: boolean = true;
    @observable public deleteDialogActive: boolean = false;

    constructor() {
        this.init();
    }

    @action
    public setReadOnly(readOnly: boolean) {
        this.readOnly = readOnly;
    }

    @action
    public setDeleteDialogActive(active: boolean) {
        this.deleteDialogActive = active;
    }

    @action
    public setSelectedDriver(selectedDriver: SuggestionItem<IDriverSummary>) {
        this.selectedDriver = selectedDriver;
    }

    @action
    public unassignDriver() {
        this.selectedDriver = {
            label: 'Unassigned',
            value: { id: 0, name: 'Unassigned' }
        };
    }

    @action
    public initView(vehicle: Vehicle) {
        this.readOnly = true;
        this.initialDefaultDriverId = vehicle.defaultDriverId;
        if (vehicle.defaultDriverId) {
            this.selectedDriver = {
                label: vehicle.defaultDriverName,
                value: { id: vehicle.defaultDriverId, name: vehicle.defaultDriverName },
            }
        }
    }

    @action
    public init() {
        this.initialDefaultDriverId = null;
        this.readOnly = false;
        this.unassignDriver();
    }
}
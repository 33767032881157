import { Model } from './model';
import { observable, computed, runInAction } from 'mobx';
import { IJobItemResponse } from '../../services/jobs-api';
import { moreThanZeroIfHasValue } from '../../validate';

export class JobItem extends Model {
    @observable public id: number = 0;
    @observable public code: string = '';
    @observable public description: string = '';
    @observable public quantity: number = 0;
    @observable public weightInTonnes: number = 0;
    @observable public numberOfPallets: number = 0;
    @observable public numberOfPacks: number = 0;
    @observable public requiresDemand: boolean = false;
    @observable public unit: string = '';
    
    constructor(obj: Partial<JobItem>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    public static fromResponse(r: IJobItemResponse) {
        return new JobItem({...r});
    }

    @computed
    public get missingDemand() {
        return this.requiresDemand && (!this.numberOfPacks && !this.numberOfPallets);
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'numberOfPallets': (val: number) => {
            if (this.requiresDemand === false) {
                return true;    
            } else {
                this.errors.numberOfPacks = moreThanZeroIfHasValue(this.numberOfPacks);
                this.errors.numberOfPallets = this.oneOrOtherButNotBoth(val, this.numberOfPacks);
                return this.errors.numberOfPallets === null;
            }
        },
        'numberOfPacks': (val: number) => {
            if (this.requiresDemand === false) {
                return true;    
            } else {
                this.errors.numberOfPallets = moreThanZeroIfHasValue(this.numberOfPallets);
                this.errors.numberOfPacks = this.oneOrOtherButNotBoth(val, this.numberOfPallets);
                return this.errors.numberOfPacks === null;
            }
        }
    }

    private oneOrOtherButNotBoth(a: number, b: number) {
        if (!a && !b)
            return 'Packs or Pallets required';
        else if (a && b)
            return 'Enter only Packs or Pallets';
        else return moreThanZeroIfHasValue(a);
    }
}
import * as React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles, TextField, Grid } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { DriverUnavailability } from '../../stores/domain/driver-unavailability';
import Dialog from '../Dialog/Dialog';
import { CloseButton, SaveButton } from '../Dialog/Buttons/Buttons';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { InlineDatePicker } from 'material-ui-pickers';
import { DateTime } from 'luxon';
import { dateFormattedShort } from '../../datetime';
import LuxonUtils from '@date-io/luxon';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { IAsFormProps, asForm } from '../Form/AsForm';
import DayOfWeekSelector from '../Selector/DayOfWeekSelector'
import { DayOfWeekFlags } from '../../stores/domain/day-of-week-flags';

interface IUnavailablityDialogProps extends WithStyles<typeof globalStyles> {
    model: DriverUnavailability
    active: boolean
    readOnly?: boolean
    onSave: (model: DriverUnavailability) => void
    onClose: () => void
}

type Props = IUnavailablityDialogProps & IAsFormProps;

@observer
class DriverUnavailabilityDialog extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.handleDaysChanged = this.handleDaysChanged.bind(this);
    }

    public render() {
        return <Dialog
            title={`${!this.props.model.id ? 'Add' : 'Edit'} Unavailability`}
            type="slide"
            active={this.props.active}
            onClose={this.props.onClose}>
            {{
                content: <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            className={this.props.classes.textFieldFullWidth}
                            InputProps={{ disableUnderline: true }}
                            label="Reason"
                            value={this.props.model.reason}
                            disabled={this.props.readOnly}
                            onChange={this.props.handleChange}
                            name="reason"
                            error={!!this.props.model.errors.reason}
                            helperText={this.props.model.errors.reason || null}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <InlineDatePicker
                                className={this.props.classes.inlineDateField}
                                label="Start"
                                value={this.props.model.startDate}
                                onChange={(date: DateTime) => this.props.handleSetProperty!('startDate', date)}
                                labelFunc={(date: DateTime) => dateFormattedShort(date)}
                                InputProps={{ disableUnderline: true }}
                                leftArrowIcon={<KeyboardArrowLeft />}
                                rightArrowIcon={<KeyboardArrowRight />}
                                error={!!this.props.model.errors.startDate}
                                helperText={this.props.model.errors.startDate || null}
                                disabled={this.props.readOnly}
                                disablePast
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className={this.props.classes.textField}
                            error={!!this.props.model.errors.startDispatchTime}
                            helperText={this.props.model.errors.startDispatchTime || null}
                            InputProps={{ disableUnderline: true }}
                            inputProps={{ maxLength: 5 }}
                            label="Start Time"
                            type="text"
                            name="startDispatchTime"
                            autoComplete="no"
                            value={this.props.model.startDispatchTime}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.model.updateAndValidate('startDispatchTime', e.currentTarget.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <InlineDatePicker
                                className={this.props.classes.inlineDateField}
                                label="End"
                                value={this.props.model.endDate}
                                onChange={(date: DateTime) => this.props.handleSetProperty!('endDate', date)}
                                labelFunc={(date: DateTime) => dateFormattedShort(date)}
                                InputProps={{ disableUnderline: true }}
                                leftArrowIcon={<KeyboardArrowLeft />}
                                rightArrowIcon={<KeyboardArrowRight />}
                                error={!!this.props.model.errors.endDate}
                                helperText={this.props.model.errors.endDate || null}
                                disabled={this.props.readOnly}
                                disablePast
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                                className={this.props.classes.textField}
                                error={!!this.props.model.errors.endDispatchTime}
                                helperText={this.props.model.errors.endDispatchTime || null}
                                InputProps={{ disableUnderline: true }}
                                inputProps={{ maxLength: 5 }}
                                label="End Time"
                                type="text"
                                name="endDispatchTime"
                                autoComplete="no"
                                value={this.props.model.endDispatchTime}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.model.updateAndValidate('endDispatchTime', e.currentTarget.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DayOfWeekSelector
                            label="Repeat Every"
                            onChange={this.handleDaysChanged}
                            value={this.props.model.repeatDayFlags}
                        />
                    </Grid>
                </Grid>,
                actions: <React.Fragment>
                    <CloseButton onClick={this.props.onClose} />
                    <SaveButton onClick={() => {
                        if (!this.props.model.validate()) return;
                        this.props.onSave(this.props.model);
                    }} />
                </React.Fragment>
            }}
        </Dialog>
    }

    private handleDaysChanged(e: DayOfWeekFlags, checked: boolean) {
        this.props.model.setRepeatDays(e, checked)
    }
}

export default (asForm(withStyles(globalStyles)(DriverUnavailabilityDialog)));
import { Model } from './model';
import { observable, runInAction, computed } from 'mobx';
import { required, hasValue } from '../../validate';

export class Address extends Model {
    @observable public id: number = 0;
    @observable public locationName: string = '';
    @observable public streetAddress: string = '';
    @observable public suburb: string = '';
    @observable public postCode: string = '';
    @observable public latLng: ILatLng = { lat: null, lng: null };
    @observable public formattedAddress: string = ''
    @observable public openingTime: string = '';
    @observable public closingTime: string = '';    

    @computed
    public get addressText(): string {
        return hasValue(this.locationName) ? this.locationName : this.formattedAddress;
    }

    constructor(obj: Partial<Address>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'formattedAddress': (val: string) => {
            this.errors.formattedAddress = required(val);
            return this.errors.formattedAddress === null;
        },
        'latLng': () => {
            this.errors.formattedAddress = 
                this.latLng.lat != null && this.latLng.lng != null ? null : 'Required';
            return this.errors.formattedAddress === null;
        }
    }
}

export interface ILatLng { lat: number | null, lng: number | null };
import { Model } from './model';
import { observable, runInAction } from 'mobx';
import { hasValue } from '../../validate';

export class ScheduleToRoute extends Model {
    @observable public directAdd: boolean = false;
    @observable public force: boolean = false;
    @observable public jobId: number = 0;
    @observable public routeGuid: string = '';

    constructor(obj: Partial<ScheduleToRoute>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'jobId': (val: number) => {
            this.errors.jobId = val > 0 ? null : 'Must be a valid job';
            return this.errors.jobId === null;
        },
        'routeGuid': (val: string) => {
            this.errors.routeGuid = hasValue(val) ? null : 'Must choose a valid route';
            return this.errors.routeGuid === null;
        }
    }
}
import { observer, inject } from 'mobx-react';
import { MuiPickersUtilsProvider, InlineDatePicker } from 'material-ui-pickers';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LuxonUtils from '@date-io/luxon';
import React from 'react';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import tracker from '../Loader/loader-tracker';
import { UiStore } from '../../stores/ui-store';
import { WithStyles, withStyles } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { DateTime } from 'luxon';
import { dateFormatted } from '../../datetime';
import { JobStore } from '../../stores/job-store';
import { CloseButton, TransShipButton } from '../Dialog/Buttons/Buttons';
import Dialog from '../Dialog/Dialog';
import { ILocationSummary } from '../../stores/domain/location-summary';

interface ITransShipDialogProps extends WithStyles<typeof globalStyles> {
    jobStore?: JobStore
    uiStore?: UiStore
}

@inject('jobStore', 'uiStore')
@observer
class TransShipDialog extends React.Component<ITransShipDialogProps> {
    
    constructor(props: ITransShipDialogProps) {
        super(props);

        this.getTransShipLocationSuggestions = this.getTransShipLocationSuggestions.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.setTransShipLocation = this.setTransShipLocation.bind(this);
    }

    public render() {
        const { transShipDialogState } = this.props.uiStore!;
        const { transShip } = transShipDialogState;

        return transShip !== undefined && <Dialog
            title="Trans-Ship"
            type="slide"
            onClose={() => this.handleCancel()}
            active={transShip !== undefined}
            className="trans-ship-dialog">
        {{
            content: <React.Fragment>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <InlineDatePicker
                        onlyCalendar
                        className={this.props.classes.inlineDateField}
                        label="Trans-ship Date"
                        clearable
                        value={transShipDialogState.transShip!.deliveryDate}
                        onChange={(date: DateTime) => transShip!.updateAndValidate('deliveryDate', date)}
                        labelFunc={(date: DateTime) => dateFormatted(date)}
                        InputProps={{ disableUnderline: true }}
                        leftArrowIcon={<KeyboardArrowLeft />}
                        rightArrowIcon={<KeyboardArrowRight />}
                        error={!!transShip!.errors.deliveryDate}
                        helperText={transShip!.errors.deliveryDate || null}
                        maxDate={transShipDialogState.maxDate}
                        minDate={DateTime.local().startOf('day')}
                    />
                </MuiPickersUtilsProvider>

                <Autocomplete
                    label="Trans-Ship Location"
                    onSelect={this.setTransShipLocation}
                    getSuggestions={this.getTransShipLocationSuggestions}
                    errorText={transShip!.errors.transShipLocationId}
                    selectedItem={transShip!.toLocationSuggestion()}
                    value={transShip!.transShipLocationName}
                />
            </React.Fragment>,
            actions: <React.Fragment>
                <CloseButton onClick={this.handleCancel} />
                <TransShipButton onClick={this.handleSave} />
                      </React.Fragment>
        }}
        </Dialog>
    }

    private getTransShipLocationSuggestions(value: string): Promise<Array<SuggestionItem<ILocationSummary>>> {
        return this.props.jobStore!.searchTransShipLocations(value);
    }

    private setTransShipLocation(item: SuggestionItem<ILocationSummary>) {
        this.props.uiStore!.transShipDialogState.transShip!.update('transShipLocationId', item.value.id);
        this.props.uiStore!.transShipDialogState.transShip!.update('transShipLocationName', item.value.name);
    }

    private handleCancel() {
        this.props.uiStore!.transShipDialogState.reset();
    }

    private async handleSave() {
        const transShip = this.props.uiStore!.transShipDialogState.transShip!;
        if (!transShip.validate()) return;
        const ok = await tracker.track(this.props.jobStore!.transShip(transShip));
        if (ok === true) {
            this.props.uiStore!.transShipDialogState.reset();
        }
    }
}

export default withStyles(globalStyles)(TransShipDialog);
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { WithStyles, withStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, createStyles, Theme } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { asForm, IAsFormProps } from '../Form/AsForm';
import { JobStore } from '../../stores/job-store';
import { IJobAuditLog } from '../../stores/domain/job';
import { dateTimeFormatted } from '../../datetime';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface IJobAuditLogsProps extends WithStyles<typeof styles> {
    jobStore?: JobStore
}

type Props = IJobAuditLogsProps & IAsFormProps;

@inject('jobStore')
@observer
class JobAuditLogs extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    public render() {
        const { jobStore } = this.props;
        return (
            <ExpansionPanel className={this.props.classes.expansion}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        className={this.props.classes.expansionSection}>
                        Event log
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        className={this.props.classes.expansionSection}>
                        <div className="event-items">
                            {
                                jobStore!.job.jobAuditLogs.map((e, i) => (<EventRow event={e} key={i} />))
                            }
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
        )
    }
}

interface IEventRowProps {
    event: IJobAuditLog;
}
const EventRow: React.SFC<IEventRowProps> = (props) => {
    return <div className="event-item">
        <Grid container>
            <Grid item xs={4}>
                {props.event.message}
            </Grid>
            <Grid item xs={3}>
                {dateTimeFormatted(props.event.date)}
            </Grid>
            <Grid item xs={3}>
                {props.event.user}
            </Grid>
        </Grid>
    </div>
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    expansion: {
        boxShadow: 'none',
        marginBottom: 30
    },
    expansionSection: {
        padding: 0
    }
});


export default (asForm(withStyles(styles)(JobAuditLogs)));
import { action,  observable } from 'mobx'
import { Task } from '../domain/task';

export class SupplierReturnDialogState {
    @observable public taskId: number = 0;

    @action
    public reset() {
        this.taskId = 0;
    }

    @action
    public init(task: Task) {
        this.taskId = task.id;
    }
}
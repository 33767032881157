import { observer, inject } from 'mobx-react';
import { ScheduleStore } from '../../stores/schedule-store';
import { UiStore } from '../../stores/ui-store';
import { withStyles, WithStyles, FormControl, InputLabel, Select, MenuItem, FormHelperText, Theme, createStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { globalStyles } from '../../theme';
import tracker from '../Loader/loader-tracker';
import React from 'react';
import { action } from 'mobx';
import { VehicleStore } from '../../stores/vehicle-store';
import Dialog from '../Dialog/Dialog';
import { CloseButton, TransferDriverButton } from '../Dialog/Buttons/Buttons';
import { DriverStore } from '../../stores/driver-store';
import { timeFormatted } from '../../datetime';

interface ITransferDriverDialogProps extends WithStyles<typeof styles> {
    driverStore?: DriverStore;
    scheduleStore?: ScheduleStore;
    uiStore?: UiStore;
    vehicleStore?: VehicleStore;
}

@inject('driverStore', 'scheduleStore', 'uiStore', 'vehicleStore')
@observer
class TransferDriverDialog extends React.Component<ITransferDriverDialogProps> {
    
    constructor(props: ITransferDriverDialogProps) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
        this.submit = this.submit.bind(this);
    }

    public render() {
        const { transferDriverDialogState } = this.props.uiStore!;
        const { driverTransfer } = transferDriverDialogState;
        return <Dialog
            title="Transfer Driver"
            type="slide"
            onClose={this.closeDialog}
            active={driverTransfer.routeGuid.length > 0}
            className="transfer-driver-dialog">
        {{
            content: <React.Fragment>
                <FormControl
                    className={this.props.classes.textFieldFullWidth}>
                    <InputLabel>After Task</InputLabel>
                    <Select
                        disableUnderline
                        value={driverTransfer.taskGuid}
                        onChange={async (e) => {
                            transferDriverDialogState.updateTaskGuid(e.target.value);
                        }}
                        inputProps={{ name: 'taskGuid', id: 'taskGuid' }}>
                        {
                            [<MenuItem value={''} key={-1}>Select Task</MenuItem>]
                                .concat(transferDriverDialogState.route.tasks.map((t, i) => (
                                    <MenuItem classes={{ root: this.props.classes.menuItemExpand }} value={t.guid} key={i}>
                                        <span>
                                            <div>{`${timeFormatted(t.etaDispatchTime!)} ${t.taskTypeText}`}</div>
                                            <div className="sub-text">{`${t.addressText}`}</div>
                                        </span>
                                    </MenuItem>)))
                        }
                    </Select>
                    {
                        driverTransfer.errors.taskGuid
                        && <FormHelperText error>
                            {driverTransfer.errors.taskGuid}
                        </FormHelperText>
                    }
                </FormControl>
                <FormControlLabel
                    control={<Checkbox
                        checked={driverTransfer.transferLoad}
                        name="transferLoad"
                        onChange={async (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                            await transferDriverDialogState.updateTransferLoad(checked);
                        }}
                        disabled={driverTransfer.taskGuid === ''} />}
                    label="Transfer Load"
                />
                <FormControl
                    className={this.props.classes.textFieldFullWidth}>
                    <InputLabel>Transfer Vehicle</InputLabel>
                    <Select
                        disableUnderline
                        disabled={driverTransfer.taskGuid === ''}
                        value={transferDriverDialogState.vehicleIndex}
                        onChange={(e) => {
                            const index = Number(e.target.value);
                            transferDriverDialogState.updateVehicle(index);
                        }}
                        inputProps={{ name: 'vehicleId', id: 'vehicleId' }}>
                        {
                            [<MenuItem value={0} key={-1}>Select Vehicle</MenuItem>]
                                .concat(transferDriverDialogState.transferVehicles.map((t, i) => (
                                    <MenuItem classes={{ root: this.props.classes.menuItemExpand }} value={i} key={i}>
                                        <span>
                                            <div>{t.registration}</div>
                                            <div className="sub-text">From {t.startDispatchTime} to {t.endDispatchTime}</div>
                                        </span>
                                    </MenuItem>)))
                        }
                    </Select>
                    {
                        driverTransfer.errors.vehicleId
                        && <FormHelperText error>
                            {driverTransfer.errors.vehicleId}
                        </FormHelperText>
                    }
                </FormControl>
            </React.Fragment>,
            actions: <React.Fragment>
                <CloseButton onClick={this.closeDialog} />
                <TransferDriverButton onClick={this.submit} />
                </React.Fragment>
        }}
        </Dialog>
    }

    public closeDialog(): void {
        this.props.uiStore!.transferDriverDialogState.reset();
    }

    @action
    private async submit() {
        const { scheduleStore, uiStore } = this.props;

        const model = uiStore!.transferDriverDialogState.driverTransfer;
        if (!model.validate()) return;
        const ok = await tracker.track(scheduleStore!.transferDriver(model));
        if (ok === true) {
            uiStore!.transferDriverDialogState.reset();
        }
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    menuItemExpand: { 
        height: 'auto'
    },
});

export default withStyles(styles)(TransferDriverDialog);
import * as React from 'react';
import { Header } from '../Header/Header';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { DriverStore } from '../../stores/driver-store';
import { RouteComponentProps } from 'react-router';
import VehiclesList from './DriversList';
import { AddAction } from '../ActionsMenu/AddAction';

interface IDriversProps {
    driverStore?: DriverStore
}
type Props = IDriversProps & RouteComponentProps<{}>;

@inject('driverStore')
@observer
class Drivers extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    public async componentDidMount() {
        await this.props.driverStore!.getDrivers();
    }

    public render() {
        return <div className="drivers">
            <Header title="Drivers" navAsTitleFullScreen />
            <VehiclesList { ...this.props } />
            <AddAction routeName="new-driver" />
        </div>
    }
}

export default Drivers;
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { asForm, IAsFormProps } from '../Form/AsForm';
import { JobStore } from '../../stores/job-store';
import { UiStore } from '../../stores/ui-store';
import Carousel, { Modal, ModalGateway } from 'react-images';

interface IImageGalleryProps extends WithStyles<typeof globalStyles> {
    jobStore?: JobStore
    uiStore?: UiStore
}

type Props = IImageGalleryProps & IAsFormProps;

@inject('jobStore', 'uiStore')
@observer
class ImageGallery extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.openLightBox = this.openLightBox.bind(this);
        this.closeLightBox = this.closeLightBox.bind(this);
    }

    public render() {
        const { jobStore } = this.props;
        const { jobFormState } = this.props.uiStore!;
        return (
            <div>
                <p className="photoTitle">
                    Photos
                </p>
                <div
                    className="gallery">
                    {jobStore!.job.pictures.map((picture, j) => (
                        <div
                            className="imageContainer"
                            onClick={() => this.openLightBox(j)} key={picture.src}>
                            <img
                                src={picture.src}
                                className="image"
                            />
                        </div>
                    ))}
                </div>
                <ModalGateway>
                    {jobFormState.imageViewerOpen ? (
                        <Modal onClose={this.closeLightBox}
                            closeOnBackdropClick={true}
                            allowFullscreen={false}
                            styles={{
                                blanket: (base: any) => ({
                                    ...base,
                                    backgroundColor: 'rgba(255,255,255,0.8)',
                                }),
                                dialog: (base: any) => ({
                                    ...base,
                                    maxWidth: '50%',
                                })
                            }}>
                            <Carousel
                                currentIndex={jobFormState.currentImageIndex}
                                styles={{
                                    footer: (base: any) => ({
                                        ...base,
                                        background: 'none !important',
                                        color: 'gray',
                                        padding: 0,
                                        paddingTop: 20,
                                        position: 'static',

                                        '& a': {
                                            color: 'black',
                                        },
                                    }),
                                    header: (base: any) => ({
                                        ...base,
                                        background: 'none !important',
                                        padding: 0,
                                        paddingBottom: 10,
                                        position: 'static',
                                    }),
                                    headerClose: (base: any) => ({
                                        ...base,
                                        color: 'gray',

                                        ':hover': { color: 'red' },
                                    }),
                                    view: (base: any) => ({
                                        ...base,
                                        backgroundColor: '#F4F5F7',
                                        alignItems: 'center',
                                        display: 'flex ',
                                        height: 'calc(80vh - 150px)',
                                        justifyContent: 'center',
                                        '& > img': {
                                            maxHeight: 'calc(80vh - 120px)',
                                        },
                                    }),

                                    navigationPrev: navButtonStyles,
                                    navigationNext: navButtonStyles,
                                }}
                                views={jobStore!.job.pictures.map(x => ({
                                    ...x,
                                    srcset: x.src
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        )

    }

    public openLightBox(index: number) {
        this.props.uiStore!.jobFormState.openImageViewer(index);
    }

    public closeLightBox() {
        this.props.uiStore!.jobFormState.resetImageViewer();
    }
}

const navButtonStyles = (base: any) => ({
    ...base,
    backgroundColor: 'white',
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.18)',
    color: '#6B778C',

    '&:hover, &:active': {
        backgroundColor: 'white',
        color: '#091E42',
        opacity: 1,
    },
    '&:active': {
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.14)',
        transform: 'scale(0.96)',
    },
});

export default (asForm(withStyles(globalStyles)(ImageGallery)));
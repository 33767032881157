import * as React from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

interface IIconTooltipProps {
    title: string,
    placement?: TooltipProps['placement'],
    children: {
        icon: React.ReactElement<React.ComponentType<SvgIconProps>>
    };
}

export const IconTooltip: React.SFC<IIconTooltipProps> = (props: IIconTooltipProps) => {
    return <Tooltip title={props.title} placement={props.placement}
        disableFocusListener
        disableTouchListener>
        {
            props.children.icon
        }
    </Tooltip>
};
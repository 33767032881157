import { observer, inject } from 'mobx-react';
import { MuiPickersUtilsProvider, InlineDatePicker } from 'material-ui-pickers';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LuxonUtils from '@date-io/luxon';
import React from 'react';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import tracker from '../Loader/loader-tracker';
import { UiStore } from '../../stores/ui-store';
import { WithStyles, withStyles } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { DateTime } from 'luxon';
import { dateFormatted } from '../../datetime';
import { JobStore } from '../../stores/job-store';
import { CloseButton, PreLoadButton } from '../Dialog/Buttons/Buttons';
import Dialog from '../Dialog/Dialog';
import { VehicleStore } from '../../stores/vehicle-store';
import { IVehicleSummary } from '../../stores/domain/vehicle-summary';

interface IPreLoadDialogProps extends WithStyles<typeof globalStyles> {
    jobStore?: JobStore
    uiStore?: UiStore
    vehicleStore?: VehicleStore
}

@inject('jobStore', 'uiStore', 'vehicleStore')
@observer
class PreLoadDialog extends React.Component<IPreLoadDialogProps> {
    
    constructor(props: IPreLoadDialogProps) {
        super(props);

        this.getVehicleSuggestions = this.getVehicleSuggestions.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.setPreLoadVehicle = this.setPreLoadVehicle.bind(this);
    }

    public render() {
        const { preLoadDialogState } = this.props.uiStore!;
        const { preLoad } = preLoadDialogState;

        return preLoad !== undefined && <Dialog
            title="Pre-Load"
            type="slide"
            onClose={() => this.handleCancel()}
            active={preLoad !== undefined}
            className="pre-load-dialog">
        {{
            content: <React.Fragment>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <InlineDatePicker
                        onlyCalendar
                        className={this.props.classes.inlineDateField}
                        label="Pre-Load Date"
                        clearable
                        value={preLoad.preLoadDate}
                        onChange={(date: DateTime) => preLoad.updateAndValidate('preLoadDate', date)}
                        labelFunc={(date: DateTime) => dateFormatted(date)}
                        InputProps={{ disableUnderline: true }}
                        leftArrowIcon={<KeyboardArrowLeft />}
                        rightArrowIcon={<KeyboardArrowRight />}
                        error={!!preLoad.errors.preLoadDate}
                        helperText={preLoad.errors.preLoadDate || null}
                        maxDate={preLoadDialogState.maxDate}
                        minDate={DateTime.local().startOf('day')}
                    />
                </MuiPickersUtilsProvider>

                <Autocomplete
                    label="Vehicle"
                    onSelect={this.setPreLoadVehicle}
                    getSuggestions={this.getVehicleSuggestions}
                    errorText={preLoad.errors.vehicleId}
                    selectedItem={preLoad.toVehicleSuggestion()}
                    value={preLoad.vehicleRegistration}
                />
            </React.Fragment>,
            actions: <React.Fragment>
                <CloseButton onClick={this.handleCancel} />
                <PreLoadButton onClick={this.handleSave} />
                      </React.Fragment>
        }}
        </Dialog>
    }

    private getVehicleSuggestions(value: string): Promise<Array<SuggestionItem<IVehicleSummary>>> {
        const jobId = this.props.uiStore!.preLoadDialogState.preLoad!.jobId;

        return this.props.vehicleStore!.searchAvailableVehicles(jobId, value, false);
    }

    private setPreLoadVehicle(item: SuggestionItem<IVehicleSummary>) {
        this.props.uiStore!.preLoadDialogState.preLoad!.update('vehicleId', item.value.id);
        this.props.uiStore!.preLoadDialogState.preLoad!.update('vehicleRegistration', item.value.registration);
    }

    private handleCancel() {
        this.props.uiStore!.preLoadDialogState.reset()
    }

    private async handleSave() {
        const preLoad = this.props.uiStore!.preLoadDialogState.preLoad!
        if (!preLoad.validate()) return
        const ok = await tracker.track(this.props.jobStore!.preLoad(preLoad))
        if (ok === true) {
            this.props.uiStore!.preLoadDialogState.reset()
        }
    }
}

export default withStyles(globalStyles)(PreLoadDialog)
import { observer, inject } from 'mobx-react';
import React from 'react';
import tracker from '../Loader/loader-tracker';
import { UiStore } from '../../stores/ui-store';
import { WithStyles, withStyles, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { JobStore } from '../../stores/job-store';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LuxonUtils from '@date-io/luxon';
import { CloseButton, OkButton } from '../Dialog/Buttons/Buttons';
import Dialog from '../Dialog/Dialog';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import { ILocationSummary } from '../../stores/domain/location-summary';
import { MuiPickersUtilsProvider, InlineDatePicker } from 'material-ui-pickers';
import { DateTime } from 'luxon';
import { dateFormatted } from '../../datetime';
import notification from '../../components/Notification/notification';

interface IAbcReturnDialogProps extends WithStyles<typeof globalStyles> {
    jobStore?: JobStore,
    uiStore?: UiStore,
}

@inject('jobStore', 'uiStore')
@observer
class AbcReturnDialog extends React.Component<IAbcReturnDialogProps> {
    constructor(props: IAbcReturnDialogProps) {
        super(props);

        this.getAbcLocationSuggestions = this.getAbcLocationSuggestions.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setAbcLocation = this.setAbcLocation.bind(this);
        this.setUnload = this.setUnload.bind(this);
    }

    public render() {
        const { abcReturnDialogState: returnToAbcDialogState } = this.props.uiStore!;
        const { abcReturn } = returnToAbcDialogState;
        const classes = this.props.classes;

        if (!abcReturn.unload && abcReturn.abcLocationName !== abcReturn.vehicleDepot) {
            this.getAbcLocationSuggestions(abcReturn.vehicleDepot!).then(res => {
                if (res.length) {
                    this.setAbcLocation(res.pop()!)
                }
            });
        }

        return returnToAbcDialogState.abcReturn.taskId > 0 && <Dialog
            title="Return to ABC"
            type="slide"
            onClose={() => this.handleCancel()}
            active={true}
            className="abc-return-dialog"
            loaderAreaName="abc-return-loader">
            {{
                content: <React.Fragment>
                    <div className="radio-wrapper">
                        <FormControl component={'fieldset' as 'div'}>
                            <FormLabel component={'legend' as 'label'}>Unload?</FormLabel>
                            <RadioGroup
                                aria-label="Unload?"
                                name="unload"
                                row
                                value={abcReturn.unload.toString()}
                                onChange={(e, val) => this.setUnload(val)}
                            >
                                <FormControlLabel
                                    value={'false'}
                                    control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                                    label="Don't Unload" />
                                <FormControlLabel
                                    value={'true'}
                                    control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                                    label="Unload" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <Autocomplete
                        label="ABC Location"
                        onSelect={this.setAbcLocation}
                        getSuggestions={this.getAbcLocationSuggestions}
                        errorText={abcReturn!.errors.abcLocationName}
                        selectedItem={abcReturn!.toLocationSuggestion()}
                        value={abcReturn!.abcLocationName}
                        disabled={!abcReturn.unload}
                    />

                    {abcReturn.unload === false && <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <InlineDatePicker
                            onlyCalendar
                            className={this.props.classes.inlineDateField}
                            label="Delivery Date"
                            clearable
                            value={abcReturn.deliveryDate}
                            onChange={(date: DateTime) => abcReturn!.updateAndValidate('deliveryDate', date)}
                            labelFunc={(date: DateTime) => dateFormatted(date)}
                            InputProps={{ disableUnderline: true }}
                            leftArrowIcon={<KeyboardArrowLeft />}
                            rightArrowIcon={<KeyboardArrowRight />}
                            error={!!abcReturn!.errors.deliveryDate}
                            helperText={abcReturn!.errors.deliveryDate || null}
                            minDate={returnToAbcDialogState.minDate}
                        />
                    </MuiPickersUtilsProvider>
                    }
                </React.Fragment>,
                actions: <React.Fragment>
                    <CloseButton onClick={this.handleCancel} />
                    <OkButton onClick={this.handleSubmit} />
                </React.Fragment>
            }}
        </Dialog>
    }

    private getAbcLocationSuggestions(value: string): Promise<Array<SuggestionItem<ILocationSummary>>> {
        return this.props.jobStore!.searchAbcLocations(value);
    }

    private setAbcLocation(item: SuggestionItem<ILocationSummary>) {
        this.props.uiStore!.abcReturnDialogState.abcReturn!.update('abcLocationId', item.value.id);
        this.props.uiStore!.abcReturnDialogState.abcReturn!.update('abcLocationName', item.value.name);
    }

    private setUnload(textValue: string) {
        const unload = JSON.parse(textValue)
        if (unload) {
            this.props.uiStore!.abcReturnDialogState.abcReturn!.update('abcLocationId', '');
            this.props.uiStore!.abcReturnDialogState.abcReturn!.update('abcLocationName', '');
        }
        this.props.uiStore!.abcReturnDialogState.abcReturn!.update('unload', unload);
    }

    private handleCancel() {
        this.props.uiStore!.abcReturnDialogState.reset();
    }

    private async handleSubmit() {
        if (!this.props.uiStore!.abcReturnDialogState.abcReturn!.validate()) return;

        const { abcReturnDialogState: returnToAbcDialogState } = this.props.uiStore!;

        const ok = await tracker.track(this.props.jobStore!.returnToAbc(returnToAbcDialogState.abcReturn), 'abc-return-loader');
        if (ok === true) {
            this.props.uiStore!.abcReturnDialogState.reset();
            notification.showInfo('Items will be returned to ABC and the current task is cancelled.');
        } else {
            this.props.uiStore!.abcReturnDialogState.reset();
            notification.showError('Same ABC Location as the truck\'s final destination must be selected.');
        }
    }
}

export default withStyles(globalStyles)(AbcReturnDialog);
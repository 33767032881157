import { Model } from './model';
import { observable, runInAction, action, computed } from 'mobx';
import { moreThanZero } from '../../validate';
import { SuggestionItem } from '../../components/Autocomplete/Autocomplete';
import { IVehicleSummary } from './vehicle-summary';
import { ScheduleAvailability } from './schedule-availability';

export class UnscheduledDelay extends Model {
    @observable public allowReduction: boolean = false;
    @observable public driverIndex: number = -1;
    @observable public liveVehicleId: number = 0;
    @observable public routeId: number = 0;
    @observable public delayInMinutes: number = 0;
    @observable public selectedVehicle: SuggestionItem<IVehicleSummary> = {
        label: '',
        value: {
            id: 0,
            availableFromUtc: '',
            defaultDriverName: '',
            registration: '',
            suburb: '',
            validAddress: false }
    };
    @observable public spareDrivers: ScheduleAvailability[] = [];
    
    constructor(obj: Partial<UnscheduledDelay>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    @computed
    public get selectedDriver() {
        if (!this.spareDrivers.length) return undefined;
        return this.spareDrivers[this.driverIndex];
    }

    @computed
    public get newDriverId(): number {
        return this.selectedDriver ? this.selectedDriver.driverId : 0;
    }

    @computed
    public get newVehicleId(): number {
        return this.selectedVehicle.value.id;
    }

    @computed get vehicleId(): number {
        return this.selectedVehicle.value.id === 0 ? this.liveVehicleId : this.selectedVehicle.value.id;
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'delayInMinutes': (val: number) => {
            this.errors.delayInMinutes = this.allowReduction ? null : moreThanZero(val);
            return this.errors.delayInMinutes === null;
        }
    }

    @action
    public clearSelectedVehicle() {
        this.selectedVehicle = {
            label: '',
            value: {
                id: 0,
                availableFromUtc: '',
                defaultDriverName: '',
                registration: '',
                suburb: '',
                validAddress: false }
        };
    }

    @action 
    public setSpareDrivers(spareDrivers: ScheduleAvailability[]) {
        this.spareDrivers = spareDrivers;
    }

    @action
    public setSelectedVehicle(selectedVehicle: SuggestionItem<IVehicleSummary>) {
        this.selectedVehicle = selectedVehicle;
    }

    @action 
    public updateDriver(driver: ScheduleAvailability, index: number) {
        this.driverIndex = index;
    }
}
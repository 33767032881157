import { Model } from './model';
import { moreThanZero, required } from '../../validate';
import { observable, runInAction } from 'mobx';

export class DriverAssignment extends Model {
    @observable public driverId: number = 0;
    @observable public routeGuid: string = '';
    @observable public runId: string = '';
    
    constructor(obj: Partial<DriverAssignment>) {
        super();
        runInAction(() => {
            Object.assign(this, obj);
        } );
    }
    
    protected validators: { [name: string]: (val: any) => boolean } = {
        'driverId': (val: number) => {
            this.errors.driverId = moreThanZero(val);
            return this.errors.driverId === null;
        },
        'routeGuid': (val: string) => {
            this.errors.routeGuid = required(val);
            return this.errors.routeGuid === null;
        },
        'runId': (val: string) => {
            this.errors.runId = required(val);
            return this.errors.runId === null;
        }
    }
}
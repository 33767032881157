import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { VehicleStore } from '../../stores/vehicle-store';
import { Header } from '../Header/Header';
import VehicleForm from './VehicleForm';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { ActionItem } from '../ActionsMenu/ActionItem';
import Save from '@material-ui/icons/Save';
import { RouteComponentProps } from 'react-router';
import { UiStore } from '../../stores/ui-store';
import tracker from '../Loader/loader-tracker';

interface INewVehicleProps {
    vehicleStore?: VehicleStore
    uiStore?: UiStore
}

type Props = INewVehicleProps & RouteComponentProps<{}>;

@inject('vehicleStore', 'uiStore')
@observer
class NewVehicle extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
        this.createVehicle = this.createVehicle.bind(this);

        this.props.uiStore!.vehicleFormState.init();
    }

    public render() {
        return <div className="new-vehicle">
            <Header title="New Vehicle" />
            <VehicleForm />
            <ActionsMenu>
                <ActionItem
                    enabled={true}
                    label="Save"
                    onClick={this.createVehicle}>
                    <Save />
                </ActionItem>
            </ActionsMenu>
        </div>
    }

    private async createVehicle() {
        const ok = await tracker.track(this.props.vehicleStore!.createVehicle());
        if (ok) this.props.history.push('vehicles');
    }
}

export default NewVehicle;
import { action, computed, observable } from 'mobx'
import { PublishStatus } from '../domain/schedule';
import { DateTime } from 'luxon';
import { dateFormatted } from '../../datetime';

export class ScheduleUiState {
    @observable public publishStatus: PublishStatus = PublishStatus.Live;
    @observable public scheduleDay: ScheduleDay = ScheduleDay.Today;
    @observable public otherScheduleDate: DateTime = DateTime.local().startOf('day').minus({ day: 1 });
    @observable public runTimeoutInSeconds: number = 0;

    @action
    public setRunTimeoutInSeconds(runTimeoutInSeconds: number) {
        this.runTimeoutInSeconds = runTimeoutInSeconds;
    }

    @action public setScheduleDay(scheduleDay: ScheduleDay) {
        this.scheduleDay = scheduleDay;
    }

    @action public setPublishStatus(publishStatus: PublishStatus) {
        this.publishStatus = publishStatus;
    }

    @action public setOtherScheduleDate(date : DateTime) {
        this.otherScheduleDate = date.startOf('day');
    }

    @computed public get isTodaysSchedule() : boolean {
        return this.today === this.scheduleDate;
    }

    @computed public get scheduleDate(): DateTime {
        if(this.scheduleDay === ScheduleDay.Other){
            return this.otherScheduleDate;
        }
        return this.scheduleDay === ScheduleDay.Today ? this.today : this.today.plus({ day: 1 });
    }

    @computed public get dayText(): string {
        if(this.scheduleDay === ScheduleDay.Other){
            return dateFormatted(this.otherScheduleDate);
        }
        return this.scheduleDay === ScheduleDay.Today ? 'today' : 'tomorrow';
    } 

    @computed public get today(): DateTime  {
        return DateTime.local().startOf('day');
    }

}

export enum ScheduleDay {
    None = 0,
    Today = 1,
    Tomorrow = 2,
    Other = 3
}

import { observer, inject } from 'mobx-react';
import { UiStore } from '../../stores/ui-store';
import { withStyles, WithStyles, FormControl, InputLabel, Select, MenuItem, FormHelperText, Theme, createStyles } from '@material-ui/core';
import { globalStyles } from '../../theme';
import tracker from '../Loader/loader-tracker';
import React from 'react';
import { action } from 'mobx';
import Dialog from '../Dialog/Dialog';
import { CloseButton, AssignDriverButton } from '../Dialog/Buttons/Buttons';
import { ScheduleStore } from '../../stores/schedule-store';

interface IAssignDriverDialogProps extends WithStyles<typeof styles> {
    scheduleStore?: ScheduleStore;
    uiStore?: UiStore;
}

@inject('scheduleStore', 'uiStore')
@observer
class AssignDriverDialog extends React.Component<IAssignDriverDialogProps> {
    
    constructor(props: IAssignDriverDialogProps) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
        this.submit = this.submit.bind(this);
    }

    public render() {
        const { assignDriverDialogState } = this.props.uiStore!;
        const { driverAssignment } = assignDriverDialogState;
        return <Dialog
            title="Assign Driver"
            type="slide"
            onClose={this.closeDialog}
            active={driverAssignment.routeGuid.length > 0}
            className="assign-driver-dialog">
        {{
            content: <React.Fragment>
                <FormControl
                    className={this.props.classes.textFieldFullWidth}>
                    <InputLabel>Driver</InputLabel>
                    <Select
                        disableUnderline
                        value={assignDriverDialogState.driverIndex}
                        onChange={(e) => {
                            const index = Number(e.target.value);
                            assignDriverDialogState.updateDriver(index);
                        }}
                        inputProps={{ name: 'driverId', id: 'driverId' }}>
                        {
                            [<MenuItem value={0} key={-1}>Select Driver</MenuItem>]
                                .concat(assignDriverDialogState.spareDrivers.map((t, i) => (
                                    <MenuItem classes={{ root: this.props.classes.menuItemExpand }} value={i} key={i}>
                                        <span>
                                            <div>{t.driverName}</div>
                                            <div className="sub-text">From {t.startDispatchTime} to {t.endDispatchTime}</div>
                                        </span>
                                    </MenuItem>)))
                        }
                    </Select>
                    {
                        driverAssignment.errors.driverId
                        && <FormHelperText error>
                            {driverAssignment.errors.driverId}
                        </FormHelperText>
                    }
                </FormControl>
            </React.Fragment>,
            actions: <React.Fragment>
                <CloseButton onClick={this.closeDialog} />
                <AssignDriverButton onClick={this.submit} />
                </React.Fragment>
        }}
        </Dialog>
    }

    public closeDialog(): void {
        this.props.uiStore!.assignDriverDialogState.reset();
    }

    @action
    private async submit() {
        const { scheduleStore, uiStore } = this.props;

        const model = uiStore!.assignDriverDialogState.driverAssignment;
        if (!model.validate()) return;
        const ok = await tracker.track(scheduleStore!.assignDriver(model));
        if (ok === true) {
            uiStore!.assignDriverDialogState.reset();
        }
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    menuItemExpand: { 
        height: 'auto'
    },
});

export default withStyles(styles)(AssignDriverDialog);
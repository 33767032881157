import * as React from 'react';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, Theme, WithStyles, withStyles, InputAdornment, Chip, CircularProgress, Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react';
import { throttle } from 'throttle-debounce';
import Close from '@material-ui/icons/Close';

export interface SuggestionItem<T> {
    label: string
    value: T
}

export interface IProps<T> extends WithStyles<typeof styles> {
    label: string
    errorText?: string | null
    helpText?: string | null
    placeholder?: string | null
    selectedItem?: SuggestionItem<T>
    disabled?: boolean;
    autoFocus?: boolean;
    onSelect(selected: SuggestionItem<T>): void
    getSuggestions(value: string): Promise<Array<SuggestionItem<T>>>
    suggestionToComponent?: {
        containerStyle: any,
        getComponent(item: SuggestionItem<T>): React.ReactElement<any>
    },
    value?: string;
    onChange?(address: string): void,
    multiSelect?: boolean
    filterMultiSelect?(item: SuggestionItem<T>, filters: Array<SuggestionItem<T>>): Array<SuggestionItem<T>>
    filterDefaults?: Array<SuggestionItem<T>>
    onSelectionDeleted?(item: SuggestionItem<T>): void
    onClear?(): void
}

export interface IState<T> {
    suggestions: Array<SuggestionItem<T>>
    filters: Array<SuggestionItem<T>>
    loading: boolean;
}

@observer
class Autocomplete<T> extends React.Component<IProps<T>, IState<T>> {

    private searchTrottled: (inputValue: string) => void;
    private lastQuery: string = '';

    constructor(props: IProps<T>) {
        super(props);
        this.search = this.search.bind(this);
        this.searchTrottled = throttle(1000, this.getSuggestions);
        this.handleSelection = this.handleSelection.bind(this);
    }

    public componentDidMount() {
        this.setState({
            suggestions: [],
            filters: this.props.filterDefaults || [],
            loading: false
        });
    }

    public render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Downshift
                    id="downshift"
                    onOuterClick={() => this.setState({ suggestions: [] })}
                    onSelect={this.handleSelection}
                    itemToString={this.itemToString}
                    selectedItem={this.props.selectedItem}
                >
                    {({
                        getInputProps,
                        getItemProps,
                        getMenuProps,
                        highlightedIndex,
                        isOpen,
                        selectedItem
                    }) => (
                            <div className={classes.container}>
                                {
                                    this.renderInput({
                                        fullWidth: true,
                                        classes,
                                        InputProps: getInputProps(this.getInputProps()),
                                        label: this.props.label,
                                        error: !!this.props.errorText,
                                        helperText: this.props.errorText || this.props.helpText || null
                                    })
                                }
                                <div {...getMenuProps()}>
                                    {isOpen ? (
                                        <Paper className={classes.paper} square>
                                            {
                                                this.state.suggestions.map((suggestion, index) =>
                                                    this.renderSuggestion({
                                                        suggestion,
                                                        index,
                                                        itemProps: getItemProps({ item: suggestion }),
                                                        highlightedIndex,
                                                        selectedItem,
                                                    }),
                                                )
                                            }
                                            {this.renderNoResults()}
                                        </Paper>
                                    ) : null}
                                </div>
                            </div>
                        )}
                </Downshift>
            </div>)
    }

    private getInputProps() {
        const props = {
            placeholder: this.props.placeholder || '',
            onChange: this.search,
            disabled: this.props.disabled
        };
        return this.props.value
            ?  { ...props, value: this.props.value }
            : props;
    }

    private renderNoResults(): React.ReactNode | void {
        if (!this.state.suggestions.length) {
            return (<MenuItem value=""><em>No results</em></MenuItem>)
        }
    }

    private renderInput(inputProps: any) {
        const { InputProps, classes, ref, ...other } = inputProps;
        return (
            <TextField
                InputProps={{
                    disableUnderline: true,
                    inputRef: ref,
                    classes: {
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    },
                    startAdornment: this.renderChips(),
                    endAdornment: this.renderEndAdornment(),
                    autoFocus: this.props.autoFocus,
                    ...InputProps,
                }}
                InputLabelProps={
                    (this.props.placeholder !== undefined) 
                        ? { shrink: true } 
                        : {}
                }
                {...other}
            />
        );
    }

    private renderEndAdornment() {
        if (this.state && this.state.loading) {
            return <InputAdornment position="end">
                <CircularProgress size={20} />
            </InputAdornment>
        }
        else if (this.props.onClear && this.props.disabled === false) {
            return <InputAdornment position="end">
                <Close onClick={this.props.onClear} className={this.props.classes.action} />
            </InputAdornment>
        }
        return null;
    }

    private renderChips() {
        return this.props.multiSelect && this.state && this.state.filters.length
            ? (
                <InputAdornment position="start">
                    {
                        this.state.filters.map((s, i) => (
                            <Tooltip title={s.label} placement="top" key={i}
                                disableFocusListener
                                disableTouchListener>
                                <Chip
                                    className={`${this.props.classes.chip} ' autocomplete-chip'`}
                                    label={(s.label.length <= 25) ? s.label : `${s.label.substring(0, 22)}...`}
                                    key={i}
                                    variant="outlined"
                                    onDelete={() => {
                                        const remaining = this.state.filters.filter(x => x !== s);
                                        this.setState({ filters: remaining });
                                        if (this.props.onSelectionDeleted) this.props.onSelectionDeleted(s);
                                    }} />
                            </Tooltip>
                        ))
                    }
                </InputAdornment>
            ) : null
    }

    private renderSuggestion(params: any) {
        const { suggestion, index, itemProps, highlightedIndex } = params
        const isHighlighted = highlightedIndex === index;
        return (
            <MenuItem
                {...itemProps}
                key={index}
                selected={isHighlighted}
                component="div"
                className={this.props.classes.menuItem}
                style={this.props.suggestionToComponent ? this.props.suggestionToComponent.containerStyle : {}}
            >
                {
                    this.props.suggestionToComponent
                    && (this.props.suggestionToComponent.getComponent(suggestion))
                }
                {
                    !this.props.suggestionToComponent
                    && suggestion.label
                }
            </MenuItem>
        );
    }

    private search(e: React.ChangeEvent<HTMLInputElement>) {
        const { onChange } = this.props;
        const query = e.currentTarget.value;

        if (onChange)
            onChange(query);

        if (!query.length) {
            this.setState({ suggestions: [] });
            return;
        }

        this.searchTrottled(query);
    }

    private getSuggestions(query: string) {
        this.setState({ loading: true });
        this.lastQuery = query;
        this.props.getSuggestions(query)
            .then(suggestions => {
                if (this.lastQuery === query)
                    this.setState({ suggestions, loading: false });
            }).catch((reason: any) => {
                console.error(reason);
                this.setState({ loading: false });
            });
    }

    private handleSelection = (selected: SuggestionItem<T>) => {
        if (this.props.multiSelect) {
            const filters = this.state.filters;
            if (this.props.filterMultiSelect)
                this.setState({ filters: this.props.filterMultiSelect(selected, filters) });
        }
        this.props.onSelect(selected);
    }

    private itemToString = (item: SuggestionItem<T>) => {
        if (!item) return '';
        return item.label;
    }
}

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        marginBottom: 30,
    },
    container: {
        flexGrow: 1,
        position: 'relative',
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    inputRoot: {
        flexWrap: 'nowrap',
        display: 'inherit',
        width: '100%',
        marginBottom: 0
    },
    inputInput: {
        width: '100%',
        flexGrow: 1,
        marginBottom: 0
    },
    menuItem: {
        whiteSpace: 'normal'
    },
    chip: {
        color: '#ea592c',
        border: '1px solid #ea592c',
        backgroundColor: '#fff'
    },
    action: {
        cursor: 'pointer'
    }
});

export default withStyles(styles)(Autocomplete);
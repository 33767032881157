import * as React from 'react';
import './styles.scss';
import { observer } from 'mobx-react';
import Dialog from './Dialog';
import { CloseButton, OkButton } from './Buttons/Buttons';

interface IConfirmDialogProps {
    active: boolean
    title: string
    message: string
    className?: string
    onClose(): void
    onOk(): void
    onOpen?(): void
}

@observer
class ConfirmDialog extends React.Component<IConfirmDialogProps> {
    
    public render() {
        return <Dialog
            type="full-screen"
            loaderAreaName="confirm-dialog"
            { ...this.props }>
            {{
                content: <React.Fragment>
                    {this.props.message}
                </React.Fragment>,
                actions: <React.Fragment>
                    <CloseButton onClick={this.props.onClose} />
                    <OkButton onClick={this.props.onOk} />
                </React.Fragment>
            }}
        </Dialog>
    }
}

export default ConfirmDialog;
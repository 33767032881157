import { Model } from './model';
import { observable, action, computed, runInAction } from 'mobx';
import { DateTime } from 'luxon';

export class JobsFilter extends Model {

    @observable public address: string = '';
    @observable public customerName: string = '';
    @observable public driver: string = '';
    @observable public orderNumber: string = '';
    @observable public purchaseOrderNumber: string = '';
    @observable public startDate: DateTime | null = null;
    @observable public endDate: DateTime | null = null;

    constructor(obj: Partial<JobsFilter>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }
    
    @action
    public setAddress(value: string) {
        this.address = value;
    }

    @action
    public setCustomerName(value: string) {
        this.customerName = value;
    }

    @action
    public setDriver(value: string) {
        this.driver = value;
    }

    @action
    public setOrderNumber(value: string) {
        this.orderNumber = value;
    }

    @action
    public setPurchaseOrderNumber(value: string) {
        this.purchaseOrderNumber = value;
    }

    @action
    public setStartDate(date: DateTime | null) {
        this.updateAndValidate('startDate', date ? date.startOf('day') : null);
    }

    @action
    public setEndDate(date: DateTime | null) {
        this.updateAndValidate('endDate', date ? date.endOf('day') : null);
    }

    @computed
    public get isFiltered(): boolean {
        return this.address !== ''
                || this.driver !== ''
                    || this.orderNumber !== ''
                        || this.purchaseOrderNumber !== ''
                            || (this.startDate !== null && this.endDate !== null);
    }

    public clone() {
        return new JobsFilter({
            address: this.address,
            driver: this.driver,
            orderNumber: this.orderNumber,
            purchaseOrderNumber: this.purchaseOrderNumber,
            startDate: this.startDate,
            endDate: this.endDate});
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'startDate': (val: DateTime) => {
            if (!this.endDate && !val) {
                this.errors.startDate = null;
                this.errors.endDate = null;
            }

            if (!this.endDate) return true;
            this.errors.startDate = val
                ? null : 'Date from is required';
            if(!this.errors.startDate) {
                this.errors.startDate = this.endDate && this.endDate > val
                    ? null : 'From date cannot be greater than To date';
            };
            return this.errors.startDate === null;
        },
        'endDate': (val: DateTime) => {
            if (!val && !this.startDate) {
                this.errors.startDate = null;
                this.errors.endDate = null;
            }

            if (!this.startDate) return true;
            this.errors.endDate = val
                ? null : 'Date to is required';
            return this.errors.endDate === null;
        }
    }
}
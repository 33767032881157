import { observer, inject } from 'mobx-react';
import { ScheduleStore } from '../../stores/schedule-store';
import { UiStore } from '../../stores/ui-store';
import {
    withStyles,
    WithStyles,
    FormControl,
    InputLabel,
    Checkbox,
    Select,
    MenuItem,
    FormHelperText,
    Theme,
    createStyles,
    FormControlLabel
} from '@material-ui/core';
import { globalStyles } from '../../theme';
import tracker from '../Loader/loader-tracker';
import React from 'react';
import { action } from 'mobx';
import NumberField, { INumberChangedEvent } from '../NumberField/NumberField';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import { IVehicleSummary } from '../../stores/domain/vehicle-summary';
import { VehicleStore } from '../../stores/vehicle-store';
import Dialog from '../Dialog/Dialog';
import { CloseButton, AddDelayButton } from '../Dialog/Buttons/Buttons';
import { ScheduleListAction } from '../../stores/ui-state/schedule-list-state';
import { DriverStore } from '../../stores/driver-store';
import { DateTime } from 'luxon';

interface IUnscheduledDelayDialogProps extends WithStyles<typeof styles> {
    driverStore?: DriverStore;
    scheduleStore?: ScheduleStore;
    uiStore?: UiStore;
    vehicleStore?: VehicleStore;
}

@inject('driverStore', 'scheduleStore', 'uiStore', 'vehicleStore')
@observer
class UnscheduledDelayDialog extends React.Component<IUnscheduledDelayDialogProps> {
    
    constructor(props: IUnscheduledDelayDialogProps) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
        this.getDriverSuggestions = this.getDriverSuggestions.bind(this);
        this.getVehicleSuggestions = this.getVehicleSuggestions.bind(this);
        this.handleDelayInMinutesChange = this.handleDelayInMinutesChange.bind(this);
        this.submit = this.submit.bind(this);
        this.renderVehicleSuggestion = this.renderVehicleSuggestion.bind(this);
    }

    public render() {
        const { scheduleListState } = this.props.uiStore!;
        const { unscheduledDelay } = scheduleListState!;
        return <Dialog
            title="Unscheduled Delay"
            type="slide"
            onClose={this.closeDialog}
            onOpen={async () => await this.getDriverSuggestions()}
            active={scheduleListState.activeAction === ScheduleListAction.UnscheduledDelay}
            className="unscheduled-delay-dialog">
        {{
            content: <React.Fragment>
                <FormControlLabel
                    className={this.props.classes.textFieldFullWidth}
                    control={<Checkbox
                        checked={unscheduledDelay.allowReduction}
                        name="allowReduction"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                            unscheduledDelay.update('allowReduction', checked);
                        }}
                    />}
                    label="Allow reduction (negative)"
                />
                <NumberField
                    name="delayInMinutes"
                    value={unscheduledDelay.delayInMinutes}
                    onChange={(e: INumberChangedEvent) => this.handleDelayInMinutesChange(e.parsedValue)}
                    error={unscheduledDelay.errors.delayInMinutes}
                    textProps={{
                        className: this.props.classes.textField,
                        label: 'Delay in Minutes',
                        autoFocus: true
                    }}
                />
                <FormControl
                    className={this.props.classes.textFieldFullWidth}>
                    <InputLabel htmlFor="driverId">Replacement Driver?</InputLabel>
                    <Select
                        disableUnderline
                        value={unscheduledDelay.driverIndex}
                        onChange={(e) => {
                            const index = Number(e.target.value);
                            const selected = unscheduledDelay.spareDrivers[index];
                            if (!selected) return;
                            unscheduledDelay.updateDriver(selected, index);
                            this.getDriverSuggestions();
                        }}
                        inputProps={{ name: 'driverId', id: 'driverId' }}>
                        {
                            [<MenuItem value={0} key={-1}>Select Driver</MenuItem>]
                                .concat(unscheduledDelay.spareDrivers.map((t, i) => (
                                    <MenuItem classes={{ root: this.props.classes.menuItemExpand }} value={i} key={i}>
                                        <span>
                                            <div>{t.driverName}</div>
                                            <div className="sub-text">From {t.startDispatchTime} to {t.endDispatchTime}</div>
                                        </span>
                                    </MenuItem>)))
                        }
                    </Select>
                    {
                        unscheduledDelay.errors.driverId
                        && <FormHelperText error>
                            {unscheduledDelay.errors.driverId}
                        </FormHelperText>
                    }
                </FormControl>
                <Autocomplete
                    label="Replacement Vehicle?"
                    onSelect={(selected: SuggestionItem<IVehicleSummary>) => {
                        unscheduledDelay.setSelectedVehicle(selected);
                    }}
                    getSuggestions={this.getVehicleSuggestions}
                    selectedItem={unscheduledDelay.selectedVehicle}
                    suggestionToComponent={{
                        containerStyle: { minHeight: '50px', height: 'auto' },
                        getComponent: this.renderVehicleSuggestion
                    }}
                    onClear={unscheduledDelay.clearSelectedVehicle}
                />
            </React.Fragment>,
            actions: <React.Fragment>
                <CloseButton onClick={this.closeDialog} />
                <AddDelayButton onClick={this.submit} />
                </React.Fragment>
        }}
        </Dialog>
    }

    public closeDialog(): void {
        const { scheduleListState } = this.props.uiStore!;
        scheduleListState.resetCurrentAction();
    }

    private async getDriverSuggestions() {
        const unscheduledDelay = this.props.uiStore!.scheduleListState.unscheduledDelay;
        const scheduleId = this.props.scheduleStore!.currentSchedule.id;
        const vehicleId = unscheduledDelay.vehicleId;
        const utcNow = DateTime.utc();
        const drivers = await tracker.track(this.props.driverStore!.getSpareDriversForLive(scheduleId, vehicleId, utcNow), 'unscheduled-delay-dialog');
        unscheduledDelay.setSpareDrivers(drivers);
    }

    private async getVehicleSuggestions(value: string): Promise<Array<SuggestionItem<IVehicleSummary>>> {
        const routeId = this.props.uiStore!.scheduleListState.unscheduledDelay.routeId;
        return await this.props.vehicleStore!.searchSpareVehicles(routeId, value);
    }

    @action
    private handleDelayInMinutesChange(delay: number) {
        this.props.uiStore!.scheduleListState.unscheduledDelay.updateAndValidate('delayInMinutes', delay);
    }

    @action
    private async submit() {
        const { scheduleStore, uiStore } = this.props;

        const delay = uiStore!.scheduleListState.unscheduledDelay;
        if (!delay.validate()) return;
        const ok = await tracker.track(scheduleStore!.addUnscheduledDelay(delay));
        if (ok === true) {
            uiStore!.scheduleListState.resetCurrentAction();
        }
    }

    private renderVehicleSuggestion(item: SuggestionItem<IVehicleSummary>) {
        return <div className="autocomplete-item no-subtext">
            <span>{item.label}</span>
        </div>
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    menuItemExpand: { 
        height: 'auto'
    },
});

export default withStyles(styles)(UnscheduledDelayDialog);
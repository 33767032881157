import { observer, inject } from 'mobx-react';
import { ScheduleStore } from '../../stores/schedule-store';
import { UiStore } from '../../stores/ui-store';
import { withStyles, WithStyles, FormControl, InputLabel, Select, MenuItem, createStyles, Theme, FormHelperText, TextField } from '@material-ui/core';
import { globalStyles } from '../../theme';
import React from 'react';
import { VehicleStore } from '../../stores/vehicle-store';
import Dialog from '../Dialog/Dialog';
import { CloseButton, OkButton } from '../Dialog/Buttons/Buttons';
import { asForm, IAsFormProps } from '../Form/AsForm';
import { IRootStore } from '../../initStores';
import { DriverStore } from '../../stores/driver-store'; 
import tracker from '../Loader/loader-tracker';

interface ISpareVehicleDialogProps extends WithStyles<typeof styles> {
    scheduleStore?: ScheduleStore
    uiStore?: UiStore
    vehicleStore?: VehicleStore
    driverStore?: DriverStore
}

type Props = ISpareVehicleDialogProps & IAsFormProps;

@inject('scheduleStore', 'uiStore', 'vehicleStore', 'driverStore')
@observer
class SpareVehicleDialog extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.getVehicleSuggestions = this.getVehicleSuggestions.bind(this);
        this.getDriverSuggestions = this.getDriverSuggestions.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    public render() {
        const { spareVehicleDialogState } = this.props.uiStore!;
        const { spareVehicleAvailability: model } = spareVehicleDialogState;
        return <Dialog
            title="Add Spare Vehicle"
            type="slide"
            onClose={() => this.handleCancel()}
            onOpen={async () => await this.getVehicleSuggestions()}
            active={spareVehicleDialogState.active}
            loaderAreaName="spare-dialog">
            {{
                content: <React.Fragment>
                    <FormControl
                        className={this.props.classes.textFieldFullWidth}>
                        <InputLabel htmlFor="vehicleId">Spare Vehicle</InputLabel>
                        <Select
                            disableUnderline
                            value={spareVehicleDialogState.vehicleIndex}
                            onChange={(e) => {
                                const index = Number(e.target.value);
                                const selected = spareVehicleDialogState.spareVehicles[index];
                                if (!selected) return;
                                spareVehicleDialogState.updateVehicle(selected, index);
                                this.getDriverSuggestions();
                            }}
                            inputProps={{ name: 'vehicleId', id: 'vehicleId' }}>
                            {
                                [<MenuItem value={-1} key={-1}>Select Vehicle</MenuItem>]
                                .concat(spareVehicleDialogState.spareVehicles.map((t, i) => (
                                    <MenuItem classes={{root: this.props.classes.menuItemExpand}} value={i} key={i}>
                                        <span>
                                            <div>{t.registration}</div>
                                            <div className="sub-text">From {t.startDispatchTime} to {t.endDispatchTime}</div>
                                        </span>
                                    </MenuItem>)))
                            }
                        </Select>
                        {
                            model.errors.vehicleId
                            && <FormHelperText error>
                                {model.errors.vehicleId}
                            </FormHelperText>
                        }
                    </FormControl>
                    <FormControl
                        disabled={!model.vehicleId}
                        className={this.props.classes.textFieldFullWidth}>
                        <InputLabel htmlFor="driverId">Spare Driver</InputLabel>
                        <Select
                            disableUnderline
                            value={spareVehicleDialogState.driverIndex}
                            onChange={(e) => {
                                const index = Number(e.target.value);
                                const selected = spareVehicleDialogState.spareDrivers[index];
                                if (!selected) return;
                                spareVehicleDialogState.updateDriver(selected, index);
                            }}
                            inputProps={{ name: 'driverId', id: 'driverId' }}>
                            {
                                [<MenuItem value={-1} key={-1}>Select Driver</MenuItem>]
                                .concat(spareVehicleDialogState.spareDrivers.map((t, i) => (
                                    <MenuItem classes={{root: this.props.classes.menuItemExpand}} value={i} key={i}>
                                        <span>
                                            <div>{t.driverName}</div>
                                            <div className="sub-text">From {t.startDispatchTime} to {t.endDispatchTime}</div>
                                        </span>
                                    </MenuItem>)))
                            }
                        </Select>
                        {
                            model!.errors.driverId
                            && <FormHelperText error>
                                {model!.errors.driverId}
                            </FormHelperText>
                        }
                    </FormControl>
                    <TextField
                            className={this.props.classes.textField}
                            error={!!model.errors.startDispatchTime}
                            helperText={model.errors.startDispatchTime || null}
                            InputProps={{ disableUnderline: true }}
                            inputProps={{ maxLength: 5 }}
                            label="Start Time"
                            type="text"
                            name="StartDispatchTime"
                            autoComplete="no"
                            value={model.startDispatchTime}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => model.updateAndValidate('startDispatchTime', e.currentTarget.value)}
                        />
                    <TextField
                            className={this.props.classes.textField}
                            error={!!model.errors.endDispatchTime}
                            helperText={model.errors.endDispatchTime || null}
                            InputProps={{ disableUnderline: true }}
                            inputProps={{ maxLength: 5 }}
                            label="End Time"
                            type="text"
                            name="EndDisppatchTime"
                            autoComplete="no"
                            value={model.endDispatchTime}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => model.updateAndValidate('endDispatchTime', e.currentTarget.value)}
                    />
                </React.Fragment>,
                actions: <React.Fragment>
                    <CloseButton onClick={this.handleCancel} />
                    <OkButton onClick={this.handleSave} />
                </React.Fragment>
            }}
        </Dialog>
    }

    private async getVehicleSuggestions() {
        const { spareVehicleDialogState } = this.props.uiStore!;
        const runId = this.props.scheduleStore!.currentDraftSchedule.runId;
        const vehicles = await tracker.track(this.props.vehicleStore!.getSpareVehicles(runId), 'spare-dialog');
        spareVehicleDialogState.setSpareVehicles(vehicles);
    }

    private async getDriverSuggestions() {
        const { spareVehicleDialogState } = this.props.uiStore!;
        const { runId } = this.props.scheduleStore!.currentDraftSchedule;
        const selectedVehicle = spareVehicleDialogState!.selectedVehicle;
        if (!selectedVehicle) return;
        const drivers = await tracker.track(this.props.driverStore!.getSpareDrivers(selectedVehicle, runId), 'spare-dialog');
        spareVehicleDialogState.setSpareDrivers(drivers);
    }

    private handleCancel() {
        const { spareVehicleDialogState } = this.props.uiStore!;
        spareVehicleDialogState.setActive(false);
    }

    private async handleSave() {
        const { spareVehicleDialogState } = this.props.uiStore!;
        const { spareVehicleAvailability } = this.props.uiStore!.spareVehicleDialogState;
        if (!spareVehicleAvailability || !spareVehicleAvailability.validate()) return;
        await tracker.track(this.props.scheduleStore!.addVehicleAvailability(spareVehicleAvailability), 'spare-dialog');
        spareVehicleDialogState.setActive(false);
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    menuItemExpand: { 
        height: 'auto'
    },
});

export default inject((s: IRootStore) => ({ model: s.uiStore.spareVehicleDialogState.spareVehicleAvailability }))
    (asForm(withStyles(styles)(SpareVehicleDialog)));
import * as React from 'react';
import { Header } from '../Header/Header';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { VehicleStore } from '../../stores/vehicle-store';
import { RouteComponentProps } from 'react-router';
import VehiclesList from './VehiclesList';
import { AddAction } from '../ActionsMenu/AddAction';
import tracker from '../Loader/loader-tracker';

interface IVehiclesProps {
    vehicleStore?: VehicleStore
}
type Props = IVehiclesProps & RouteComponentProps<{}>;

@inject('vehicleStore')
@observer
class Vehicles extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    public async componentDidMount() {
        await tracker.track(this.props.vehicleStore!.getVehicles());
    }

    public render() {
        return <div className="vehicles">
            <Header title="Vehicles" navAsTitleFullScreen />
            <VehiclesList { ...this.props } />
            <AddAction routeName="new-vehicle" />
        </div>
    }
}

export default Vehicles;
import * as React from 'react';
import { observer } from 'mobx-react';
import './styles.css';
import Clock from './Clock';

export const Environment: React.SFC<{}> = observer((props: any) => {
    const getEnvironment = () => {
        if (window.location.host.includes("asap-ci"))
            return 'CI';
        if (window.location.host.includes("asap-uat"))
            return 'UAT';
        if (window.location.host.includes("local"))
            return 'DEV';
        return null;
    };

    const env = getEnvironment();
    const showClock = window && window.config && (window.config.clockOffsetSeconds || 0) != 0

    return env && <div className="environment">
        This is the {env} environment
        {showClock &&
            <React.Fragment>
                <br />
                <Clock />
            </React.Fragment>
        }
    </div>
});

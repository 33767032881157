import { observable, action } from 'mobx';

export class Notification {
    @observable public active: boolean = false;
    @observable public message: string = '';
    @observable public type: NotificationType = NotificationType.info;

    @action
    public close() {
        this.active = false;
    }

    @action
    public showInfo(message: string) {
        this.active = true;
        this.message = message;
        this.type = NotificationType.info;
    }

    @action
    public showError(message: string) {
        this.active = true;
        this.message = message;
        this.type = NotificationType.error;
    }
}

export enum NotificationType {
    error = 'error',
    info = 'info'
}

const notification = new Notification();
export default notification;


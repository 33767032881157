import * as EmailValidator from 'email-validator';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { DateTime } from 'luxon';
import { Address } from './stores/domain/address';

export const hasPast = (val: DateTime): boolean => {
    const utcNow = DateTime.utc();
    return val.toUTC() <= utcNow;
}

export const todayOrInFuture = (val: DateTime): boolean => {
    const utcNow = DateTime.utc();
    const dispatchStartOfDay = utcNow.setZone('Australia/Brisbane').startOf('day');
    const utcDay = dispatchStartOfDay.toUTC();

    return val.toUTC() >= utcDay;
}

export const getTime = (val: string): number => {
    if (!hasValue(val)) {
        return NaN;
    }

    const split = val.split(':');
    if (split.length !== 2 || split[1].length !== 2) {
        return NaN;
    }

    const hours = parseInt(split[0], 0);
    const minutes = parseInt(split[1], 0);

    if (isNaN(hours) || hours > 23 || hours < 0 ||
        isNaN(minutes) || minutes > 59 || minutes < 0) {
            return NaN;
    }

    const totalMinutes = hours * 60 + minutes;

    return totalMinutes > 1439 ? NaN : totalMinutes;
}

export const inFuture = (val: DateTime): boolean => {
    const utcNow = DateTime.utc();
    return val.toUTC() > utcNow;
}

export const hasValue = (val: any): boolean => {
    if (!val) return false;
    return val !== null && val !== undefined && val.toString().trim().length > 0;
}

export const inTheFuture = (val: DateTime): string | null => {
    return inFuture(val) ? null : 'Must be in the future';
}

export const todayOrInTheFuture = (val: DateTime): string | null => {
    return todayOrInFuture(val) ? null : 'Must be today or in the future';
}

export const inThePast = (val: DateTime): string | null => {
    return hasPast(val) ? null : 'Must be in the past';
}

export const required = (val: any): string | null => {
    if (val instanceof Address) {
        return hasValue(val.formattedAddress) ? null : 'Required';
    }
    return hasValue(val) ? null : 'Required';
}

export const moreThanZero = (val: number): string | null => {
    return val > 0 ? null : 'Must be more than 0';
}

export const  moreThanZeroIfHasValue = (val: number) => {
    if (!val) return null;
    else return moreThanZero(val);
}

export const email = (val: string): string | null => {
    return EmailValidator.validate(val) ? null : 'Invalid email';
}

export const phone = (val: string): string | null => {
    const util = PhoneNumberUtil.getInstance();
    const valid = () => {
        try {
            const parsedNumber = util.parseAndKeepRawInput(val, 'AU');
            return (parsedNumber && util.isValidNumber(parsedNumber)) || false;
        }
        catch {
            return false;
        }
    }
    return valid() ? null : 'Invalid phone number';
}
import { observable, action, computed } from 'mobx';
import { Model } from './model';
import { Address } from './address';
import { moreThanZero, required } from '../../validate';
import { IVehicleResponse } from '../../services/vehicles-api';
import { Unavailability } from './unavailability';

export class Vehicle extends Model {
    @observable public id: number = 0;
    @observable public address: Address = new Address({});
    @observable public defaultDriverId: number | null = null;
    @observable public defaultDriverName: string = '';
    @observable public packCapacity: number = 0;
    @observable public palletCapacity: number = 0;
    @observable public registration: string = '';
    @observable public tonneCapacity: number = 0;
    @observable public truckType: TruckType = TruckType.None;
    @observable public unavailability: Unavailability[] = [];

    constructor(obj: Partial<Vehicle>) {
        super();
        Object.assign(this, obj);
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'registration': (val: string) => {
            this.errors.registration = required(val);
            return this.errors.registration === null;
        },
        'packCapacity': (val: number) => {
            this.errors.packCapacity = moreThanZero(val);
            return this.errors.packCapacity === null;
        },
        'palletCapacity': (val: number) => {
            this.errors.palletCapacity = moreThanZero(val);
            return this.errors.palletCapacity === null;
        },
        'tonneCapacity': (val: number) => {
            this.errors.tonneCapacity = moreThanZero(val);
            return this.errors.tonneCapacity === null;
        },
        'truckType': (val: TruckType) => {
            this.errors.truckType = val > TruckType.None ? null : 'Invalid truck type';
            return this.errors.truckType === null;
        },
        'address': (val: Address) => val.validate()
    }

    @action
    public static fromResponse(r: IVehicleResponse) {
        const v = new Vehicle({ ...r, unavailability: [] });
        v.address = new Address({ ...r.address });
        v.unavailability = r.unavailability && r.unavailability.map((u, i) => Unavailability.fromResponse(u, i));
        return v;
    }

    @action
    public setDefaultDriver(id: number | null, name: string) {
        this.defaultDriverName = name;
        this.defaultDriverId = id;
    }

    @action
    public addUnavailability(unavailability: Unavailability) {
        if (!this.unavailability.some(x => x.lineId === unavailability.lineId)) {
            this.unavailability.push(unavailability);
        } else this.unavailability = this.unavailability
            .map(x => x.lineId === unavailability.lineId ? unavailability : x);
    }

    @action
    public deleteUnavailability(unavailability: Unavailability) {
        this.unavailability = this.unavailability.filter(u => u.lineId !== unavailability.lineId);
    }

    @computed
    public get truckTypeText() {
        switch(this.truckType) {
            case TruckType.BodyTruck: return 'Body Truck';
            case TruckType.Semi: return 'Semi';
            case TruckType.BodyTruck: return 'BDouble';
            default: return '';
        }
    }
}

export enum TruckType {
    None = 0,
    BodyTruck = 1,
    Semi = 2,
    BDouble = 3
}
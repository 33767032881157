import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { Header } from '../Header/Header';
import DriverForm from './DriverForm';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { ActionItem } from '../ActionsMenu/ActionItem';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import Delete from '@material-ui/icons/Delete';
import { UiStore } from '../../stores/ui-store';
import { action, observable } from 'mobx';
import { DriverStore } from '../../stores/driver-store';
import tracker from '../Loader/loader-tracker';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import { hasValue } from '../../validate';

interface IViewDriverProps {
    driverStore?: DriverStore
    uiStore?: UiStore
}

interface MatchParams {
    id: string;
}

type Props = IViewDriverProps & RouteComponentProps<MatchParams>;

@inject('driverStore', 'uiStore')
@observer
class ViewDriver extends React.Component<Props> {

    @observable private readOnly: boolean = true;
    @observable private deleteDialogActive: boolean = false;

    constructor(props: Props) {
        super(props);
        this.updateDriver = this.updateDriver.bind(this);
        this.deleteDriver = this.deleteDriver.bind(this);
    }

    public async componentDidMount() {
        const driverId = this.props.match.params.id;
        await tracker.track(this.props.driverStore!.loadDriver(Number(driverId)));
    }

    public render() {
        const { driver } = this.props.driverStore!;
        return <div className="view-driver">
            <Header title="Driver" />
            <DriverForm readOnly={this.readOnly} />
            <ActionsMenu>
                {
                    this.readOnly
                    && <ActionItem
                        enabled={true}
                        label="Edit"
                        onClick={() => this.setReadOnly(false)}>
                        <Edit />
                    </ActionItem>
                }
                {
                    this.readOnly
                    && <ActionItem
                        enabled={true}
                        label="Delete"
                        onClick={() => this.setDeleteDialogActive(true)}>
                        <Delete />
                    </ActionItem>
                }
                {
                    !this.readOnly
                    && <ActionItem
                        enabled={true}
                        label="Submit"
                        onClick={this.updateDriver}>
                        <Save />
                    </ActionItem>
                }
            </ActionsMenu>

            <ConfirmDialog
                active={this.deleteDialogActive}
                title="Delete Driver"
                message={`Are you sure you want to delete ${driver.name}? 
                    ${hasValue(driver.defaultVehicleRegistration) ? ` Vehicle ${driver.defaultVehicleRegistration} will be unassigned.` : ''} `}
                onClose={() => this.setDeleteDialogActive(false)} 
                onOk={this.deleteDriver}/>
        </div>
    }

    @action
    private setReadOnly(readOnly: boolean) {
        this.readOnly = readOnly;
    }

    @action
    public setDeleteDialogActive(active: boolean) {
        this.deleteDialogActive = active;
    }

    private async updateDriver() {
        const ok = await tracker.track(this.props.driverStore!.updateDriver());
        if (ok) this.props.history.push('/drivers');
    }

    private async deleteDriver() {
        const ok = await tracker.track(this.props.driverStore!.deleteDriver(), 'confirm-dialog');
        if (ok) this.props.history.push('/drivers');
    }
}

export default ViewDriver;
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { Grid, Button, WithStyles, withStyles } from '@material-ui/core';
import { ScheduleStore } from '../../stores/schedule-store';
import { UiStore } from '../../stores/ui-store';
import { JobStore } from '../../stores/job-store';
import SpareVehicleDialog from './SpareVehicleDialog';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { ContextItem } from '../ContextMenu/ContextItem';
import { ScheduleAvailability } from '../../stores/domain/schedule-availability';
import { globalStyles } from '../../theme';

interface ISpareVehiclesProps extends WithStyles<typeof globalStyles> {
    jobStore?: JobStore
    scheduleStore?: ScheduleStore
    uiStore?: UiStore
}

@inject('jobStore', 'scheduleStore', 'uiStore')
@observer
class SpareVehicles extends React.Component<ISpareVehiclesProps> {

    constructor(props: ISpareVehiclesProps) {
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
    }

    public render() {
        return <div className="schedule-list">
            <div className="section">
                {
                    this.props.scheduleStore!.availableVehicles.map((a, i) => (
                        <Grid container key={i} className="schedule-list-item">
                            <Grid item xs={1}
                                className="icon-container">
                                <ContextMenu
                                    title="options">
                                    {
                                        <ContextItem label="Delete"
                                            onClick={() => this.handleDelete(a)} />
                                    }
                                </ContextMenu>
                            </Grid>
                            <Grid item xs={1}>
                                <div>{a.registration}</div>
                                <div className="headers">Vehicle</div>
                            </Grid>
                            <Grid item xs={2}>
                                <div>From {a.startDispatchTime} to {a.endDispatchTime}</div>
                                <div className="headers">Vehicle Availability</div>
                            </Grid>
                            <Grid item xs={2}>
                                <div>{a.driverName}</div>
                                <div className="headers">Driver</div>
                            </Grid>
                            <Grid item xs={6} />
                        </Grid>
                    ))
                }
            </div>
            <div className="section action-strip">
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Button variant="outlined" onClick={() => {
                            const { date, id } = this.props.scheduleStore!.currentDraftSchedule;
                            this.props.uiStore!.spareVehicleDialogState.init(id, date);
                        }}>Add Spare Vehicle
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <SpareVehicleDialog />
        </div>
    }

    private async handleDelete(availability: ScheduleAvailability) {
        await this.props.scheduleStore!.deleteVehicleAvailability(availability);
    }
}

export default withStyles(globalStyles)(SpareVehicles);
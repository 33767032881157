import * as React from 'react';
import { observer } from 'mobx-react';
import { globalStyles } from '../../theme';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';

interface IExpandingSectionProps extends WithStyles<typeof styles> {
    title: string,
    contentCount?: number,
    children: {
        content: React.ReactNode
    };
    highlight?: boolean
}

@observer
class ExpandingSection extends React.Component<IExpandingSectionProps> {
    constructor(props: IExpandingSectionProps) {
        super(props);
    }

    public render() {
        return <ExpansionPanel 
            className={this.props.classes.expansion} 
            classes={{ expanded: this.props.classes.expanded }}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={this.props.classes.expansionHead}>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={10}
                        className={`${this.props.highlight && this.props.contentCount && this.props.contentCount > 0 ? 'highlight' : ''}`}>
                        {
                            this.props.contentCount
                                ? <React.Fragment>
                                    {this.props.title}
                                    <Badge color="default"
                                        badgeContent={this.props.contentCount}
                                        className={this.props.classes.badge} max={999}
                                        invisible={!this.props.contentCount}>{''}</Badge>
                                </React.Fragment>
                                : this.props.title
                        }
                    </Grid>
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                className={this.props.classes.expansionDetail}>
                {this.props.children.content}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    expansion: {
        boxShadow: 'none',
        width: '100%',
        '&:before': {
            height: 0
        }
    },
    expansionHead: {
        padding: 0,
        paddingTop: 20,
        paddingBottom: 20,
        borderBottom: `1px solid #f1f3f4`,
        fontSize: 18
    },
    expansionDetail: {
        padding: 0
    },
    badge: {
        marginLeft: 15
    },
    badgeHighlighted: {
        marginLeft: 15,
        color: `#fff`,
        backgroundColor: `#ea592c`
    },
    expanded: {
        margin: '0 auto'
    }
});

export default withStyles(styles)(ExpandingSection);
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { VehicleStore } from '../../stores/vehicle-store';
import { TextField, WithStyles, withStyles, FormControl, InputLabel, Select, MenuItem, Grid, FormHelperText } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { asForm, IAsFormProps } from '../Form/AsForm';
import { IRootStore } from '../../initStores';
import Section from '../Section/Section';
import UnavailablitySection from '../Unavailability/UnavailabilitySection';
import AddressSearchBox from '../AddressSearch/AddressSearchBox';
import { Address } from '../../stores/domain/address';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import { IDriverSummary } from '../../stores/domain/driver-summary';
import { DriverStore } from '../../stores/driver-store';
import { TruckType } from '../../stores/domain/vehicle';
import { UiStore } from '../../stores/ui-store';
import { computed } from 'mobx';
import { withChangesPrompt } from '../Prompt/Prompt';
import { Unavailability } from '../../stores/domain/unavailability';
import NumberField, { INumberChangedEvent } from '../NumberField/NumberField';

interface IVehicleFormProps extends WithStyles<typeof globalStyles> {
    vehicleStore?: VehicleStore
    driverStore?: DriverStore
    uiStore?: UiStore
}

@inject('vehicleStore', 'driverStore', 'uiStore')
@observer
class VehicleForm extends React.Component<IVehicleFormProps & IAsFormProps> {

    constructor(props: IVehicleFormProps) {
        super(props);
        this.getDriverSuggestions = this.getDriverSuggestions.bind(this);
        this.renderDriverSuggestion = this.renderDriverSuggestion.bind(this);
        this.handleSaveUnavailablity = this.handleSaveUnavailablity.bind(this);
        this.handleDeleteUnavailablity = this.handleDeleteUnavailablity.bind(this);
    }

    private readonly truckTypes: Array<{ name: string, value: TruckType }> = [
        { name: 'None', value: TruckType.None },
        { name: 'Body Truck', value: TruckType.BodyTruck },
        { name: 'Semi', value: TruckType.Semi },
        { name: 'B-double', value: TruckType.BDouble }
    ];

    public render() {
        const { vehicle } = this.props.vehicleStore!;
        const { vehicleFormState } = this.props.uiStore!;
        const { handleSetProperty, handleChange, handleSelectChanged } = this.props;
        return <div className="form">
            <Section title="Details">
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textField}
                            InputProps={{ disableUnderline: true }}
                            label="Registration"
                            type="text"
                            name="registration"
                            error={!!vehicle.errors.registration}
                            helperText={vehicle.errors.registration || null}
                            value={vehicle.registration}
                            onChange={handleChange}
                            disabled={vehicleFormState.readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <FormControl
                            disabled={vehicleFormState.readOnly}
                            className={this.props.classes.textField}>
                            <InputLabel htmlFor="truckType">Truck Type</InputLabel>
                            <Select
                                disableUnderline
                                error={!!vehicle.errors.truckType}
                                value={vehicle.truckType}
                                onChange={handleSelectChanged}
                                inputProps={{ name: 'truckType', id: 'truckType' }}>
                                {
                                    this.truckTypes.map(t => (<MenuItem value={t.value} key={t.value}>{t.name}</MenuItem>))
                                }
                            </Select>
                            {
                                vehicle.errors.truckType &&
                                <FormHelperText error>{vehicle.errors.truckType || null}</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <NumberField
                            textProps={{
                                className: this.props.classes.textField,
                                label: "Pack Capacity",
                                helperText: vehicle.errors.packCapacity || null,
                                disabled: vehicleFormState.readOnly
                            }}
                            InputProps={{ disableUnderline: true }}
                            name="packCapacity"
                            error={!!vehicle.errors.packCapacity}
                            value={vehicle.packCapacity}
                            onChange={(e: INumberChangedEvent) => this.props.vehicleStore!.vehicle.updateAndValidate('packCapacity', e.parsedValue)}                            
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <NumberField
                            textProps={{
                                className: this.props.classes.textField,
                                label: "Pallet Capacity",
                                helperText: vehicle.errors.palletCapacity || null,
                                disabled: vehicleFormState.readOnly
                            }}
                            InputProps={{ disableUnderline: true }}
                            name="palletCapacity"
                            error={!!vehicle.errors.palletCapacity}
                            value={vehicle.palletCapacity}
                            onChange={(e: INumberChangedEvent) => this.props.vehicleStore!.vehicle.updateAndValidate('palletCapacity', e.parsedValue)}                            
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <NumberField
                            textProps={{
                                className: this.props.classes.textField,
                                label: "Tonne Capacity",
                                helperText: vehicle.errors.tonneCapacity || null,
                                disabled: vehicleFormState.readOnly
                            }}
                            InputProps={{ disableUnderline: true }}
                            name="tonneCapacity"
                            error={!!vehicle.errors.tonneCapacity}
                            value={vehicle.tonneCapacity}
                            onChange={(e: INumberChangedEvent) => this.props.vehicleStore!.vehicle.updateAndValidate('tonneCapacity', e.parsedValue)}                            
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <AddressSearchBox
                            className={this.props.classes.textFieldFullWidth}
                            address={vehicle.address.formattedAddress}
                            handleAddressSelected={(address: Address) => handleSetProperty!('address', address)}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetProperty!('address', new Address(
                                { ...vehicle.address, formattedAddress: e.currentTarget.value })
                            )}
                            label="Location"
                            fieldName="address"
                            error={vehicle.address.errors.formattedAddress}
                            disabled={vehicleFormState.readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            label="Default Driver"
                            onSelect={(selected: SuggestionItem<IDriverSummary>) => {
                                vehicleFormState.setSelectedDriver(selected);
                                vehicle.setDefaultDriver(selected.value.id, selected.value.name);
                            }}
                            getSuggestions={this.getDriverSuggestions}
                            errorText={vehicle.errors.defaultDriver}
                            helpText={this.getHelpText}
                            disabled={vehicleFormState.readOnly}
                            selectedItem={vehicleFormState.selectedDriver}
                            suggestionToComponent={{
                                containerStyle: { minHeight: '50px', height: 'auto' },
                                getComponent: this.renderDriverSuggestion
                            }}
                            onClear={!vehicleFormState.selectedDriver!.value.id ? undefined : () => {
                                vehicleFormState.unassignDriver();
                                vehicle.setDefaultDriver(null, '');
                            }}
                        />
                    </Grid>
                </Grid>
            </Section>
            <UnavailablitySection
                unavailabilities={vehicle.unavailability}
                readOnly={vehicleFormState.readOnly}
                onSave={this.handleSaveUnavailablity}
                onDelete={this.handleDeleteUnavailablity}
            />
        </div >
    }

    @computed
    private get getHelpText(): string {
        const { selectedDriver, initialDefaultDriverId } = this.props.uiStore!.vehicleFormState;
        if (selectedDriver
            && selectedDriver.value.defaultVehicle
            && initialDefaultDriverId !== selectedDriver.value.id) {
            return `${selectedDriver.value.name} will be unassigned from ${selectedDriver.value.defaultVehicle.registration}`;
        }
        return '';
    }

    private async getDriverSuggestions(value: string): Promise<Array<SuggestionItem<IDriverSummary>>> {
        return await this.props.driverStore!.searchDrivers(value, this.props.vehicleStore!.vehicle.truckType);
    }

    private handleSaveUnavailablity(unavailability: Unavailability) {
        this.props.vehicleStore!.vehicle.addUnavailability(unavailability);
    }

    private handleDeleteUnavailablity(unavailability: Unavailability) {
        this.props.vehicleStore!.vehicle.deleteUnavailability(unavailability);
    }

    private renderDriverSuggestion(item: SuggestionItem<IDriverSummary>) {
        const getSubText = () => {
            if (!item.value.id) {
                return 'No driver to be assigned';
            } else if (item.value.id === this.props.uiStore!.vehicleFormState.initialDefaultDriverId) {
                return 'Assigned to this vehicle';
            } else {
                return item.value.defaultVehicle
                    ? `Already assigned to ${item.value.defaultVehicle.registration}`
                    : 'Unassigned';
            }
        };
        return <div className="autocomplete-item">
            <span>
                <div>{item.label}</div>
                <div className="sub-text">{getSubText()}</div>
            </span>
        </div>
    }

    public componentWillUnmount() {
        this.props.vehicleStore!.unloadVehicle();
        this.props.uiStore!.vehicleFormState.init();
    }
}

export default inject((s: IRootStore) => ({ model: s.vehicleStore.vehicle }))
    (asForm(withStyles(globalStyles)(withChangesPrompt(VehicleForm))));



import { Driver } from './driver';
import { Vehicle } from './vehicle';
import { Task, TaskType } from './task';
import { Model } from './model';
import { observable, computed, action, runInAction } from 'mobx';
import { IVehicleRouteReponse } from '../../services/schedule-api';

export class VehicleRoute extends Model {
    @observable public driver: Driver | undefined = undefined;
    @observable public guid: string = '';
    @observable public id: number = 0;
    @observable public isLocked: boolean = false;
    @observable public loadScore: number = 0;
    @observable public scheduleId: number = 0;
    @observable public tasks: Task[] = [];
    @observable public totalHours: number = 0;
    @observable public vehicle: Vehicle | undefined = undefined;

    constructor(obj: Partial<VehicleRoute>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    public static fromResponse(r: IVehicleRouteReponse) {
        const j = new VehicleRoute({
            ...r,
            driver: r.driver && Driver.fromResponse(r.driver),
            vehicle: r.vehicle && Vehicle.fromResponse(r.vehicle),
            tasks: r.tasks.map(i => Task.fromResponse(i))
        });
        return j;
    }

    @computed
    public get isEightHours(){
        if(this.tasks.length > 0){
            return this.tasks[this.tasks.length -1].etdUtc!.diff(this.tasks![0].etaUtc!, 'hours').hours >= 8;
        }
        return false;
    }

    @action
    public moveTask(dragIndex: number, hoverIndex: number) {
        const taskToMove = this.tasks[dragIndex];
        let newIndex = hoverIndex;

        if (taskToMove.type === TaskType.Delivery) {
            const pickUpTaskIndex = this.tasks.findIndex(t => t.type === TaskType.Pickup && t.job!.id === taskToMove.job!.id);
            if (pickUpTaskIndex >= hoverIndex) {
                newIndex = pickUpTaskIndex + 1;
            }
        } else if (taskToMove.type === TaskType.Pickup) {
            const deliveryTaskIndex = this.tasks.findIndex(t => t.type === TaskType.Delivery && t.job!.id === taskToMove.job!.id);
            if (deliveryTaskIndex <= hoverIndex) {
                newIndex = deliveryTaskIndex -1;
            }
        }
        this.tasks.splice(newIndex, 0, this.tasks.splice(dragIndex, 1)[0]);
    }

    public validate(): boolean {
        const validation = [
            super.validate(),
            ...this.tasks.map(i => i.validate())
        ];
        return validation.every(v => v);
    }
}


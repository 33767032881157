import { action, observable } from 'mobx'
import { Job } from '../domain/job';

export class UnassignLiveJobDialogState {
    @observable public jobId: number = 0;
    @observable public orderNumber: string = "";

    @action
    public reset() {
        this.jobId = 0;
        this.orderNumber = "";
    }

    @action
    public init(job: Job) {
        this.jobId = job.id;
        this.orderNumber = job.orderNumber;
    }
}
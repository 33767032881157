import { observer, inject } from 'mobx-react';
import React from 'react';
import tracker from '../Loader/loader-tracker';
import { UiStore } from '../../stores/ui-store';
import { WithStyles, withStyles } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { JobStore } from '../../stores/job-store';
import { CloseButton, OkButton } from '../Dialog/Buttons/Buttons';
import Dialog from '../Dialog/Dialog';
import { JobType } from '../../stores/domain/job';

interface IUndoDialogProps extends WithStyles<typeof globalStyles> {
    jobStore?: JobStore
    uiStore?: UiStore
}

@inject('jobStore', 'uiStore')
@observer
class UndoDialog extends React.Component<IUndoDialogProps> {
    
    constructor(props: IUndoDialogProps) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public render() {
        const { undoDialogState } = this.props.uiStore!;

        return undoDialogState.jobType !== JobType.Default && <Dialog
            title={`Undo ${undoDialogState.jobTypeText}`}
            type="slide"
            onClose={() => this.handleCancel()}
            active={true}
            className="undo-dialog">
        {{
            content: <React.Fragment>
                {`Are you sure you want to undo this ${undoDialogState.jobTypeText} Job?`}
            </React.Fragment>,
            actions: <React.Fragment>
                <CloseButton onClick={this.handleCancel} />
                <OkButton onClick={this.handleSubmit} />
            </React.Fragment>
        }}
        </Dialog>
    }

    private handleCancel() {
        this.props.uiStore!.undoDialogState.reset();
    }

    private async handleSubmit() {
        const { undoDialogState } = this.props.uiStore!;

        const ok = await tracker.track(this.props.jobStore!.undoJob(undoDialogState.jobId));
        if (ok === true) {
            this.props.uiStore!.undoDialogState.reset();
        }
    }
}

export default withStyles(globalStyles)(UndoDialog);
import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Task, TaskStatus, TaskType } from '../../stores/domain/task';
import {Job, JobStatus, JobType} from '../../stores/domain/job';
import { ScheduleStore } from '../../stores/schedule-store';
import { UiStore } from '../../stores/ui-store';
import { ContextItem } from '../ContextMenu/ContextItem';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import './styles.scss';
import {JobStore} from "../../stores/job-store";

interface ITaskContextMenuProps {
    task: Task;
    uiStore?: UiStore;
    jobStore?: JobStore;
    scheduleStore?: ScheduleStore;
    vehicleDepot: string;
    vehicleId?: number;
    isLiveView: boolean;
}

@inject('uiStore', 'jobStore', 'scheduleStore')
@observer
class TaskContextMenu extends React.Component<ITaskContextMenuProps> {
    constructor(props: ITaskContextMenuProps) {
        super(props);
        this.onCompleteTask = this.onCompleteTask.bind(this);
    }

    public render() {
        const { task, uiStore, vehicleDepot, isLiveView, vehicleId } = this.props;
        const jobNotComplete = task.job && task.job.status < JobStatus.Complete;
        const hasLiveContextMenuItems = isLiveView && (task.status === TaskStatus.OnRoute || task.canCompleteManually || jobNotComplete)

        const isLockedToVehicle = task.job && task.job.vehicleId
        const canBeLockedTypes = [ JobType.Default, JobType.TransShipPickup, JobType.TransShipDelivery ]
        const canLockToVehicle = task.job && task.job.status < JobStatus.OnRoute && canBeLockedTypes.includes(task.job.type) && vehicleId
        const hasDraftContextMenuItems = !isLiveView && canLockToVehicle

        const hasContextMenu = hasLiveContextMenuItems || hasDraftContextMenuItems

        return (<Grid item xs={1}
            className="icon-container"
            onClick={(event: React.MouseEvent<Element, MouseEvent>) => event.stopPropagation()}>
            {
                hasContextMenu &&
                <ContextMenu title="route options">
                    {
                        isLiveView &&
                        task.status === TaskStatus.OnRoute &&
                        <ContextItem label="Return to ABC"
                            onClick={() => uiStore!.abcReturnDialogState.init(task, vehicleDepot)} />
                    }
                    {
                        isLiveView &&
                        task.status === TaskStatus.OnRoute &&
                        <ContextItem label="Return to Supplier"
                            onClick={() => uiStore!.supplierReturnDialogState.init(task)} />
                    }
                    {
                        isLiveView &&
                        task.canCompleteManually &&
                        <ContextItem label="Complete"
                            onClick={this.onCompleteTask} />
                    }
                    {
                        isLiveView &&
                        jobNotComplete
                        && <ContextItem
                            label="Remove from live schedule"
                            onClick={() => {
                                this.handleUnassignLiveJob(task.job!)
                            }} />
                    }
                    {
                        !isLiveView &&
                        canLockToVehicle &&
                        !isLockedToVehicle &&
                        <ContextItem label="Lock to Vehicle"
                                     onClick={async () => await this.handleLockToVehicle(task.job!, vehicleId!)} />
                    }
                    {
                        !isLiveView &&
                        canLockToVehicle &&
                        isLockedToVehicle &&
                        <ContextItem label="Unlock from Vehicle"
                                     onClick={async () => await this.handleLockToVehicle(task.job!, null)} />
                    }
                </ContextMenu>
            }
        </Grid>);
    }

    public handleUnassignLiveJob(job: Job): void {
        this.props.uiStore!.resetDialogs();
        this.props.uiStore!.unassignLiveJobDialogState.init(job);
    }

    private async handleLockToVehicle(job: Job, vehicleId: number | null) {
        await this.props.scheduleStore!.lockToVehicle(job.id, vehicleId);
    }
    
    private async onCompleteTask() {
        const task = this.props.task;
        if (task.type === TaskType.Delivery) {
            await this.props.scheduleStore!.completeDeliveryTask(task);
        }
        else if (task.type === TaskType.Pickup) {
            await this.props.scheduleStore!.completePickupTask(task);
        }
    }
}

export default TaskContextMenu;
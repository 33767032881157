import * as signalR from '@microsoft/signalr';

export class SignalRClient {
    private apiUrl: string = '';
    private connection?: signalR.HubConnection = undefined;
    public onJobsUpdate?: () => Promise<void> = () => Promise.resolve(undefined);
    public onScheduleError?: (runId: string, error: string) => Promise<void> = (runId: string, error: string) => Promise.resolve(undefined);
    public onScheduleGenerated?: (runId: string) => Promise<void> = (runId: string) => Promise.resolve(undefined);
    public onScheduleGenerationStarted?: (timeOutInSeconds: number) => Promise<void> = (timeOutInSeconds: number) => Promise.resolve(undefined);
    public onScheduleUpdateStarted?: (timeOutInSeconds: number) => Promise<void> = (timeOutInSeconds: number) => Promise.resolve(undefined);
    private reconnectCount: number = 1;
    private stopped: boolean = false;

    public constructor(apiUrl: string) {
        this.apiUrl = apiUrl;

        this.fibonacci = this.fibonacci.bind(this);
        this.handleJobsUpdated = this.handleJobsUpdated.bind(this);
        this.handleScheduleError = this.handleScheduleError.bind(this);
        this.handleScheduleGenerated = this.handleScheduleGenerated.bind(this);
        this.handleScheduleGenerationStarted = this.handleScheduleGenerationStarted.bind(this);
        this.handleScheduleUpdateStarted = this.handleScheduleUpdateStarted.bind(this);
        this.onDisconnect = this.onDisconnect.bind(this);
    }

    public start(): void {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(this.apiUrl + '/asaphub')
            .build();
        this.connection.on('JobsUpdated', this.handleJobsUpdated);
        this.connection.on('ScheduleError', this.handleScheduleError);
        this.connection.on('ScheduleGenerationStarted', this.handleScheduleGenerationStarted);
        this.connection.on('ScheduleUpdateStarted', this.handleScheduleUpdateStarted);
        this.connection.on('ScheduleGenerated', this.handleScheduleGenerated);
        this.connection.onclose(this.onDisconnect);

        this.connection.start();
    }

    public stop(): void {
        if (this.stopped === false) {
            if (this.connection !== undefined) {
                this.connection!.stop();
            }

            this.stopped = true;
        }
    }

    private onDisconnect(err?: Error): void {
        if (this.stopped === false && this.reconnectCount < 10) {
            setTimeout(() => {
                this.connection!.start();
            }, this.fibonacci(this.reconnectCount));
        }
    }
    
    private async handleJobsUpdated(): Promise<void> {
        console.log('jobsupdated');
        if (this.onJobsUpdate) {
            return this.onJobsUpdate();
        }
    }

    private async handleScheduleError(runId: string, error: string): Promise<void> {
        console.log(`schedule error ${runId} ${error}`);
        if (this.onScheduleError) {
            return this.onScheduleError(runId, error);
        }
    }

    private async handleScheduleGenerated(runId: string): Promise<void> {
        console.log(`schedule generated ${runId}`);
        if (this.onScheduleGenerated) {
            return this.onScheduleGenerated(runId);
        }
    }

    private async handleScheduleGenerationStarted(timeOutInSeconds: number): Promise<void> {
        console.log(`schedule generation started with timeout ${timeOutInSeconds}`);
        if (this.onScheduleGenerationStarted) {
            return this.onScheduleGenerationStarted(timeOutInSeconds);
        }
    }

    private async handleScheduleUpdateStarted(timeOutInSeconds: number): Promise<void> {
        console.log(`schedule update started with timeout ${timeOutInSeconds}`);
        if (this.onScheduleUpdateStarted) {
            return this.onScheduleUpdateStarted(timeOutInSeconds);
        }
    }

    private fibonacci(num: number, memo: number[] = []): number {
        if (memo[num]) return memo[num];
        if (num <= 1) return 1;
      
        return memo[num] = this.fibonacci(num - 1, memo) + this.fibonacci(num - 2, memo);
    }
}
import * as React from 'react';
import './styles.scss';
import { DriverUnavailability } from '../../stores/domain/driver-unavailability';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import DriverUnavailabilityItem from './DriverUnavailabilityItem';
import UnavailablityDialog from '../DriverUnavailability/DriverUnavailabilityDialog';
import { observable, action, computed } from 'mobx';

interface IDriverUnavailabilitySectionProps {
    unavailabilities: DriverUnavailability[]
    readOnly?: boolean
    onSave: (model: DriverUnavailability) => void
    onDelete: (model: DriverUnavailability) => void
}

@observer
class DriverUnavailabilitySection extends React.Component<IDriverUnavailabilitySectionProps> {

    @observable private dialogActive: boolean = false;
    @observable private unavailability: DriverUnavailability = new DriverUnavailability({});

    constructor(props: IDriverUnavailabilitySectionProps) {
        super(props);
        this.handleAddNewDriverUnavailability = this.handleAddNewDriverUnavailability.bind(this);
    }

    public render() {
        return <section className="abc-section unavailability-section">
            <Grid container>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <h2>Unavailability</h2>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {
                        this.orderedUnavailabilities.map((u, i) => (<DriverUnavailabilityItem
                            key={i}
                            model={u}
                            readOnly={this.props.readOnly}
                            onDeleteDriverUnavailability={this.props.onDelete}
                            onOpenDialog={() => this.setDialogActive(true, u)} />))
                    }
                </Grid>
                {
                    !this.props.unavailabilities.length
                    && <React.Fragment>
                        <Grid item xs={1} />
                        <Grid item xs><p className="sub-text">No unavailability recorded</p></Grid>
                    </React.Fragment>
                }
            </Grid>
            {
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        {
                            !this.props.readOnly
                            && <a className="action link-button" onClick={this.handleAddNewDriverUnavailability}>Add record</a>
                        }
                    </Grid>
                </Grid>
            }
            <UnavailablityDialog
                model={this.unavailability.clone()}
                readOnly={this.props.readOnly}
                active={this.dialogActive}
                onClose={() => this.setDialogActive(false)}
                onSave={(model: DriverUnavailability) => {
                    this.props.onSave(model);
                    this.setDialogActive(false);
                }}
            />
        </section>
    }

    @action
    private setDialogActive(active: boolean, unavailability?: DriverUnavailability) {
        if (unavailability) this.unavailability = unavailability;
        this.dialogActive = active;
    }

    @computed
    private get orderedUnavailabilities() {
        return this.props.unavailabilities.slice().sort((a: DriverUnavailability, b: DriverUnavailability) => {
            return a.startDate! < b.startDate! ? -1 : 1;
        })
    }

    private handleAddNewDriverUnavailability() {
        this.setDialogActive(true, new DriverUnavailability({ lineId: this.props.unavailabilities.length + 1 }));
    }
}

export default DriverUnavailabilitySection;
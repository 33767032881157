import { observable, action, computed } from 'mobx';
import { Model } from '../../stores/domain/model';

class ChangeTracker {
    @observable public initalState: string | null = null;
    @observable public state: Model | null = null;

    @action
    public track(model: Model) {
        this.initalState = model.serialized;
        this.state = model;
    }

    @action
    public reset() {
        if (!this.state) return;
        this.initalState = this.state.serialized;
    }

    @computed
    public get isDirty() {
        if (!this.state) return false;
        return this.initalState !== this.state.serialized;
    }
}

const changeTracker = new ChangeTracker();
export default changeTracker;
import { Model } from './model';
import { moreThanZero, required } from '../../validate';
import { observable, runInAction } from 'mobx';

export class TaskToMove extends Model {
    @observable public index: number = 0;
    @observable public startIndex: number = 0;
    @observable public guid: string = '';
    
    constructor(obj: Partial<TaskToMove>) {
        super();
        runInAction(() => {
            Object.assign(this, obj);
            this.startIndex = this.index;
        } );
    }
    
    protected validators: { [name: string]: (val: any) => boolean } = {
        'index': (val: number) => {
            if (val === 0) {
                this.errors.index = 'Must be non zero';
                return false;
            }

            this.errors.index = required(val);
            
            if (!this.errors.index) {
                this.errors.index = moreThanZero(val);
            }
            return this.errors.index === null;
        }
    }
}
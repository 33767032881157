import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, WithStyles, withStyles } from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { dateFormatted } from '../../datetime';
import { IRootStore } from '../../initStores';
import { Address } from '../../stores/domain/address';
import { Job, JobType } from '../../stores/domain/job';
import { JobStore } from '../../stores/job-store';
import { UiStore } from '../../stores/ui-store';
import { globalStyles } from '../../theme';
import { hasValue } from '../../validate';
import AddressSearchBox from '../AddressSearch/AddressSearchBox';
import { asForm, IAsFormProps } from '../Form/AsForm';
import { withChangesPrompt } from '../Prompt/Prompt';
import Section from '../Section/Section';
import ImageGallery from './ImageGallery';
import JobAuditLogs from './JobAuditLogs';
import JobOrderItem from './JobOrderItem';
import SignatureField from './SignatureField';

interface IJobFormProps extends WithStyles<typeof globalStyles> {
    jobStore?: JobStore
    uiStore?: UiStore
    readOnly?: boolean
}

type Props = IJobFormProps & RouteComponentProps<{}>;

@inject('jobStore', 'uiStore')
@observer
class JobForm extends React.Component<Props & IAsFormProps> {

    constructor(props: Props) {
        super(props);

        this.handleViewRelatedJob = this.handleViewRelatedJob.bind(this);
    }

    public render() {
        const { job } = this.props.jobStore!;
        const { jobFormState } = this.props.uiStore!;
        const { handleChange, handleSetProperty } = this.props;
        return <div className="job-form form">
            {job.parentJobId && <Section title="Parent Job">
                <Grid container>
                    {this.getRelatedJobType(job.type, job.parentJobId!)}
                </Grid>
            </Section>}
            {job.relatedJobs.length > 0 && <Section title="Related Jobs">
                <Grid container>
                    {job.relatedJobs.map(x => this.getRelatedJobType(x.type, x.id))}
                </Grid>
            </Section>}
            <Section title="Details">
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textFieldFullWidth}
                            InputProps={{ disableUnderline: true }}
                            label="Delivery Date"
                            value={dateFormatted(job.deliveryDate)}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            className={this.props.classes.textField}
                            InputProps={{ disableUnderline: true }}
                            label="Timeslot"
                            value={job.deliveryTimeslotText}
                            disabled />
                    </Grid>
                </Grid>
                {
                    job.subcontract
                        ? <div className="detail-and-icon">
                            <SupervisorAccount /> <span>This job is subcontracted.</span>
                        </div>
                        : <Grid container>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    className={this.props.classes.textField}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            input: !hasValue(job.vehicleRegistration)
                                                ? this.props.classes.highlightText : ''
                                        }
                                    }}
                                    label="Vehicle"
                                    value={job.vehicleRegistration || 'Unassigned'}
                                    disabled />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <TextField
                                    className={this.props.classes.textField}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            input: !hasValue(job.driverName)
                                                ? this.props.classes.highlightText : ''
                                        }
                                    }}
                                    label="Driver"
                                    value={job.driverName || 'Unassigned'}
                                    disabled />
                            </Grid>
                        </Grid>
                }
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textFieldFullWidth}
                            InputProps={{ disableUnderline: true }}
                            label="Pickup Number"
                            type="text"
                            name="pickupNumber"
                            error={!!job.errors.pickupNumber}
                            helperText={job.errors.pickupNumber || null}
                            value={job.pickupNumber}
                            onChange={handleChange}
                            disabled={jobFormState.readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <FormControl
                            disabled={jobFormState.readOnly}
                            className={this.props.classes.textField}>
                            <InputLabel htmlFor="truckType">Truck Type</InputLabel>
                            <Select
                                disableUnderline
                                error={!!job.errors.bodyTruck}
                                value={job.bodyTruck}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => 
                                    job.updateAndValidate('bodyTruck', JSON.parse(e.target.value))}
                                inputProps={{ name: 'bodyTruck', id: 'bodyTruck' }}>
                                <MenuItem value="false" key="Standard">Standard</MenuItem>
                                <MenuItem value="true" key="BodyTruck">Body Truck</MenuItem>
                            </Select>
                            {
                                job.errors.bodyTruck &&
                                <FormHelperText error>{job.errors.bodyTruck || null}</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                    <Grid container>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textFieldFullWidth}
                            InputProps={{ disableUnderline: true }}
                            label="Status"
                            value={Job.getStatusText(job.status)}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            className={this.props.classes.textField}
                            InputProps={{ disableUnderline: true }}
                            label="Draft Status"
                            value={Job.getDraftStatusText(job.draftStatus)}
                            disabled />
                    </Grid>
                </Grid>
                </Grid>
            </Section>
            <Section title="Customer">
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textFieldFullWidth}
                            InputProps={{ disableUnderline: true, className: 'job-input-field' }}
                            label="Customer"
                            value={job.customerName}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textFieldFullWidth}
                            InputProps={{ disableUnderline: true, className: 'job-input-field' }}
                            label="Site Contact"
                            value={job.customerContactName || ''}
                            disabled />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textFieldFullWidth}
                            InputProps={{ disableUnderline: true, className: 'job-input-field' }}
                            label="Contact Phone"
                            value={job.customerContactPhone || ''}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textFieldFullWidth}
                            InputProps={{ disableUnderline: true, className: 'job-input-field' }}
                            label="Contact Email"
                            value={job.customerContactEmail || ''}
                            disabled />
                    </Grid>
                </Grid>
            </Section>
            <Section title="Order">
                {
                    job.jobItems.map((jobItem, i) => (<JobOrderItem key={i} model={jobItem} />))
                }
            </Section>
            <Section title="Pickup">
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <AddressSearchBox
                            className={this.props.classes.textFieldFullWidth}
                            address={job.pickupAddress.formattedAddress}
                            handleAddressSelected={(address: Address) => handleSetProperty!('pickupAddress',
                                { ...address, openingTime: job.pickupAddress.openingTime, closingTime: job.pickupAddress.closingTime })}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetProperty!('pickupAddress', new Address(
                                { ...job.pickupAddress, formattedAddress: e.currentTarget.value })
                            )}
                            label="Location"
                            fieldName="pickupAddress"
                            error={job.pickupAddress.errors.formattedAddress}
                            disabled={jobFormState.readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textField}
                            InputProps={{ disableUnderline: true }}
                            label="Pickup Window"
                            value={job.pickupWindowText}
                            disabled />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            className={this.props.classes.textFieldFullWidth}
                            InputProps={{ disableUnderline: true }}
                            label="Driver Comment"
                            value={job.pickupComment || ''}
                            multiline
                            disabled />
                    </Grid>
                </Grid>
            </Section>
            <Section title="Delivery">
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textFieldFullWidth}
                            InputProps={{ disableUnderline: true }}
                            label="Location"
                            value={job.deliveryAddress.formattedAddress}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textField}
                            InputProps={{ disableUnderline: true }}
                            label="Delivery Window"
                            value={job.deliveryWindow}
                            disabled />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className={this.props.classes.textField}
                            InputProps={{ disableUnderline: true }}
                            label="Special Instructions"
                            value={job.specialInstructionsText}
                            disabled />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            className={this.props.classes.textFieldFullWidth}
                            InputProps={{ disableUnderline: true }}
                            label="Driver Comment"
                            value={job.deliveryComment || ''}
                            multiline
                            disabled />
                    </Grid>
                </Grid>
            </Section>
            <Section title="Delivery">
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <ImageGallery />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <SignatureField />
                    </Grid>
                </Grid>
            </Section>
            <Section title="Audit Information">
                <JobAuditLogs />
            </Section>
        </div>
    }

    public componentWillUnmount() {
        this.props.jobStore!.unloadJob();
    }

    public async handleViewRelatedJob(jobId: number) {
        await this.props.jobStore!.loadJob(Number(jobId));
        this.props.history.push(`/view-job/${jobId}`);
    }

    public getRelatedJobType(type: JobType, id: number) {
        switch (type) {
            case JobType.PreLoadPickup:
                return <React.Fragment key={id}>
                    <ArrowDownward />
                    <span className="job-link" onClick={() => this.handleViewRelatedJob(id)}>
                        {`Job Id: ${id} (Pre-Load)`}
                    </span>
                </React.Fragment>;
            case JobType.PreLoadDelivery:
                return <React.Fragment key={id}>
                    <ArrowDownward />
                    <span className="job-link" onClick={() => this.handleViewRelatedJob(id)}>
                        {`Job Id: ${id} (Pre-Load)`}
                    </span>
                </React.Fragment>;
            case JobType.TransShipPickup:
                return <React.Fragment key={id}>
                    <SwapHoriz />
                    <span className="job-link" onClick={() => this.handleViewRelatedJob(id)}>
                        {`Job Id: ${id} (Trans-Shipped)`}
                    </span>
                </React.Fragment>;
            case JobType.TransShipDelivery:
                return <React.Fragment key={id}>
                    <SwapHoriz />
                    <span className="job-link" onClick={() => this.handleViewRelatedJob(id)}>
                        {`Job Id: ${id} (Trans-Shipped)`}
                    </span>
                </React.Fragment>;
        }
        return null;
    }
}

export default inject((s: IRootStore) => ({ model: s.jobStore.job }))
    (asForm(withStyles(globalStyles)(withChangesPrompt(JobForm))));
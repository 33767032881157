import { adalApiFetch } from '../adalConfig';
import { Vehicle, TruckType } from '../stores/domain/vehicle';
import { Address } from '../stores/domain/address';
import { ScheduleAvailability } from '../stores/domain/schedule-availability';
import { IScheduleAvailabilityResponse } from './schedule-api';
import { DriverTransfer } from '../stores/domain/driver-transfer';

export class VehiclesApi {
    public async searchSpareVehicles(vehicleRouteId: number, query: string): Promise<IVehicleSummaryResponse[]> {
        const response = await adalApiFetch(`api/vehicles/sparevehicles`,
            { method: 'post', body: JSON.stringify({ vehicleRouteId, query })});
        return (response.ok ? await response.json() : []) as IVehicleSummaryResponse[];
    }

    /**
     * Returns a list of available vehicles available for transfer.
     * @param driverTransfer - the driver transfer to query from.
     * @param query - A query string matching the vehicle registration.
     */
    public async searchTransferVehicles(driverTransfer: DriverTransfer, query: string): Promise<IScheduleAvailabilityResponse[]> {
        const response = await adalApiFetch(`api/vehicleavailabilities/transfervehicles`,
            { method: 'post', body: JSON.stringify({
                runId: driverTransfer.runId,
                routeGuid: driverTransfer.routeGuid,
                taskGuid: driverTransfer.taskGuid,
                transferLoad: driverTransfer.transferLoad,
                query })});
        return (response.ok ? await response.json() : []) as IScheduleAvailabilityResponse[];
    }

    public async getSpareVehicles(runId: string): Promise<IScheduleAvailabilityResponse[]> {
        const response = await adalApiFetch(`api/vehicleavailabilities/byrun`,
            { method: 'post', body: JSON.stringify({ runId })});
        return (response.ok ? await response.json() : []) as IScheduleAvailabilityResponse[];
    }

    public async searchAvailableVehicles(jobId: number, query: string, includeVehiclesWithoutDefaultDriver: boolean): Promise<IVehicleSummaryResponse[]> {
        const response = await adalApiFetch(`api/vehicleavailabilities/byjob`,
            { method: 'post', body: JSON.stringify({ jobId, query, includeVehiclesWithoutDefaultDriver})});
        return (response.ok ? await response.json() : []) as IVehicleSummaryResponse[];
    }

    public async getVehicles(): Promise<IVehicleSummaryResponse[]> {
        const response = await adalApiFetch(`api/vehicles/summary`);
        return (response.ok ? await response.json() : []) as IVehicleSummaryResponse[];
    }

    public async getVehicle(vehicleId: number) {
        const response = await adalApiFetch(`api/vehicles/${vehicleId}`);
        return (response.ok ? await response.json() : undefined) as IVehicleResponse;
    }

    public async createVehicle(vehicle: Vehicle): Promise<boolean> {
        const response = await adalApiFetch(`api/vehicles`, { method: 'post', body: vehicle.serialized });
        return response.ok;
    }

    public async updateVehicle(vehicle: Vehicle): Promise<boolean> {
        const response = await adalApiFetch(`api/vehicles`, { method: 'put', body: vehicle.serialized });
        return response.ok;
    }

    public async deleteVehicle(vehicleId: number) {
        const response = await adalApiFetch(`api/vehicles/${vehicleId}`, { method: 'delete' });
        return response.ok;
    }

    public async addVehicleAvailability(availability: ScheduleAvailability): Promise<boolean> {
        const response = await adalApiFetch(`api/vehicleavailabilities`, {
            method: 'post',
            body: JSON.stringify({
                vehicleId: availability.vehicleId,
                driverId: availability.driverId,
                scheduleId: availability.scheduleId,
                dispatchDate: availability.dispatchDate.toFormat('yyyy-MM-dd'),
                startDispatchTime: availability.startDispatchTime,
                endDispatchTime: availability.endDispatchTime,
            })
        });
        return response.ok;
    }

    public async getVehicleAvailabilities(scheduleId: number): Promise<IScheduleAvailabilityResponse[]> {
        const response = await adalApiFetch(`api/vehicleavailabilities/byscheduleid/${scheduleId}`);
        return (response.ok ? await response.json() : []) as IScheduleAvailabilityResponse[];
    }

    public async deleteVehicleAvailability(availabilityId: number): Promise<boolean> {
        const response = await adalApiFetch(`api/vehicleavailabilities/${availabilityId}`, { method: 'delete'});
        return response.ok;
    }
}

interface IVehicleSummaryResponse {
    id: number;
    availableFromUtc: string;
    registration: string;
    defaultDriverName: string;
    suburb: string;
    validAddress: boolean;
}

export interface IVehicleResponse {
    id: number;
    registration: string;
    palletCapacity: number;
    tonneCapacity: number;
    address: Address;
    defaultDriverId: number;
    truckType: TruckType;
    unavailability: IUnavailabilityResponse[];
}

export interface IUnavailabilityResponse {
    id: number;
    reason: string;
    startDateTimeUtc: string;
    endDateTimeUtc: string;
    address: Address;
}
import { adalApiFetch } from '../adalConfig';
import { Driver, LicenseType } from '../stores/domain/driver';
import { Address } from '../stores/domain/address';
import { IUnavailabilityResponse } from './vehicles-api';
import { TruckType } from '../stores/domain/vehicle';
import { ScheduleAvailability } from '../stores/domain/schedule-availability';
import { IScheduleAvailabilityResponse } from './schedule-api';
import { DayOfWeekFlags } from '../stores/domain/day-of-week-flags';
import { DateTime } from 'luxon';

export class DriversApi {
    public async searchDrivers(query: string, truckType: TruckType): Promise<IDriverSummaryResponse[]> {
        const response = await adalApiFetch(`api/drivers/summary/${encodeURIComponent(query)}?TruckType=${truckType}`);
        return (response.ok ? await response.json() : []) as IDriverSummaryResponse[];
    }

    public async getDrivers(): Promise<IDriverSummaryResponse[]> {
        const response = await adalApiFetch(`api/drivers/summary`);
        return (response.ok ? await response.json() : []) as IDriverSummaryResponse[];
    }

    public async getDriver(driverId: number) {
        const response = await adalApiFetch(`api/drivers/${driverId}`);
        return (response.ok ? await response.json() : undefined) as IDriverResponse;
    }

    public async createDriver(driver: Driver): Promise<boolean> {
        const response = await adalApiFetch(`api/drivers`, { method: 'post', body: driver.serialized });
        return response.ok;
    }

    public async updateDriver(driver: Driver): Promise<boolean> {
        const driverObjectToBeSent = {
            ...driver,
            unavailability: driver.unavailability.map(u => {
                return {
                    ...u,
                    startDate: u.startDate!.toFormat('yyyy-MM-dd'),
                    endDate: u.endDate!.toFormat('yyyy-MM-dd')
                }
            })
        }
        const response = await adalApiFetch(`api/drivers`, { method: 'put', body: JSON.stringify(driverObjectToBeSent) });
        return response.ok;
    }

    public async deleteDriver(driverId: number) {
        const response = await adalApiFetch(`api/drivers/${driverId}`, { method: 'delete' });
        return response.ok;
    }

    public async getSpareDrivers(vehicleAvailability: ScheduleAvailability, runId: string): Promise<IScheduleAvailabilityResponse[]> {
        const response = await adalApiFetch(`api/drivers/spare`, {
            method: 'post', body: JSON.stringify({
                vehicleId: vehicleAvailability.vehicleId,
                runId
            })
        });
        return (response.ok ? await response.json() : []) as IScheduleAvailabilityResponse[];
    }

    public async getSpareDriversForRoute(routeGuid: string, runId: string): Promise<IScheduleAvailabilityResponse[]> {
        const response = await adalApiFetch(`api/drivers/sparepaused`, {
            method: 'post', body: JSON.stringify({
                runId,
                routeGuid
            })
        });
        return (response.ok ? await response.json() : []) as IScheduleAvailabilityResponse[];
    }

    public async getSpareDriversForLive(scheduleId: number, vehicleId: number, startUtc: DateTime): Promise<IScheduleAvailabilityResponse[]> {
        const response = await adalApiFetch(`api/drivers/sparelive`, {
            method: 'post', body: JSON.stringify({
                scheduleId,
                vehicleId,
                startUtc
            })
        });
        return (response.ok ? await response.json() : []) as IScheduleAvailabilityResponse[];
    }
}

interface IDriverSummaryResponse {
    id: number;
    name: string;
    suburb: string;
    validAddress: boolean;
    defaultVehicle?: IDefaultVehicleSummaryResponse;
}

interface IDefaultVehicleSummaryResponse {
    registration: string;
    nextUnavailability: IUnavailabilityResponse[];
}

export interface IDriverUnavailabilityResponse {
    id: number;
    reason: string;
    startDate: string;
    startDispatchTime: string;
    endDate: string;
    endDispatchTime: string;
    repeatDayFlags: DayOfWeekFlags;
}

export interface IDriverResponse {
    id: number;
    name: string;
    email: string;
    maximumWorkTime: string;
    phone: string;
    address: Address;
    defaultVehicleRegistration: string;
    licenseType: LicenseType;
    unavailability?: IDriverUnavailabilityResponse[];
}
import * as React from 'react';
import './styles.scss';
import { observer, inject } from 'mobx-react';
import { UiStore } from '../../stores/ui-store';
import { Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Search from '@material-ui/icons/Search';

export interface IHeaderWithSearchProps {
    title: string;
    uiStore?: UiStore;
    onClickSearch?(): void
}

@inject('uiStore')
@observer
export class HeaderWithSearch extends React.Component<IHeaderWithSearchProps> {
    constructor(props: IHeaderWithSearchProps) {
        super(props);
        this.handleNavToggle = this.handleNavToggle.bind(this);
    }

    public render() {
        const { onClickSearch, title } = this.props;
        return <React.Fragment>
            <Grid container className="header">
                <Grid item xs={1} className="center">
                    <MenuIcon 
                        onClick={this.handleNavToggle} 
                        className="menu action" />
                </Grid>
                <Grid item xs={8}>
                    <h2>{title}</h2>
                </Grid> 
                <Grid item xs={1}>
                    {
                        onClickSearch
                        && <Search 
                            onClick={onClickSearch} 
                            className="action" />
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    }

    public handleNavToggle() {
        this.props.uiStore!.setNavActive(true);
    }
}
import { Model } from './model';
import { Address } from './address';
import { observable, computed, action, runInAction } from 'mobx';
import { IDriverResponse } from '../../services/drivers-api';
import { required, phone, email, hasValue, getTime } from '../../validate';
import { DriverUnavailability } from './driver-unavailability';

export class Driver extends Model {
    @observable public id: number = 0;
    @observable public name: string = '';
    @observable public phone: string = '';
    @observable public email: string = '';
    @observable public maximumWorkTime: string = '';
    @observable public address: Address = new Address({});
    @observable public licenseType: LicenseType = LicenseType.None;
    @observable public defaultVehicleRegistration: string = '';
    @observable public unavailability: DriverUnavailability[] = [];

    constructor(obj: Partial<Driver>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    @computed 
    public get isNew(): boolean {
        return this.id === 0;
    }

    public static fromResponse(r: IDriverResponse) {
        const d = new Driver({
            ...r,
            address: new Address({...r.address}),
            unavailability: r.unavailability ? r.unavailability.map((u, i) => DriverUnavailability.fromResponse(u, i)) : []
        });
        return d;
    }

    
    @action
    public addUnavailability(unavailability: DriverUnavailability) {
        if (!this.unavailability.some(x => x.lineId === unavailability.lineId)) {
            this.unavailability.push(unavailability);
        } else this.unavailability = this.unavailability
            .map(x => x.lineId === unavailability.lineId ? unavailability : x);
    }

    @action
    public deleteUnavailability(unavailability: DriverUnavailability) {
        this.unavailability = this.unavailability.filter(u => u.lineId !== unavailability.lineId);
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'name': (val: string) => {
            this.errors.name = required(val);
            return this.errors.name === null;
        },
        'phone': (val: string) => {
            this.errors.phone = phone(val);
            return this.errors.phone === null;
        },
        'email': (val: string) => {
            this.errors.email = email(val);
            return this.errors.email === null;
        },
        'licenseExpiryDate': (val: number) => {
            this.errors.licenseExpiryDate = required(val);
            return this.errors.licenseExpiryDate === null;
        },
        'maximumWorkTime': (val: string) => {
            if (!hasValue(val)) {
                this.errors.maximumWorkTime = 'Required';
                return false;
            }

            const workTime = getTime(val);
            if (isNaN(workTime)) {
                this.errors.maximumWorkTime = 'not a valid number of hours (eg. 14:30)';
                return false;    
            }

            this.errors.maximumWorkTime = null;
            return this.errors.maximumWorkTime === null;
        },
        'address': (val: Address) => val.validate()
    }
}

export enum LicenseType {
    None = 0,
    HR = 1, // Heavy Rigid
    HC = 2, // Heavy Combination
    MC = 3  // Multi Combination
}
import { observer, inject } from 'mobx-react';
import React from 'react';
import { RouteComponentProps } from "react-router";
import tracker from '../Loader/loader-tracker';
import { UiStore } from '../../stores/ui-store';
import { WithStyles, withStyles } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { JobStore } from '../../stores/job-store';
import { CloseButton, OkButton } from '../Dialog/Buttons/Buttons';
import Dialog from '../Dialog/Dialog';

interface IUnassignLiveJobDialogProps extends WithStyles<typeof globalStyles> {
    jobStore?: JobStore
    uiStore?: UiStore
}

type Props = IUnassignLiveJobDialogProps & RouteComponentProps<{}>;


@inject('jobStore', 'uiStore')
@observer
class UnassignLiveJobDialog extends React.Component<Props> {
    
    constructor(props: Props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public render() {
        const { unassignLiveJobDialogState } = this.props.uiStore!;

        return <Dialog
            title="Remove from live schedule"
            type="slide"
            onClose={() => this.handleCancel()}
            active={unassignLiveJobDialogState.jobId !== 0}
            className="unassign-live-job-dialog">
        {{
            content: <React.Fragment>
                <p>
                Are you sure you want to remove this job for <span title={"Job " + unassignLiveJobDialogState.jobId}>
                    Order {unassignLiveJobDialogState.orderNumber}
                </span> from the live schedule?
                </p>
                <p className="unassign-note">
                    This will remove the job directly from the live schedule, ignoring constraints such as whether it is already loaded on a truck.
                    It should be used with caution and the job then assigned to a route to match the actual status.
                </p>
            </React.Fragment>,
            actions: <React.Fragment>
                <CloseButton onClick={this.handleCancel} />
                <OkButton onClick={this.handleSubmit} />
            </React.Fragment>
        }}
        </Dialog>
    }

    private handleCancel() {
        this.props.uiStore!.unassignLiveJobDialogState.reset();
    }

    private async handleSubmit() {
        const { unassignLiveJobDialogState } = this.props.uiStore!;

        const ok = await tracker.track(this.props.jobStore!.unassignLiveJob(unassignLiveJobDialogState.jobId));
        if (ok === true) {
            this.props.uiStore!.unassignLiveJobDialogState.reset();
        }
    }
}

export default withStyles(globalStyles)(UnassignLiveJobDialog);
import * as React from 'react';
import { Link } from 'react-router-dom';
import { authContext } from '../../adalConfig';

const Unauthorised: React.SFC = () => {
    return <div>
        Unauthorised. Only dispatch users mate... Consider yourself Dispatched! ;) <span>
            <Link to="" onClick={() => authContext.logOut()}>
                Logout
            </Link>
        </span>
    </div>
};

export default Unauthorised;
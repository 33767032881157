import { Model } from './model';
import { IScheduleAvailabilityResponse } from '../../services/schedule-api';
import { observable, runInAction } from 'mobx';
import { DateTime } from 'luxon';
import { TruckType } from './vehicle';
import { hasValue, getTime } from '../../validate';

export class ScheduleAvailability extends Model {
    @observable public id: number = 0;
    @observable public driverId: number = 0;
    @observable public driverName: string = '';
    @observable public vehicleId: number = 0;
    @observable public registration: string = '';
    @observable public dispatchDate: DateTime = DateTime.local().startOf('day');
    @observable public scheduleId: number = 0;
    @observable public startDispatchTime: string = '';
    @observable public endDispatchTime: string = '';
    @observable public truckType: TruckType = TruckType.None;
    @observable public vehicleStartTimeInMinutes: number = 0;
    @observable public vehicleEndTimeInMinutes: number = 0;
    @observable public driverStartTimeInMinutes: number = 0;
    @observable public driverEndTimeInMinutes: number = 0;
    @observable public routeGuid: string = '';

    constructor(obj: Partial<ScheduleAvailability>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    public static fromResponse(r: IScheduleAvailabilityResponse) {
        const v = new ScheduleAvailability({ 
            ...r,
        });
        return v;
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'driverId': (val: number) => {
            this.errors.driverId = val > 0 ? null : 'Required';
            return this.errors.driverId === null;
        },
        'vehicleId': (val: number) => {
            this.errors.vehicleId = val > 0 ? null : 'Required';
            return this.errors.vehicleId === null;
        },
        'endDispatchTime': (val: string) => {
            if (!hasValue(val)) {
                this.errors.endDispatchTime = 'Required';
                return false;
            }

            const endTime = getTime(val);
            const startTime = getTime(this.startDispatchTime);

            if (isNaN(endTime)) {
                this.errors.endDispatchTime = 'not a valid 24hr time (eg. 9:00)';
                return false;    
            }
            
            if (isNaN(startTime)) {
                this.errors.startDispatchTime = 'invalid start time';
                this.errors.endDispatchTime = null;
            }

            if ((endTime - startTime) < 60) {
                this.errors.endDispatchTime = 'minimum window is 1 hr';
                return false;
            }

            if (endTime > this.vehicleEndTimeInMinutes) {
                this.errors.endDispatchTime = 'vehicle not available at this time';
                return false;
            }

            if (endTime > this.driverEndTimeInMinutes) {
                this.errors.endDispatchTime = 'driver not available at this time';
                return false;
            }

            this.errors.endDispatchTime = null;
            return this.errors.endDispatchTime === null;
        },
        'startDispatchTime': (val: string) => {
            if (!hasValue(val)) {
                this.errors.startDispatchTime = 'Required';
                return false;
            }

            const endTime = getTime(this.endDispatchTime);
            const startTime = getTime(val);

            if (isNaN(startTime)) {
                this.errors.startDispatchTime = 'not a valid 24hr time (eg. 7:00)';
                return false;
            }

            if (isNaN(endTime)) {
                this.errors.endDispatchTime = 'invalid end time';
            }

            if ((endTime - startTime) < 60) {
                this.errors.startDispatchTime = 'minimum window is 1 hr'
                return false;
            }

            if (startTime < this.vehicleStartTimeInMinutes) {
                this.errors.startDispatchTime = 'vehicle not available at this time';
                return false;
            }

            if (startTime < this.driverStartTimeInMinutes) {
                this.errors.startDispatchTime = 'driver not available at this time';
                return false;
            }

            this.errors.startDispatchTime = null;
            return this.errors.startDispatchTime === null;
        },
    }
}
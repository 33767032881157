import { observable, action, computed } from 'mobx'
import { IJobSummary } from '../domain/job-summary';
import { ScheduleToRoute } from '../domain/schedule-to-route';
import { ScheduleAvailability } from '../domain/schedule-availability';

export class ScheduleToRouteDialogState {
    @observable public job?: IJobSummary;
    @observable public confirmDialogOpen: boolean = false;
    @observable public dialogOpen: boolean = false;
    @observable public scheduleToRoute: ScheduleToRoute = new ScheduleToRoute({});
    @observable public routeIndex: number = -1;
    @observable public availableRoutes: ScheduleAvailability[] = [];
    
    @action
    public init(job: IJobSummary) {
        this.job = job;
        this.scheduleToRoute = new ScheduleToRoute({ jobId: job.id });
        this.dialogOpen = true;
        this.setAvailableRoutes([]);
        this.routeIndex = -1;
    }

    @action
    public reset() {
        this.job = undefined;
        this.scheduleToRoute = new ScheduleToRoute({});
        this.dialogOpen = false;
        this.setAvailableRoutes([]);
        this.confirmDialogOpen = false;
        this.routeIndex = -1;
    }

    @action
    public setAvailableRoutes(availableRoutes: ScheduleAvailability[]) {
        this.availableRoutes = availableRoutes;
    }

    @action
    public toggleConfirmDialog(){
        this.confirmDialogOpen = !this.confirmDialogOpen;
    }

    @action 
    public updateRoute(route: ScheduleAvailability, index: number) {
        this.scheduleToRoute.update('routeGuid', route.routeGuid);

        this.routeIndex = index;
    }

    @computed
    public get selectedVehicle() {
        return this.routeIndex < 0 ? '' : this.availableRoutes[this.routeIndex].registration;
    }
}
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { UiStore } from '../../stores/ui-store';
import { PublishStatus } from '../../stores/domain/schedule';
import { Grid } from '@material-ui/core';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { ContextItem } from '../ContextMenu/ContextItem';
import { VehicleRoute } from '../../stores/domain/vechicle-route';
import { ScheduleStore } from '../../stores/schedule-store';
import { UnassignedJobs } from '../../stores/domain/unassigned-jobs';
import { ScheduleListAction } from '../../stores/ui-state/schedule-list-state';
import Block from '@material-ui/icons/Block';

interface IVehicleRouteContextMenu {
    scheduleStore?: ScheduleStore
    uiStore?: UiStore
    vehicleRoute: VehicleRoute
}

@inject('jobStore', 'scheduleStore', 'uiStore')
@observer
class VehicleRouteContextMenu extends React.Component<IVehicleRouteContextMenu> {
    constructor(props: IVehicleRouteContextMenu) {
        super(props);

        this.assignDriver = this.assignDriver.bind(this);
        this.cancelUnassignJobs = this.cancelUnassignJobs.bind(this);
        this.cancelVehicleRouteChanges = this.cancelVehicleRouteChanges.bind(this);
        this.lockRoute = this.lockRoute.bind(this);
        this.reorganizeVehicleRoute = this.reorganizeVehicleRoute.bind(this);
        this.saveUnassignedJobs = this.saveUnassignedJobs.bind(this);
        this.saveUnassignedJobsWithoutScheduler = this.saveUnassignedJobsWithoutScheduler.bind(this);
        this.saveVehicleRouteChanges = this.saveVehicleRouteChanges.bind(this);
        this.transferDriver = this.transferDriver.bind(this);
        this.unassignJobs = this.unassignJobs.bind(this);
        this.unlockRoute = this.unlockRoute.bind(this);
        this.downloadLoadingSheets = this.downloadLoadingSheets.bind(this)
    }

    public async componentWillUnmount() {
        this.props.uiStore!.scheduleListState.resetCurrentAction();
    }

    public render() {
        const { uiStore, scheduleStore, vehicleRoute } = this.props;
        const { scheduleUiState } = uiStore!;
        const activeAction = uiStore!.scheduleListState.activeAction;
        const schedule = scheduleStore!.currentSchedule!;

        switch (scheduleUiState.publishStatus)
        {
            case PublishStatus.Live:
                if (scheduleUiState.isTodaysSchedule) {
                    if (vehicleRoute.driver) {
                        return <Grid item xs={1} className="icon-container">
                            <ContextMenu title="route options">
                                <ContextItem label="Unscheduled Delay"
                                    onClick={() => uiStore!.scheduleListState.addUnscheduledDelay(vehicleRoute)} />
                                <ContextItem label="Download Loading Sheets"
                                    onClick={this.downloadLoadingSheets} />
                            </ContextMenu>
                        </Grid>
                    }
                    return <Grid item xs={1}
                            className="icon-container"
                            title="Route is paused" >
                            <Block className="action disabled"/>
                    </Grid>
                }
                const text = schedule.date! > scheduleUiState.today ? 'future' : 'past';
                return <Grid item xs={1}
                    className="icon-container"
                    title={`Schedule is in the ${text}`} >
                    <ContextMenu title="route options">
                        <ContextItem label="Download Loading Sheets"
                            onClick={this.downloadLoadingSheets} />
                    </ContextMenu>
                </Grid>
            case PublishStatus.Draft:
                if (schedule.date! < scheduleUiState.today) {
                    return <Grid item xs={1}
                                 className="icon-container"
                                 title="Schedule is in the past" >
                        <Block className="action disabled"/>
                    </Grid>
                }
                return  <Grid item xs={1} className="icon-container">
                            <ContextMenu title="route options">
                                {activeAction === ScheduleListAction.None &&
                                !vehicleRoute.isLocked &&
                                <ContextItem label="Lock Route"
                                    onClick={this.lockRoute} />}
                                {activeAction === ScheduleListAction.None &&
                                vehicleRoute.isLocked &&
                                vehicleRoute.driver &&
                                <ContextItem label="Unlock Route"
                                    onClick={this.unlockRoute} />}
                                {activeAction === ScheduleListAction.None &&
                                <ContextItem label="Re-organize Tasks"
                                    onClick={this.reorganizeVehicleRoute} />}
                                {(activeAction === ScheduleListAction.None && vehicleRoute.driver) &&
                                <ContextItem label="Transfer Driver"
                                    onClick={this.transferDriver} />}
                                {(activeAction === ScheduleListAction.None && !vehicleRoute.driver) &&
                                <ContextItem label="Assign Driver"
                                    onClick={this.assignDriver} />}
                                {uiStore!.scheduleListState.reorganizingVehicleRouteGuid === vehicleRoute.guid &&
                                <ContextItem label="Save changes"
                                    onClick={this.saveVehicleRouteChanges} />}
                                {uiStore!.scheduleListState.reorganizingVehicleRouteGuid === vehicleRoute.guid &&
                                <ContextItem label="Discard changes"
                                    onClick={this.cancelVehicleRouteChanges} />}
                                {activeAction === ScheduleListAction.None &&
                                <ContextItem label="Unassign Jobs"
                                    onClick={this.unassignJobs} />}
                                {uiStore!.scheduleListState.unassignedJobs.routeGuid === vehicleRoute.guid &&
                                <ContextItem label="Save changes"
                                    onClick={this.saveUnassignedJobs} />}
                                {uiStore!.scheduleListState.unassignedJobs.routeGuid === vehicleRoute.guid &&
                                <ContextItem label="Save without running scheduler"
                                             onClick={this.saveUnassignedJobsWithoutScheduler} />}
                                {uiStore!.scheduleListState.unassignedJobs.routeGuid === vehicleRoute.guid &&
                                <ContextItem label="Cancel"
                                    onClick={this.cancelUnassignJobs} />}
                        </ContextMenu>
                </Grid>;
            default:
                return <Grid item xs={1}
                        className="icon-container"
                        title="Invalid Schedule" >
                        <Block className="action disabled"/>
                    </Grid>;
        }
    }

    private async assignDriver() {
        const { uiStore, scheduleStore, vehicleRoute } = this.props;
        const runId = scheduleStore!.currentDraftSchedule.runId!;

        await uiStore!.assignDriverDialogState.init(runId, vehicleRoute);
    }

    private async cancelUnassignJobs() {
        this.props.uiStore!.scheduleListState.resetCurrentAction();
    }

    private async downloadLoadingSheets() {
        const { scheduleStore, vehicleRoute } = this.props;
        scheduleStore!.downloadLoadingSheetZip(vehicleRoute.id)
            .then((blob: File) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = blob.name;
                a.click();
            });
    }

    private async cancelVehicleRouteChanges() {
        const { scheduleStore, uiStore } = this.props;
        const scheduleDate = scheduleStore!.currentDraftSchedule.date!;
        await scheduleStore!.loadSchedules(scheduleDate);
        uiStore!.scheduleListState.resetCurrentAction();
    }

    private async lockRoute() {
        await this.props.scheduleStore!.lockRoute(this.props.vehicleRoute.guid);
    }

    private reorganizeVehicleRoute() {
        const { uiStore, vehicleRoute } = this.props;
        uiStore!.scheduleListState.reorganizeVehicleRoute(vehicleRoute.guid);
    }

    private async transferDriver() {
        const { uiStore, scheduleStore, vehicleRoute } = this.props;
        const runId = scheduleStore!.currentDraftSchedule.runId!;

        uiStore!.transferDriverDialogState.init(runId, vehicleRoute);
    }

    private unassignJobs() {
        const schedule = this.props.scheduleStore!.currentDraftSchedule;
        const vehicleRoute = this.props.vehicleRoute;

        this.props.uiStore!.scheduleListState.unassignJobs(new UnassignedJobs({
            runId: schedule.runId,
            routeGuid: vehicleRoute.guid
        }));
    }

    private async saveVehicleRouteChanges() {
        const ok = await this.props.scheduleStore!.saveVehicleRoute(this.props.vehicleRoute.guid);
        if (ok) {
            this.props.uiStore!.scheduleListState.resetCurrentAction();
        }
    }

    private async saveUnassignedJobs() {
        const { uiStore } = this.props;
        const unassignedJobs = uiStore!.scheduleListState.unassignedJobs;

        const ok = await this.props.scheduleStore!.saveUnassignedJobs(unassignedJobs, false);
        if (ok) {
            uiStore!.scheduleListState.resetCurrentAction();
        }
    }
    
    private async saveUnassignedJobsWithoutScheduler() {
        const { uiStore } = this.props;
        const unassignedJobs = uiStore!.scheduleListState.unassignedJobs;

        const ok = await this.props.scheduleStore!.saveUnassignedJobs(unassignedJobs, true);
        if (ok) {
            uiStore!.scheduleListState.resetCurrentAction();
        }
    }

    private async unlockRoute() {
        await this.props.scheduleStore!.unlockRoute(this.props.vehicleRoute.guid);
    }
}

export default VehicleRouteContextMenu;
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { UiStore } from '../../stores/ui-store';
import Grid from '@material-ui/core/Grid';

interface IScheduleTimeoutOptionsProps {
    uiStore?: UiStore;
}

type Props = IScheduleTimeoutOptionsProps;

@inject('uiStore')
@observer
export class ScheduleTimeoutOptions extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render() {
        return <Grid container className="action-item">
            <Grid item xs={7} />
            <Grid item xs={4} className="text-right">
            <FormControl>
                <Select
                    onChange={this.handleChange}
                    value={this.props.uiStore!.scheduleUiState.runTimeoutInSeconds}
                >
                    <MenuItem value={90}>1.5 (Default)</MenuItem>
                    <MenuItem value={300}>5</MenuItem>
                    <MenuItem value={900}>15</MenuItem>
                </Select>
                    <FormHelperText>Run Timeout (Minutes)</FormHelperText>
                </FormControl>
            </Grid>   
        </Grid>   
    }

    private handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.props.uiStore!.scheduleUiState.setRunTimeoutInSeconds(event.target.value as number);
    };
}
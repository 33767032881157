import * as React from 'react';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { ScheduleStore } from '../../stores/schedule-store';
import { globalStyles } from '../../theme';

interface IUnassignedDriversProps extends WithStyles<typeof globalStyles> {
    scheduleStore?: ScheduleStore
}

@inject('scheduleStore')
@observer
class UnassignedDrivers extends React.Component<IUnassignedDriversProps> {

    public render() {
        return <div className="unassigned-driver-list">
            <div className="section">
                {
                    this.props.scheduleStore!.currentDraftSchedule.unassignedDrivers
                        .map((d, i) => (
                        <Grid container key={i} className="item">
                            <Grid item xs={1} />
                            <Grid item xs={3}>
                                <div>{d.name}</div>
                                <div className="headers">Name</div>
                            </Grid>
                            <Grid item xs={2}>
                                <div>{d.defaultVehicleRegistration}</div>
                                <div className="headers">Default Vehicle</div>
                            </Grid>
                        </Grid>
                    ))
                }
            </div>
        </div>
    }
}

export default withStyles(globalStyles)(UnassignedDrivers);
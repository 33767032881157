import * as React from 'react';
import './styles.scss';
import Grid from '@material-ui/core/Grid';

interface IActionItemProps {
    enabled: boolean;
    label: string;
    onClick: React.MouseEventHandler<Element>;
    tooltip?: string;
}
export const ActionItem: React.SFC<IActionItemProps> = (props) => {
    return <Grid container className="action-item">
        <Grid item xs={7} />
        <Grid item xs={4} className="text-right">
            <span className={`action ${props.enabled ? '' : 'disabled'}`}
                onClick={props.enabled ? props.onClick : () => {}}
                title={props.tooltip}>
                <span className="action-label">
                    {props.label}
                </span>
                <span className="icons-col">
                    {props.children}
                </span>
            </span>
        </Grid>
    </Grid>
};
import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom'

interface IAddActionProps {
    routeName: string;
}

export const AddAction: React.SFC<IAddActionProps> = (props: IAddActionProps) => {
    return <Link to={props.routeName}>
        <div className="action-container">
            <AddIcon className="icon" />
        </div>
    </Link>
};
import {Collapse} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {Warning} from '@material-ui/icons';
import Alarm from '@material-ui/icons/Alarm';
import Lock from '@material-ui/icons/Lock';
import {DateTime} from 'luxon';
import {inject, observer} from 'mobx-react';
import * as React from 'react';
import {JobStatus, JobType} from '../../stores/domain/job';
import {PublishStatus} from '../../stores/domain/schedule';
import {Task, TaskStatus, TaskType} from '../../stores/domain/task';
import {VehicleRoute} from '../../stores/domain/vechicle-route';
import {ScheduleStore} from '../../stores/schedule-store';
import {UiStore} from '../../stores/ui-store';
import {IconTooltip} from '../IconTooltip/IconTooltip';
import TaskItem from './TaskItem';
import VehicleRouteContextMenu from './VehicleRouteContextMenu';
import Tooltip from "@material-ui/core/Tooltip";

const lowLoadProportionThreshold = 0.25;

interface ITaskListProps {
    key: number
    onViewJob: (jobId: number) => void
    uiStore?: UiStore
    scheduleStore?: ScheduleStore
    vehicleRoute: VehicleRoute
}

interface IState {
    checked: boolean;
}

interface IRemovedJob {
    orderNumber: string
    customerName: string
    jobIds: number[]
}

@inject('scheduleStore', 'uiStore')
@observer
class TaskList extends React.Component<ITaskListProps, IState> {
    constructor(props: ITaskListProps) {
        super(props);

        this.moveTask = this.moveTask.bind(this);
        this.state = { checked: true }
    }

    public render() {
        const { key, uiStore, vehicleRoute: vr } = this.props;
        const { scheduleListState } = uiStore!;
        const enableCheck = scheduleListState.unassignedJobs.routeGuid === vr.guid;
        const enableDragAndDrop = scheduleListState.reorganizingVehicleRouteGuid === vr.guid;
        const vehicleDepot = vr.tasks.filter(t => t.type === TaskType.End).map(t => t.addressText)[0];
        const cannotBeUnassignedJobs: number[] = [];
        const canAlwaysBeUnassignedTaskStatuses = [ TaskStatus.DateChanged, TaskStatus.JobUpdated,
            TaskStatus.Cancelled, TaskStatus.Deleted ];
        const utcNow = DateTime.utc();
        const isLiveView = uiStore!.scheduleUiState.publishStatus === PublishStatus.Live

        let nextRemainingTask: Task|undefined;
        const liveRoute = this.getLiveRoute(vr.vehicle!.id)
        if (liveRoute) {
            // Next remaining task (from the live schedule)
            const remainingLiveTasks = liveRoute!.tasks.filter(t => t.etaUtc! > utcNow)
            if (remainingLiveTasks.length > 0) {
                nextRemainingTask = remainingLiveTasks[0]
            }
        }
        
        if (enableCheck) {
            // 1. Add the next remaining task (from the live schedule)
            if (liveRoute) {
                if (nextRemainingTask && nextRemainingTask.job && nextRemainingTask.isJobLive) {
                    cannotBeUnassignedJobs.push(nextRemainingTask.job.id);
                }

                // 2. Add jobs where their live tasks are in the past or on-route (t = live task)
                liveRoute.tasks.forEach(t => {
                    if (t.job && t.isJobLive) {
                        if (t.job!.status > JobStatus.Scheduled || t!.etaUtc! <= utcNow) {
                            if (!cannotBeUnassignedJobs.includes(t.job!.id)) {
                                cannotBeUnassignedJobs.push(t.job!.id);
                            }
                        }
                    }
                });
            }

            // 3. Add jobs that are of a preload type
            const cannotBeUnassignedTypes = [ JobType.PreLoadPickup, JobType.PreLoadDelivery, JobType.Redelivery, 
                JobType.ReturnToAbcAndUnload, JobType.ReturnToAbcForRedelivery, JobType.ReturnToSupplier ];
            vr.tasks.forEach(t => {
                if (t.job && t.isJobLive) {
                    if (cannotBeUnassignedTypes.includes(t.job!.type)) {
                        if (!cannotBeUnassignedJobs.includes(t.job!.id)) {
                            cannotBeUnassignedJobs.push(t.job!.id);
                        }
                    }
                }
            });
        }

        const removedJobs: IRemovedJob[] = [];
        if (!isLiveView && liveRoute) {
            const draftJobIds = vr.tasks.filter(t => t.job).map(t => t.job!.id)
            liveRoute.tasks.forEach(liveTask => {
                if (liveTask.job && liveTask.job.status < JobStatus.Complete && !draftJobIds.includes(liveTask.job.id)) {
                    const removedJob = removedJobs.find(rj => rj.orderNumber === liveTask.job!.orderNumber)
                    if (removedJob) {
                        if (!removedJob.jobIds.includes(liveTask.job.id)) {
                            removedJob.jobIds.push(liveTask.job.id)
                        }
                    } else {
                        removedJobs.push({ 
                            orderNumber: liveTask.job.orderNumber, 
                            customerName: liveTask.job.customerName, 
                            jobIds: [liveTask.job.id]
                        })
                    }
                }
            })
        }
        
        return (
            <div key={key}>
                <Grid container className={`name-container${vr.driver ? '' : ' paused'} pointer`}>
                    <VehicleRouteContextMenu vehicleRoute={vr} />
                    <Grid item xs={11} onClick={() => this.setState({ checked: !this.state.checked })}>
                        <span className={'route-header'}>
                            {vr.vehicle!.registration}
                            {vr.driver ? ' : ' + vr.driver!.name : ' : No driver!'}
                        </span>
                        {' - ' + vr.totalHours.toFixed(1) + ' hr(s)'}
                        {' '}
                        <Tooltip title={'Load score of 100 is a fully loaded headhaul trip, empty on return. ' +
                                'Low scores (< 100) indicate an under-utilised vehicle. High scores (> 100) ' +
                                'could indicate be efficient routing, backhaul loads, or simply inefficient ' +
                                'early pickup of a load that is then carried around all day.'}>
                            <span>{'@' + vr.loadScore + ' load'}</span>
                        </Tooltip>
                        {' '}
                        <span className={'route-header'}>
                            {vr.isLocked &&
                                <IconTooltip title={`Route is locked`}>
                                    {{ icon: <Lock /> }}
                                </IconTooltip>
                            }
                            {!vr.isEightHours &&
                                <IconTooltip title={`Vehicle is scheduled for less than 8 hours`}>
                                    {{ icon: <Alarm /> }}
                                </IconTooltip>
                            }
                            {this.getWarnings(vr)}
                        </span>
                    </Grid>
                </Grid>
                <Collapse in={this.state.checked}>
                    {vr.tasks!.map((task, j) => <TaskItem
                        enableCheck={enableCheck}
                        enableDragAndDrop={enableDragAndDrop}
                        index={j}
                        isChecked={uiStore!.scheduleListState.unassignedJobs.isChecked(task)}
                        isLiveView={isLiveView}
                        isNextRemaining={!!nextRemainingTask && nextRemainingTask.id == task.id}
                        key={j}
                        liveRoute={task.job && this.getLiveRouteForJob(task.job.id)}
                        moveTask={this.moveTask}
                        onCheck={() => uiStore!.scheduleListState.unassignedJobs.check(task)}
                        onClick={() => task.job ? this.props.onViewJob(task.job.id) : {}}
                        task={task}
                        canUnassign={task.job
                            ? (canAlwaysBeUnassignedTaskStatuses.includes(task.status) || !cannotBeUnassignedJobs.includes(task.job!.id))
                            : false}
                        utcNow={utcNow}
                        vehicleDepot={vehicleDepot}
                        vehicleId={vr.vehicle!.id} />
                    )}
                    {removedJobs.length > 0 &&
                    <div key={'end'} className={'taskWrapper changed-removed'}>
                        <Grid container
                              className={'removed-message'}>
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={11}>
                                <strong>Removed jobs: </strong>
                                {removedJobs.map((removedJob, index) =>
                                    <React.Fragment key={index}>
                                        {index > 0 && ', '}
                                        <span title={`Job Ids: ${removedJob.jobIds.join(', ')}`}>{removedJob.orderNumber} {removedJob.customerName}</span>
                                    </React.Fragment>
                                )}
                            </Grid>
                        </Grid>   
                    </div>
                    }
                </Collapse>

            </div>);
    };

    public getWarnings = (vr: VehicleRoute) => {
        let warningText = null;

        const maxRouteUtilisation = vr.tasks.reduce((max, task) => task.usedCapacityProportion > max ? task.usedCapacityProportion : max, 0);
        if ((maxRouteUtilisation!) < lowLoadProportionThreshold) {
            warningText = `Low utilised route. Using ${(maxRouteUtilisation! * 100).toFixed(0)}% of the vehicle's capacity.`;
        }

        return <React.Fragment>
            {
                warningText !== null && <IconTooltip title={warningText}>
                    {{ icon: <Warning className="warning-icon" /> }}
                </IconTooltip>
            }
        </React.Fragment>;
    };

    public async moveTask(dragIndex: number, hoverIndex: number) {
        this.props.vehicleRoute.moveTask(dragIndex, hoverIndex);
        const runId = this.props.scheduleStore!.currentDraftSchedule.runId;
        const vehicleRoute = this.props.vehicleRoute;
        await this.props.scheduleStore!.recalculateVehicleRoute(runId, vehicleRoute.guid, vehicleRoute.tasks.map(t => t.sequence));
    }

    private getLiveRoute(vehicleId: number) {
        return this.props.scheduleStore!.currentSchedule.vehicleRoutesWithTasks.find(lr => lr.vehicle!.id === vehicleId);
    }
    
    private getLiveRouteForJob(jobId: number) {
        return this.props.scheduleStore!.currentSchedule.vehicleRoutes
            .find(vr => vr.tasks!.filter(t => t.job && t.job!.id == jobId).length > 0);
    }
}

export default TaskList;
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { TextField, WithStyles, withStyles, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { asForm, IAsFormProps } from '../Form/AsForm';
import { IRootStore } from '../../initStores';
import Section from '../Section/Section';
import AddressSearchBox from '../AddressSearch/AddressSearchBox';
import { Address } from '../../stores/domain/address';
import { DriverStore } from '../../stores/driver-store';
import { withChangesPrompt } from '../Prompt/Prompt';
import DriverUnavailablitySection from '../DriverUnavailability/DriverUnavailabilitySection';
import { DriverUnavailability } from '../../stores/domain/driver-unavailability';
import { LicenseType } from '../../stores/domain/driver';

interface IDriverFormProps extends WithStyles<typeof globalStyles> {
    driverStore?: DriverStore
    readOnly?: boolean
}

type Props = IDriverFormProps & IAsFormProps;

@inject('driverStore')
@observer
class DriverForm extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.handleSaveUnavailablity = this.handleSaveUnavailablity.bind(this);
        this.handleDeleteUnavailablity = this.handleDeleteUnavailablity.bind(this);
    }

    private readonly licenseTypes: Array<{ name: string, value: LicenseType }> = [
        { name: 'Heavy Rigid', value: LicenseType.HR },
        { name: 'Heavy Combination', value: LicenseType.HC },
        { name: 'Multi Combination', value: LicenseType.MC }
    ];

    public render() {
        const { driver } = this.props.driverStore!;
        const { handleSetProperty, handleChange, handleSelectChanged, readOnly } = this.props;
        return <div className="form">
            <Section title="Details">
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textField}
                            InputProps={{ disableUnderline: true }}
                            label="Name"
                            type="text"
                            name="name"
                            error={!!driver.errors.name}
                            helperText={driver.errors.name || null}
                            value={driver.name}
                            onChange={handleChange}
                            disabled={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <AddressSearchBox
                            className={this.props.classes.textFieldFullWidth}
                            address={driver.address.formattedAddress}
                            handleAddressSelected={(address: Address) => handleSetProperty!('address', address)}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetProperty!('address', new Address(
                                { ...driver.address, formattedAddress: e.currentTarget.value })
                            )}
                            label="Location"
                            fieldName="address"
                            error={driver.address.errors.formattedAddress}
                            disabled={readOnly}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textField}
                            InputProps={{ disableUnderline: true }}
                            label="Phone"
                            type="text"
                            name="phone"
                            error={!!driver.errors.phone}
                            helperText={driver.errors.phone || null}
                            value={driver.phone}
                            onChange={handleChange}
                            disabled={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            className={this.props.classes.textFieldFullWidth}
                            InputProps={{ disableUnderline: true }}
                            label="Email"
                            type="text"
                            name="email"
                            error={!!driver.errors.email}
                            helperText={driver.errors.email || null}
                            value={driver.email}
                            onChange={handleChange}
                            disabled={readOnly}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <FormControl
                            disabled={readOnly}
                            className={this.props.classes.textField}>
                            <InputLabel htmlFor="licenseType">License Type</InputLabel>
                            <Select
                                disableUnderline
                                value={driver.licenseType}
                                onChange={handleSelectChanged}
                                inputProps={{ name: 'licenseType', id: 'licenseType' }}>
                                {
                                    this.licenseTypes.map(t => (<MenuItem value={t.value} key={t.value}>{t.name}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        {
                            !driver.isNew
                            && <TextField
                                className={this.props.classes.textField}
                                InputProps={{ disableUnderline: true }}
                                label="Default Vehicle"
                                type="text"
                                name="defaultVehicleRegistration"
                                value={driver.defaultVehicleRegistration || 'Unassigned'}
                                disabled
                            />
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <TextField
                            className={this.props.classes.textField}
                            error={!!driver.errors.maximumWorkTime}
                            helperText={driver.errors.maximumWorkTime || null}
                            InputProps={{ disableUnderline: true }}
                            inputProps={{ maxLength: 5 }}
                            label="Maximum work time"
                            type="text"
                            name="maximumWorkTime"
                            autoComplete="no"
                            value={driver.maximumWorkTime}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => driver.updateAndValidate('maximumWorkTime', e.currentTarget.value)}
                            disabled={readOnly}
                        />
                    </Grid>
                </Grid>
            </Section>
            <DriverUnavailablitySection
                unavailabilities={driver.unavailability}
                readOnly={readOnly}
                onSave={this.handleSaveUnavailablity}
                onDelete={this.handleDeleteUnavailablity}
            />
        </div>
    }

    public componentWillUnmount() {
        this.props.driverStore!.unloadDriver();
    }

    private handleSaveUnavailablity(unavailability: DriverUnavailability) {
        this.props.driverStore!.driver.addUnavailability(unavailability);
    }

    private handleDeleteUnavailablity(unavailability: DriverUnavailability) {
        this.props.driverStore!.driver.deleteUnavailability(unavailability);
    }
}

export default inject((s: IRootStore) => ({ model: s.driverStore.driver }))
    (asForm(withStyles(globalStyles)(withChangesPrompt(DriverForm))));
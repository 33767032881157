import { observable, action, computed, runInAction } from 'mobx'
import { DriverTransfer } from '../domain/driver-transfer';
import { ScheduleAvailability } from '../domain/schedule-availability';
import { VehicleStore } from '../vehicle-store';
import { VehicleRoute } from '../domain/vechicle-route';

export class TransferDriverDialogState {
    public vehicleStore: VehicleStore;

    @observable public driverTransfer: DriverTransfer = new DriverTransfer({});
    @observable public transferVehicles: ScheduleAvailability[] = [];
    @observable public vehicleIndex: number = -1;
    @observable public route: VehicleRoute = new VehicleRoute({});

    constructor(vehicleStore: VehicleStore) {
        this.vehicleStore = vehicleStore;
    }

    @computed
    public get selectedVehicle() {
        if (!this.transferVehicles.length) return;
        return this.transferVehicles[this.vehicleIndex];
    }
    
    @action
    public init(runId: string, route: VehicleRoute) {
        this.route = route;
        this.driverTransfer = new DriverTransfer({ runId, routeGuid: route.guid });
    }

    @action
    public reset() {
        this.driverTransfer = new DriverTransfer({});
        this.route = new VehicleRoute({});
        this.transferVehicles = [];
    }

    @action
    public async updateVehicle(index: number) {
        this.vehicleIndex = index;
        const vehicle = this.selectedVehicle;

        this.driverTransfer.vehicleId = vehicle ? vehicle.vehicleId : 0;
    }

    @action
    public async updateTaskGuid(taskGuid: string) {
        this.driverTransfer.update('taskGuid', taskGuid);
        await this.getTransferVehicles();
    }

    @action
    public async updateTransferLoad(transferLoad: boolean) {
        this.driverTransfer.update('transferLoad', transferLoad);
        await this.getTransferVehicles();
    }

    @action
    private async getTransferVehicles() {
        const vehicles = await this.vehicleStore!.searchTransferVehicles(this.driverTransfer, '');
        runInAction(() => this.transferVehicles = vehicles);
    }
}
import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import tracker from './loader-tracker';
import { observer } from 'mobx-react';
import './styles.scss';

interface ILoaderProps {
    area?: string
    message?: string
}
@observer
export class Loader extends React.Component<ILoaderProps> {

    private area: string;

    constructor(props: ILoaderProps) {
        super(props);
        this.area = (props.area) ? props.area : 'main';
    }

    public render() {
        return !tracker.complete(this.area)
            ? <div className={`loading-panel ${this.area}`}>
                {
                    this.props.message
                    && <div className="message">{this.props.message}</div>
                }
                <CircularProgress className="loader" />
            </div>
            : null
    }

    public componentWillUnmount() {
        tracker.deleteTracker(this.area);
    }
}
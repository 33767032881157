import { IJobSummary } from './domain/job-summary';
import { observable, action, runInAction, computed } from 'mobx';
import { JobsApi } from '../services/jobs-api';
import { DateTime } from 'luxon';
import { JobsFilter } from './domain/jobs-filter';
import { SuggestionItem } from '../components/Autocomplete/Autocomplete';
import { Job, TimeslotType, JobStatus } from './domain/job';
import notification from '../components/Notification/notification';
import { TransShip } from './domain/trans-ship';
import { ILocationSummary } from './domain/location-summary';
import { PreLoad } from './domain/pre-load';
import { AbcReturn } from './domain/abc-return';

export class JobStore {
    constructor(private jobsApi: JobsApi) { }

    @observable public job: Job = new Job({});
    @observable public jobs: IJobSummary[] = [];
    @observable public jobsFilter: JobsFilter = new JobsFilter({});

    @action
    public async getJobs(): Promise<IJobSummary[]> {
        const jobs = await this.jobsApi.getJobs(this.jobsFilter);
        runInAction(() => {
            this.jobs = jobs.map(d => {
                return { ...d, deliveryDate: DateTime.fromISO(d.deliveryDate).toLocal() }
            });
        });
        return this.jobs;
    }

    @action
    public async loadJob(jobId: number): Promise<Job> {
        const job = await this.jobsApi.getJob(jobId);
        runInAction(() => {
            this.job = Job.fromResponse(job);
            this.job.track();
        });
        return this.job;
    }
    
    @action
    public async lockJob(jobId: number, lock: boolean): Promise<boolean> {
        const ok = await this.jobsApi.lockJob(jobId, lock);
        if (ok) {
            await this.getJobs();
        }
        return ok;
    }

    @action
    public async preLoad(preLoad: PreLoad): Promise<boolean> {
        const result = await this.jobsApi.preLoad(preLoad)
        if (result.success) {
            await this.getJobs()
        } else {
            if (result.errors.length > 0) {
                notification.showError(result.errors.join('\n'))
            }
        }
        return result.success
    }

    @action
    public async returnToAbc(abcReturn: AbcReturn): Promise<boolean> {
        let ok = true;
        if (abcReturn.unload) {
            ok = await this.jobsApi.returnToAbcAndUnload(abcReturn);
        } else {
            ok = await this.jobsApi.returnToAbcAndRedeliver(abcReturn);
        }
        
        if (ok) {
            await this.getJobs();
        }
        return ok;
    }

    @action
    public async returnToSupplier(taskId: number): Promise<boolean> {
        const ok = await this.jobsApi.returnToSupplier(taskId);

        if (ok) {
            await this.getJobs();
        }
        return ok;
    }

    @action
    public async transShip(transShip: TransShip): Promise<boolean> {
        const result = await this.jobsApi.transShip(transShip);
        if (result.success) {
            await this.getJobs()
        } else {
            if (result.errors.length > 0) {
                notification.showError(result.errors.join('\n'))
            }
        }
        return result.success
    }

    @action
    public async unassignLiveJob(jobId: number): Promise<boolean> {
        const ok = await this.jobsApi.unassignLiveJob(jobId);
        if (ok) {
            await this.getJobs();
        }
        return ok;
    }

    @action
    public async undoJob(jobId: number): Promise<boolean> {
        const ok = await this.jobsApi.undo(jobId);
        if (ok) {
            await this.getJobs();
        }
        return ok;
    }

    @action
    public updateJobsFilter(jobsFilter: JobsFilter) {
        this.jobsFilter = jobsFilter;
    }

    @action
    public unloadJob() {
        this.job = new Job({});
    }

    public async completeSubContractedJob() {
        if (!this.job.subcontract) return;
        const ok = await this.jobsApi.completeSubContractedJob(this.job.id);
        if (ok) {
            notification.showInfo('Job completed');
            this.job.untrack();
        }
        return ok;
    }

    @computed
    public get actionableJobs(): IJobSummary[] {
        const actionableJobs = this.jobs
            .filter(x => x.exceedsShippingLimit
                || x.isMissingDemands
                || !x.scheduled)
            .slice()
            .sort(x => x.deliveryDate.toSeconds());
        return actionableJobs.filter(x => x.deliveryTimeslot === TimeslotType.ASAP)
            .concat(actionableJobs.filter(x => x.deliveryTimeslot !== TimeslotType.ASAP));
    }

    @computed
    public get orderedJobs() {
        return this.jobs
            .slice()
            .sort(x => x.deliveryDate.toSeconds());
    }

    @computed
    public get jobsRequiringAttention(): IJobSummary[] {
        return this.jobs
            .filter(x => x.exceedsShippingLimit
                || x.isMissingDemands);
    }

    @computed
    public get unscheduledJobs(): IJobSummary[] {
        return this.jobs.filter(x => x.status < JobStatus.Scheduled && x.subcontract === false);
    }

    @computed
    public get unscheduledJobsCount() {
        return this.unscheduledJobs.length;
    }

    @computed
    public get scheduledJobs(): IJobSummary[] {
        return this.jobs.filter(x => x.status >= JobStatus.Scheduled);
    }

    @computed
    public get scheduledJobsCount() {
        return this.scheduledJobs.length;
    }

    @computed
    public get subcontractedJobs(): IJobSummary[] {
        return this.jobs.filter(x => x.subcontract === true);
    }

    public async updateJob() {
        if (!this.job.validate()) return;
        const ok = await this.jobsApi.updateJob(this.job);
        if (ok) {
            notification.showInfo('Job updated');
            this.job.untrack();
        }
        return ok;
    }

    public async searchAbcLocations(query: string) {
        const suggestions = await this.jobsApi.searchAbcLocations(query);
        return suggestions.map(s => {
            return { label: s.name, value: {...s }} as SuggestionItem<ILocationSummary>
        })
    }

    public async searchCustomerNames(query: string) {
        const suggestions = await this.jobsApi.searchCustomerNames(query);
        return suggestions.map(s => {
            return { label: s, value: s } as SuggestionItem<string>
        })
    }

    public async searchDeliveryAddresses(query: string) {
        const suggestions = await this.jobsApi.searchDeliveryAddresses(query);
        return suggestions.map(s => {
            return { label: s, value: s } as SuggestionItem<string>
        })
    }

    public async searchTransShipLocations(query: string) {
        const suggestions = await this.jobsApi.searchTransShipLocations(query);
        return suggestions.map(s => {
            return { label: s.name, value: { ...s } } as SuggestionItem<ILocationSummary>
        })
    }
}
import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import {Checkbox, Hidden, Tooltip} from '@material-ui/core';
import {OnTime, Task, TaskStatus, TaskType} from '../../stores/domain/task';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {DateTime} from 'luxon';
import {timeFormatted} from '../../datetime';
import Warning from '@material-ui/icons/Warning';
import Done from '@material-ui/icons/Done';
import Alarm from '@material-ui/icons/Alarm';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import StarIcon from '@material-ui/icons/Star';
import {IconTooltip} from '../IconTooltip/IconTooltip';
import TaskContextMenu from './TaskContextMenu';
import {DropTargetMonitor, useDrag, useDrop, XYCoord} from 'react-dnd';
import MoveTaskAction from './MoveTaskAction';
import {Job, JobType, TimeslotType} from "../../stores/domain/job";
import {VehicleRoute} from "../../stores/domain/vechicle-route";

const highTimeOnVehicleInMinutes = 6 * 60; // 6 hours

interface ITaskItemProps {
    index: number;
    enableCheck: boolean;
    enableDragAndDrop: boolean;
    isChecked: boolean;
    isLiveView: boolean;
    isNextRemaining: boolean;
    liveRoute: VehicleRoute | undefined;
    moveTask: (dragIndex: number, hoverIndex: number) => void;
    onCheck: () => void;
    onClick: React.MouseEventHandler<HTMLElement>;
    task: Task;
    utcNow: DateTime;
    vehicleDepot: string;
    vehicleId?: number;
    canUnassign: boolean;
}

interface IDragItem {
    index: number
    id: string
    type: string
}

export const ItemTypes = {
    TASK: 'task',
}

enum LiveTaskStatus {
    None = 0,
    PastPickup= 1,
    OnRoute= 2,
    Past= 3,
    Complete = 4
}

/**
 * The TaskItem Component
 * 
 * @remarks
 * This is implemented as a functional component not a class component due to drag and drop functionality.
 */
function TaskItem(props: ITaskItemProps) {
    const { index, isLiveView, liveRoute, moveTask, task, vehicleDepot, vehicleId, canUnassign } = props;
    const id = task.id;
    const enableDragAndDrop = props.enableDragAndDrop &&
        (task.type !== TaskType.Start && task.type !== TaskType.End);

    const ref = React.useRef<HTMLDivElement>(null);
    const enableCheck = props.enableCheck && task.job;

    const liveTask = liveRoute && task.job && liveRoute.tasks.find(t => !!t.job && t.job.id == task.job!.id && t.type == task.type)
    
    const getLiveTaskStatus = (): LiveTaskStatus => {
        if (liveTask) {
            if (liveTask.status == TaskStatus.Complete) return LiveTaskStatus.Complete
            if (liveTask.etaDispatchTime && liveTask.etaDispatchTime < props.utcNow) return LiveTaskStatus.Past
            if (liveTask.status == TaskStatus.OnRoute) return LiveTaskStatus.OnRoute
            if (liveTask.type == TaskType.Delivery) {
                const livePickupTask = liveRoute && task.job && liveRoute.tasks.find(t => !!t.job && t.job.id == task.job!.id && t.type == TaskType.Pickup)
                if (livePickupTask && livePickupTask.etaDispatchTime && livePickupTask.etaDispatchTime < props.utcNow) return LiveTaskStatus.PastPickup
            }
        }
        return LiveTaskStatus.None
    }

    const liveTaskStatus = getLiveTaskStatus()

    const getActions = (): React.ReactFragment => {
        if (isLiveView) {
            return <React.Fragment>
                {
                    (task.status === TaskStatus.Complete
                        ? <Grid item xs={1} className="done">
                            <IconTooltip title="Task complete">
                                {{ icon: <Done /> }}
                            </IconTooltip>
                        </Grid>
                        : <TaskContextMenu task={task} vehicleDepot={vehicleDepot} isLiveView={isLiveView} vehicleId={vehicleId} />)
                }
            </React.Fragment>    
        }
        if (enableCheck) {
            return <Grid item xs={1}
                className="align-center">
                    <Tooltip
                        title= {canUnassign ? '' : 'Task cannot be unassigned as it is already started or a preload'}>
                        <div>
                            <Checkbox
                                disabled={!canUnassign}
                                checked={props.isChecked}
                                className="no-pad"
                                onChange={(e) => {
                                    props.onCheck();
                                    e.stopPropagation();
                                }}
                                value={task.job ? task.job.id.toString() : '0'} />
                        </div>
                    </Tooltip>
            </Grid>
        }
        if (enableDragAndDrop) {
            return <Grid item xs={1}
                className="align-center">
                <MoveTaskAction
                    index={index}
                    moveTask={props.moveTask}
                    task={task} />
            </Grid>
        }
        return <Grid item xs={1} className="align-center">
            <Grid container>
                <Grid item xs={5}>
                </Grid>
                <Grid item xs={2}>
                    <TaskContextMenu task={task} vehicleDepot={vehicleDepot} isLiveView={isLiveView} vehicleId={vehicleId} />
                </Grid>
                <Grid item xs={2}>
                    <span>{index}</span>
                </Grid>
                <Grid item xs={3}>
                </Grid>
            </Grid>
        </Grid>
    };
    
    const getStatus = (): React.ReactFragment => {
        if (isLiveView) {
            return <React.Fragment>
                <div className="detail status-text">{task.statusText}</div>
                <div className="headers">Status</div>
            </React.Fragment>
        } else if (liveTaskStatus == LiveTaskStatus.OnRoute || liveTaskStatus == LiveTaskStatus.Complete) {
            return <React.Fragment>
                <div className="detail status-text">{liveTask!.statusText}</div>
                <div className="headers">Status</div>
            </React.Fragment>
        } else if (liveTaskStatus == LiveTaskStatus.Past || liveTaskStatus == LiveTaskStatus.PastPickup) {
            return <React.Fragment>
                <div className="detail status-text">{liveTaskStatus == LiveTaskStatus.Past ? 'Past' : 'Past Pickup'}</div>
                <div className="headers">Status</div>
            </React.Fragment>
        }
        return <React.Fragment>
        </React.Fragment>
    };

    const [, drop] = useDrop({
        accept: ItemTypes.TASK,
        hover(item: IDragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            if (dragIndex === hoverIndex) {
                return
            }

            const hoverBoundingRect = ref!.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            if (enableDragAndDrop) {
                moveTask(dragIndex, hoverIndex)
                item.index = hoverIndex
            }
        },
    })

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.TASK, id, index },
        canDrag: enableDragAndDrop,
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    if (enableDragAndDrop) {
        drag(drop(ref));
    }

    let addressClass = 'address';
    switch (task.type) {
        case TaskType.Start:
            addressClass = 'address start';
            break;
        case TaskType.End:
            addressClass = 'address end';
            break;
        default:
            break;
    }

    let addressText = '';
    switch (task.type) {
        case TaskType.Pickup:
            if (task.job === null) {
                break;
            }
            addressText = task.job!.pickupAddress.addressText;
            break;
        case TaskType.Delivery:
            if (task.job === null) {
                break;
            }
            addressText = task.job!.deliveryAddress.addressText;
            break;
        case TaskType.Start:
            addressText = `Starting ${task.addressText}`;
            break;
        case TaskType.End:
            addressText = `Returning ${task.addressText}`;
            break;
        default:
            break;
    }

    let cursorClass = 'pointer';
    if (enableDragAndDrop) {
        cursorClass = isDragging ? 'move' : 'grab';
    }

    let wrapperClassName = 'taskWrapper'
    let className = `task ${cursorClass} ${enableDragAndDrop ? 'draggable' : ''} ${isDragging ? 'dragging' : ''}`
    if (task.job) {
        switch (task.job.deliveryTimeslot) {
            case TimeslotType.FirstUp:
                className = `${className} firstup-task`;
                break;
            case TimeslotType.ASAP:
                className = `${className} asap-task`;
                break;
        }
    }
    switch (task.status) {
        case TaskStatus.Complete:
            className = `${className} complete-task`;
            break;
        default:
            if (task.status != TaskStatus.None) {
                className = `${className} action`
            }
            
            if (task.onTime !== OnTime.OnTime) {
                className = `${className} outside-window`
                if (task.onTime == OnTime.Late) {
                    className = `${className} late-task`
                }                
            }

            if (props.isNextRemaining) {
                className = `${className} next-eta`
            } else if (task.etaDispatchTime && task.etaDispatchTime < props.utcNow) {
                className = `${className} past-eta`
            }
            
            if (task.etdDispatchTime && task.etdDispatchTime < props.utcNow) {
                className = `${className} past-etd`
            }
    }
    if (!isLiveView)
    {
        switch (liveTaskStatus) {
            case LiveTaskStatus.Complete:
                className = `${className} complete-task`;
                break;
            case LiveTaskStatus.Past:
                className = `${className} past-eta`
                break;
        }
        
        if (task.type === TaskType.Pickup || task.type === TaskType.Delivery) {
            if (liveRoute) {
                if (liveRoute.vehicle && liveRoute.vehicle.id !== vehicleId) {
                    wrapperClassName = `${wrapperClassName} changed-moved`
                }
            } else {
                wrapperClassName = `${wrapperClassName} changed-new`
            }
        }
    }

    const onClick = enableDragAndDrop || enableCheck ? () => {} : props.onClick;

    let reference = '';
    if (task.job)
    {
        reference = task.job.isFreightOnly ? task.job.customerReference : task.job.purchaseOrderNumber;
    }

    const getLockedToVehicleIcon = (job: Job) => {
        switch (job.type) {
            case JobType.PreLoadPickup:
                return <IconTooltip title='Preload pickup'>{{ icon: <AssignmentLateIcon /> }}</IconTooltip>
            case JobType.PreLoadDelivery:
                return <IconTooltip title='Preload delivery'>{{ icon: <AssignmentLateIcon /> }}</IconTooltip>
            case JobType.ReturnToAbcForRedelivery:
                return <IconTooltip title='Returning for redelivery'>{{ icon: <AssignmentReturnedIcon /> }}</IconTooltip>
            default:
                return <IconTooltip title='Assigned to vehicle'>{{ icon: <AssignmentTurnedInIcon /> }}</IconTooltip>
        }
    }

    const getAddressTimeWindow = (task: Task) => {
        if (task.job) {
            switch (task.type) {
                case TaskType.Delivery:
                    switch (task.job.deliveryTimeslot) {
                        case TimeslotType.Custom:
                            return `Deliver ${task.job.deliveryWindow}`
                        default:
                            return `Deliver ${task.job.deliveryTimeslotText} (${task.job.deliveryWindow})`
                    }
                case TaskType.Pickup:
                    return `Pickup ${task.job.pickupWindow}`
            }
        }
        return '';
    }
    
    const completeDateTime = task.completeDateTime || liveTask && liveTask.completeDateTime
    const etaDispatchTime = !isLiveView && liveTaskStatus == LiveTaskStatus.Past ? liveTask!.etaDispatchTime : task.etaDispatchTime
    const etdDispatchTime = !isLiveView && liveTaskStatus == LiveTaskStatus.Past ? liveTask!.etdDispatchTime : task.etdDispatchTime

    return <div
        key={index}
        ref={ref}
        className={wrapperClassName}>
        <Grid container
            className={className}
            onClick={onClick}>
            <Hidden mdUp>
                { getActions() }
                <Grid item xs={2}>
                    <div className="detail time">
                        {
                            <Tooltip title={completeDateTime ? timeFormatted(etaDispatchTime!) + ' ETA' : task.serviceWindowOffsetText}>
                                <span className="time">{completeDateTime ? timeFormatted(completeDateTime!) : timeFormatted(etaDispatchTime!) + ' ETA'}</span>
                            </Tooltip>
                        }
                    </div>
                    <div className="headers etd">
                        {
                            (task.type == TaskType.Pickup || task.type == TaskType.Delivery)
                                ? (task.onTime != OnTime.OnTime) ? task.onTimeText : timeFormatted(etdDispatchTime!) + ' ETC'
                                : 'Time'
                        }
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <Tooltip title={getAddressTimeWindow(task)}>
                        <div className={`detail ${addressClass}`}>{addressText}</div>
                    </Tooltip>
                    <div className="headers customer">
                        <span>{task.job && task.job!.customerName}</span>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title={'Remaining capacity (in pallets)'}>
                        <div className={`detail ${task.isOverCapacity ? 'warning' : ''}`}>
                            {task.remainingPalletCapacity} PLT
                        </div>
                    </Tooltip>
                    <Tooltip title={'Load'}>
                        <div className="headers">{task.loadText}</div>
                    </Tooltip>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={task.job && `Job ID: ${task.job.id}`}>
                        <div className="detail">{task.job && task.job!.orderNumber}</div>
                    </Tooltip>
                    <div className="headers">{reference}</div>
                </Grid>
                <Grid item xs={1} >
                    <div className="task-type">
                        {task.type === TaskType.Pickup ? <ArrowUpwardIcon /> : task.type === TaskType.Delivery ? <ArrowDownwardIcon /> : ''}
                        {
                            task.job && task.job.vehicleId &&
                                getLockedToVehicleIcon(task.job)
                        }
                        {
                            task.job && task.job.isForced &&
                            <IconTooltip title="Scheduled to route with ignore limits. Time and distance constraints for route will be ignored.">{{ icon: <StarIcon /> }}</IconTooltip>
                        }
                        {
                            task.timeOnVehicleInMinutes > highTimeOnVehicleInMinutes &&
                            <IconTooltip title={`Load is on the vehicle for ${task.timeOnVehicleInMinutes} minutes. Check if tasks can be re-organised to deliver earlier, or pick up later, to free up vehicle space for new orders (if it is only a few extra kilometers).`}>{{ icon: <Alarm /> }}</IconTooltip>
                        }
                        {
                            task.statusTooltipText &&
                            <IconTooltip title={task.statusTooltipText}>{{ icon: <Warning /> }}</IconTooltip>
                        }
                    </div>
                </Grid>
            </Hidden>
            <Hidden smDown>
                {getActions()}
                <Grid item xs={1}>
                    <div className="detail time">
                        {
                            <Tooltip title={completeDateTime ? timeFormatted(etaDispatchTime!) + ' ETA' : task.serviceWindowOffsetText}>
                                <span className="time">{completeDateTime ? timeFormatted(completeDateTime!) : timeFormatted(etaDispatchTime!) + ' ETA'}</span>
                            </Tooltip>
                        }
                    </div>
                    <div className="headers etd">
                        {
                            (task.type == TaskType.Pickup || task.type == TaskType.Delivery)
                                ? (task.onTime != OnTime.OnTime) ? task.onTimeText : timeFormatted(etdDispatchTime!) + ' ETC'
                                : 'Time'
                        }
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <Tooltip title={getAddressTimeWindow(task)}>
                        <div className={`detail ${addressClass}`}>{addressText}</div>
                    </Tooltip>
                    <div className="headers">{task.suburbText === '' ? 'Address' : `to ${task.suburbText}`}</div>
                </Grid>
                <Grid item xs={3}>
                    <div className="detail customer">
                        <span>{task.job && task.job!.customerName}</span>
                    </div>
                    <div className="headers">{task.job && 'Customer Name'}</div>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={'Remaining capacity (in pallets)'}>
                        <div className={`detail ${task.isOverCapacity ? 'warning' : ''}`}>
                            {task.remainingPalletCapacity} PLT
                        </div>
                    </Tooltip>
                    <Tooltip title={'Load'}>
                        <div className="headers">{task.loadText}</div>
                    </Tooltip>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={task.job ? `Job ID: ${task.job.id}` : ''}>
                        <div className="detail">{task.job && task.job!.orderNumber}</div>
                    </Tooltip>
                    <div className="headers">{reference}</div>
                </Grid>
                <Grid item xs={1}>
                    {getStatus()}
                </Grid>
                <Grid item xs={1}>
                    <div className="task-type">
                        {task.type === TaskType.Pickup ? <ArrowUpwardIcon /> : task.type === TaskType.Delivery ? <ArrowDownwardIcon /> : ''}
                        {
                            task.job && task.job.vehicleId &&
                                getLockedToVehicleIcon(task.job)
                        }
                        {
                            task.job && task.job.isForced &&
                            <IconTooltip title="Scheduled to route with ignore limits. Time and distance constraints for route will be ignored.">{{ icon: <StarIcon /> }}</IconTooltip>
                        }
                        {
                            task.timeOnVehicleInMinutes > highTimeOnVehicleInMinutes &&
                            <IconTooltip title={`Load is on the vehicle for ${task.timeOnVehicleInMinutes} minutes. Check if tasks can be re-organised to deliver earlier, or pick up later, to free up vehicle space for new orders (if it is only a few extra kilometers).`}>{{ icon: <Alarm /> }}</IconTooltip>
                        }
                        {
                            task.statusTooltipText &&
                            <IconTooltip title={task.statusTooltipText}>{{ icon: <Warning /> }}</IconTooltip>
                        }
                    </div>
                </Grid>
            </Hidden>
        </Grid>
    </div>;
}

export default TaskItem;
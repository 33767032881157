import { DateTime } from 'luxon';
import { Model } from './model';
import { Address } from './address';
import { JobItem } from './job-item';
import { Picture, Signature } from './picture';
import { observable, runInAction, computed } from 'mobx';
import { IJobResponse } from '../../services/jobs-api';
import { utcToLocal } from '../../datetime';
import { hasValue } from '../../validate';

export class Job extends Model {
    @observable public bodyTruck: boolean = false;
    @observable public customerContactEmail: string = '';
    @observable public customerContactName: string = '';
    @observable public customerContactPhone: string = '';
    @observable public customerName: string = '';
    @observable public customerReference: string = '';
    @observable public deliveryAddress: Address = new Address({});
    @observable public deliveryComment: string = '';
    @observable public deliveryDate: DateTime | null = null;
    @observable public deliveryTimeslot: TimeslotType = TimeslotType.None;
    @observable public deliveryWindow: string = '';
    @observable public draftStatus: JobDraftStatus = JobDraftStatus.None;
    @observable public driverName: string = '';
    @observable public id: number = 0;
    @observable public isCrane: boolean = false;
    @observable public isForced: boolean = false;
    @observable public isFreightOnly: boolean = false;
    @observable public jobAuditLogs: IJobAuditLog[] = [];
    @observable public jobItems: JobItem[] = [];
    @observable public mojoeId: string = '';
    @observable public orderApprovalStatus: OrderApprovalStatus = OrderApprovalStatus.None;
    @observable public orderNumber: string = '';
    @observable public parentJobId: number | null = null;
    @observable public pickupAddress: Address = new Address({});
    @observable public pickupComment: string = '';
    @observable public pickupNumber: string = '';
    @observable public pickupWindow: string = '';
    @observable public pictures: Picture[] = [];
    @observable public purchaseOrderNumber: string = '';
    @observable public relatedJobs: IRelatedJob[] = [];
    @observable public signature: Signature | null = null;
    @observable public specialInstruction: string = '';
    @observable public status: JobStatus = JobStatus.Unscheduled;
    @observable public subcontract: boolean = false;
    @observable public type: JobType = JobType.Default;
    @observable public updatedInstructions: boolean = false;
    @observable public vehicleId: number | null = null;
    @observable public vehicleRegistration: string = '';
    
    constructor(obj: Partial<Job>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    @computed
    public get deliveryTimeslotText() {
        switch (this.deliveryTimeslot) {
            case TimeslotType.None:
                return 'None';
            case TimeslotType.AM:
                return 'AM';
            case TimeslotType.AnyTime:
                return 'Any time';
            case TimeslotType.FirstUp:
                return 'First up';
            case TimeslotType.PM:
                return 'PM';
            case TimeslotType.ASAP:
                return 'ASAP';
            case TimeslotType.PreLoad:
                return 'Preload';
            case TimeslotType.Abc:
                return 'ABC';
            default:
                return this.deliveryWindow;
        }
    }
    
    @computed
    public get pickupWindowText() {
        return `${this.pickupAddress.openingTime} - ${this.pickupAddress.closingTime}`;
    }

    @computed
    public get specialInstructionsText() {
        const craneText = this.isCrane ? 'Must meet crane on site' : '';
        return hasValue(craneText) ? `${craneText} ${this.specialInstruction}` : `${hasValue(this.specialInstruction) ? this.specialInstruction : 'None'}`;
    }

    @computed
    public get title() {
        const reference = this.isFreightOnly ? this.customerReference : this.purchaseOrderNumber;
        return `${this.orderNumber} ${hasValue(reference) ? `(${reference})` : ''}`;
    }
    
    public static fromResponse(r: IJobResponse) {
        const j = new Job({
            ...r,
            deliveryDate: DateTime.fromISO(r.deliveryDate),
            pickupAddress: new Address({ ...r.pickupAddress }),
            deliveryAddress: new Address({ ...r.deliveryAddress }),
            jobItems: r.jobItems.map(i => new JobItem({ ...i })),
            signature: r.signature ?
                new Signature(r.signature.filename, r.signature.id, r.signature.signatory) : null,
            pictures: r.pictures && r.pictures.length > 0 ? r.pictures.map(i => new Picture(i.filename, i.id)) : [],
            jobAuditLogs: r.jobAuditLogs ? r.jobAuditLogs.map(x => ({...x, date: utcToLocal(x.createdTimeUtc)}) as IJobAuditLog) : [],
            relatedJobs: r.relatedJobs  ? r.relatedJobs.map(x => ({...x}) as IRelatedJob) : []
        });
        return j;
    }
    
    public static getDraftStatusText(draftStatus: JobDraftStatus) {
        switch (draftStatus) {
            case JobDraftStatus.None:
                return 'None';
            case JobDraftStatus.Scheduling:
                return 'Scheduling';
            case JobDraftStatus.Unschedulable:
                return 'Unschedulable';
            case JobDraftStatus.Drafted:
                return 'Drafted';
        }
        return '';
    }

    public static getShortStatusText(status: JobStatus, draftStatus: JobDraftStatus) {
        return status < JobStatus.Scheduled 
            ? this.getDraftStatusText(draftStatus) 
            : this.getStatusText(status)
    }

    public static getStatusText(status: JobStatus) {
        // Covers JobStatus.Unscheduled = 0, as well as legacy values 1, 2, & 3.
        if (status < JobStatus.Scheduled) return 'Unscheduled'
        switch (status) {
            case JobStatus.Scheduled: // = 4
                return 'Scheduled';
            case JobStatus.OnRoute:
                return 'On Route';
            case JobStatus.Complete:
                return 'Complete';
        }
        return '';
    }

    public validate(): boolean {
        const validation = [
            super.validate(),
            ...this.jobItems.map(i => i.validate())
        ];
        return validation.every(v => v);
    }
}

export interface IJobAuditLog {
    date: DateTime;
    message: string;
    user: string;
    id: string;
}

export interface IRelatedJob {
    id: number;
    type: JobType;
}

export enum JobDraftStatus {
    None = 0,
    Scheduling = 1,
    Unschedulable = 2,
    Drafted = 3,
}

export enum JobStatus {
    Unscheduled = 0,
    Scheduled = 4,
    OnRoute = 5,
    Complete = 6
}

export enum JobType {
    Default = 0,
    TransShipPickup = 1,
    TransShipDelivery = 2,
    PreLoadPickup = 3,
    PreLoadDelivery = 4,
    ReturnToAbcForRedelivery = 5,
    Redelivery = 6,
    ReturnToAbcAndUnload = 7,
    ReturnToSupplier = 8
}

export enum OrderApprovalStatus {
    None = 0,
    PricingApprovalPending = 1,
    CreditApprovalPending = 2
}

export enum TimeslotType {
    None = 0,
    AnyTime = 1,
    FirstUp = 2,
    AM = 3,
    PM = 4,
    ASAP = 5,
    PreLoad = 6,
    Abc = 7,
    Custom = 8
}

import * as React from 'react';
import { Button } from './Button';
import Alarm from '@material-ui/icons/Alarm';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Close from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import ControlCamera from '@material-ui/icons/ControlCamera';
import Done from '@material-ui/icons/Done';

interface IButtonProps {
    onClick(): void
    className?: string;
}

export const AddDelayButton: React.SFC<IButtonProps> = (props: IButtonProps) => {
    return <Button
        {...props}
        label="Add Delay">
        {{ icon: <Alarm /> }}
    </Button>
};

export const AssignDriverButton: React.SFC<IButtonProps> = (props: IButtonProps) => {
    return <Button
        {...props}
        label="Assign">
        {{ icon: <Done /> }}
    </Button>
};

export const CloseButton: React.SFC<IButtonProps> = (props: IButtonProps) => {
    return <Button
        {...props}
        label="Close">
        {{ icon: <Close /> }}
    </Button>
};

export const MoveButton: React.SFC<IButtonProps> = (props: IButtonProps) => {
    return <Button
        {...props}
        label="Move">
        {{ icon: <ControlCamera /> }}
    </Button>
};

export const PreLoadButton: React.SFC<IButtonProps> = (props: IButtonProps) => {
    return <Button
        {...props}
        label="Pre-Load">
        {{ icon: <ArrowDownward /> }}
    </Button>
};

export const SearchButton: React.SFC<IButtonProps> = (props: IButtonProps) => {
    return <Button
        {...props}
        label="Search">
        {{ icon: <Search /> }}
    </Button>
};

export const TransferDriverButton: React.SFC<IButtonProps> = (props: IButtonProps) => {
    return <Button
        {...props}
        label="Transfer">
        {{ icon: <Done /> }}
    </Button>
};

export const TransShipButton: React.SFC<IButtonProps> = (props: IButtonProps) => {
    return <Button
        {...props}
        label="Trans-Ship">
        {{ icon: <SwapHoriz /> }}
    </Button>
};

export const OkButton: React.SFC<IButtonProps> = (props: IButtonProps) => {
    return <Button
        {...props}
        label="Ok">
        {{ icon: <Done /> }}
    </Button>
};

export const SaveButton: React.SFC<IButtonProps> = (props: IButtonProps) => {
    return <Button
        {...props}
        label="Save">
        {{ icon: <Done /> }}
    </Button>
};

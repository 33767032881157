import { VehicleRoute } from './vechicle-route';
import { Model } from './model';
import { observable, computed, runInAction } from 'mobx';
import { IScheduleResponse } from '../../services/schedule-api';
import { DateTime } from 'luxon';
import { toDate } from '../../datetime';
import { TaskStatus } from './task';
import { Driver } from './driver';

export class Schedule extends Model {
    @observable public id: number = 0;
    @observable public date: DateTime | null = null;
    @observable public runId: string = '';
    @observable public vehicleRoutes: VehicleRoute[] = [];
    @observable public draftNeedsUpdate: boolean = false;

    constructor(obj: Partial<Schedule>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    @computed
    public get canPush(): boolean {
        return this.validate() && !this.invalidTasks;
    }

    @computed
    public get invalidTasks(): boolean {
        return this.vehicleRoutesWithTasks.some(r => r.tasks.some(t => t.status > TaskStatus.Complete));
    }

    @computed
    public get vehicleRoutesWithTasks() {
        return this.vehicleRoutes.filter(r => r.tasks.length > 0);
    }

    @computed
    public get vehicles() {
        return this.vehicleRoutes.map(x => x.vehicle!);
    }

    @computed
    public get unassignedDrivers() {
        let drivers = this.vehicleRoutes
            .filter(r => r.tasks.length === 0 && r.driver)
            .map(r => r.driver!)
            .sort((a: Driver, b: Driver): number => a.name < b.name ? -1 : 1);

        // Filter out any duplicates
        return drivers.filter((driver, index) => {
            return index === drivers.findIndex(obj => {
                return obj.id === driver.id;
            });
        });
    }
    
    public static fromResponse(r: IScheduleResponse) {
        const j = new Schedule({
            ...r,
            vehicleRoutes: r.vehicleRoutes.map(i => VehicleRoute.fromResponse(i)),
            date: toDate(r.date)
        });
        return j;
    }

    public validate(): boolean {
        const validation = [
            super.validate(),
            ...this.vehicleRoutesWithTasks.map(i => i.validate())
        ];
        return validation.every(v => v);
    }

    @computed
    public get jobIds() {
        if(this.vehicleRoutesWithTasks.length > 0){
        return this.vehicleRoutesWithTasks.map(x => x.tasks)
            .reduce((a, b) => a.concat(b))
            .filter(x => x.job != null)
            .map(x => x.job!.id).filter((value, index, self) => self.indexOf(value) === index)
        }
        else {
            return [];
        }
    }
}

export enum PublishStatus {
    None = 0,
    Draft = 1,
    Live = 2
}
import * as React from 'react';
import './styles.scss';
import { Unavailability } from '../../stores/domain/unavailability';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import UnavailabilityItem from './UnavailabilityItem';
import UnavailablityDialog from '../Unavailability/UnavailabilityDialog';
import { observable, action, computed } from 'mobx';

interface IUnavailabilitySectionProps {
    unavailabilities: Unavailability[]
    readOnly?: boolean
    onSave: (model: Unavailability) => void
    onDelete: (model: Unavailability) => void
}

@observer
class UnavailabilitySection extends React.Component<IUnavailabilitySectionProps> {

    @observable private dialogActive: boolean = false;
    @observable private unavailability: Unavailability = new Unavailability({});

    constructor(props: IUnavailabilitySectionProps) {
        super(props);
        this.handleAddNewUnavailability = this.handleAddNewUnavailability.bind(this);
    }

    public render() {
        return <section className="abc-section unavailability-section">
            <Grid container>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <h2>Unavailability</h2>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {
                        this.orderedUnavailabilities.map((u, i) => (<UnavailabilityItem
                            key={i}
                            model={u}
                            readOnly={this.props.readOnly}
                            onDeleteUnavailability={this.props.onDelete}
                            onOpenDialog={() => this.setDialogActive(true, u)} />))
                    }
                </Grid>
                {
                    !this.props.unavailabilities.length
                    && <React.Fragment>
                        <Grid item xs={1} />
                        <Grid item xs><p className="sub-text">No unavailability recorded</p></Grid>
                    </React.Fragment>
                }
            </Grid>
            {
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        {
                            !this.props.readOnly
                            && <a className="action link-button" onClick={this.handleAddNewUnavailability}>Add record</a>
                        }
                    </Grid>
                </Grid>
            }
            <UnavailablityDialog
                model={this.unavailability.clone()}
                readOnly={this.props.readOnly}
                active={this.dialogActive}
                onClose={() => this.setDialogActive(false)}
                onSave={(model: Unavailability) => {
                    this.props.onSave(model);
                    this.setDialogActive(false);
                }}
            />
        </section>
    }

    @action
    private setDialogActive(active: boolean, unavailability?: Unavailability) {
        if (unavailability) this.unavailability = unavailability;
        this.dialogActive = active;
    }

    @computed
    private get orderedUnavailabilities() {
        return this.props.unavailabilities.slice().sort((a: Unavailability, b: Unavailability) => {
            return a.startDispatchTime! < b.startDispatchTime! ? -1 : 1;
        })
    }

    private handleAddNewUnavailability() {
        this.setDialogActive(true, new Unavailability({ lineId: this.props.unavailabilities.length + 1 }));
    }
}

export default UnavailabilitySection;
import { observable, action } from 'mobx';
import { JobFormState } from './ui-state/job-form-state';
import { PreLoadDialogState } from './ui-state/pre-load-dialog-state';
import { AbcReturnDialogState } from './ui-state/abc-return-dialog-state';
import { ScheduleUiState } from './ui-state/schedule-ui-state';
import { ScheduleListState } from './ui-state/schedule-list-state';
import { SupplierReturnDialogState } from './ui-state/supplier-return-dialog-state';
import { TransShipDialogState } from './ui-state/trans-ship-dialog-state';
import { UndoDialogState } from './ui-state/undo-dialog-state';
import { UnassignLiveJobDialogState } from './ui-state/unassign-live-job-dialog-state';
import { VehicleFormState } from './ui-state/vehicle-form-state';
import { SpareVehicleDialogState } from './ui-state/spare-vehicle-dialog-state';
import { ScheduleToRouteDialogState } from './ui-state/schedule-to-route-dialog-state';
import { TransferDriverDialogState } from './ui-state/transfer-driver-dialog-state';
import { AssignDriverDialogState } from './ui-state/assign-driver-dialog-state';
import { IRootStore } from '../initStores';

export class UiStore {
    private readonly rootStore: IRootStore;

    @observable public navActive: boolean = false;
    @observable public abcReturnDialogState: AbcReturnDialogState = new AbcReturnDialogState();
    @observable public actionsMenuActive: boolean = false;
    @observable public assignDriverDialogState: AssignDriverDialogState = new AssignDriverDialogState(this.rootStore.driverStore);
    @observable public scheduleToRouteDialogState: ScheduleToRouteDialogState = new ScheduleToRouteDialogState();
    @observable public jobFormState: JobFormState = new JobFormState();
    @observable public preLoadDialogState: PreLoadDialogState = new PreLoadDialogState();
    @observable public scheduleUiState: ScheduleUiState = new ScheduleUiState();
    @observable public scheduleListState: ScheduleListState = new ScheduleListState();
    @observable public spareVehicleDialogState: SpareVehicleDialogState = new SpareVehicleDialogState();
    @observable public transferDriverDialogState: TransferDriverDialogState = new TransferDriverDialogState(this.rootStore.vehicleStore);
    @observable public supplierReturnDialogState: SupplierReturnDialogState = new SupplierReturnDialogState();
    @observable public transShipDialogState: TransShipDialogState = new TransShipDialogState();
    @observable public unassignLiveJobDialogState: UnassignLiveJobDialogState = new UnassignLiveJobDialogState();
    @observable public undoDialogState: UndoDialogState = new UndoDialogState();
    @observable public vehicleFormState: VehicleFormState = new VehicleFormState();
    
    constructor(rootStore: IRootStore) {
        this.rootStore = rootStore;
    }

    @action
    public resetDialogs() {
        this.abcReturnDialogState.reset();
        this.assignDriverDialogState.reset();
        this.scheduleToRouteDialogState.reset();
        this.preLoadDialogState.reset();
        this.supplierReturnDialogState.reset();
        this.transShipDialogState.reset();
        this.transferDriverDialogState.reset();
        this.unassignLiveJobDialogState.reset();
        this.undoDialogState.reset();
    }

    @action
    public setActionsMenuActive(active: boolean) {
        this.actionsMenuActive = active;
    }

    @action
    public setNavActive(active: boolean) {
        this.navActive = active;
    }
}
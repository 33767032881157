import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';
import { DriverStore } from '../../stores/driver-store';
import Grid from '@material-ui/core/Grid';
import { NoRecords } from '../NoRecords/NoRecords';
import { IDriverSummary } from '../../stores/domain/driver-summary';
import { IconTooltip } from '../IconTooltip/IconTooltip';
import LocalShipping from '@material-ui/icons/LocalShipping';
import { DateTime } from 'luxon';
import { timeFormatted, dateTimeFormattedShort } from '../../datetime';
import LocationCity from '@material-ui/icons/LocationCity';

interface IDriversListProps {
    driverStore?: DriverStore
}

type Props = IDriversListProps & RouteComponentProps<{}>;

const DriverList: React.SFC<Props> = observer((props: Props) => {

    const getIcons = (d: IDriverSummary) => {
        const tomorrowStart = DateTime.local().plus({ days: 1 }).startOf('day');
        const tomorrowEnd = tomorrowStart.endOf('day');

        const tomorrowUnavailability = d.defaultVehicle
            ? d.defaultVehicle.nextUnavailability.find(u => u.start! >= tomorrowStart && u.start! <= tomorrowEnd)
            : undefined;

        return (<React.Fragment>
            {
                tomorrowUnavailability
                && <IconTooltip title={`Driver has no vehicle starting tomorrow. 
                    Default vehicle ${d.defaultVehicle!.registration} unavailble 
                    from ${timeFormatted(tomorrowUnavailability.start)} to ${dateTimeFormattedShort(tomorrowUnavailability.end)}`}>
                    {{ icon: <LocalShipping className="error" /> }}
                </IconTooltip>
            }
            {
                d.validAddress && !d.validAddress!
                && <IconTooltip title={`Location is missing key information from Google. Please ensure the location is correct and searchable in Google.`}>
                    {{ icon: <LocationCity /> }}
                </IconTooltip>
            }
        </React.Fragment>)
    };

    return <div className="drivers-list page">
        {
            props.driverStore!.drivers.map((d, i) => (
                <Grid container
                    key={i}
                    onClick={() => props.history.push(`view-driver/${d.id}`)}
                    className="driver">
                    <Grid item xs={1} />
                    <Grid item xs={10} className="main-text">{d.name}</Grid>
                    <Grid item xs={1} />
                    <Grid item xs={1} />
                    <Grid item xs={7} className="sub-text">{d.defaultVehicle ? d.defaultVehicle.registration : 'NO DEFAULT VEHCILE'}: {d.suburb}</Grid>
                    <Grid item xs={3} className="status-icons">{getIcons(d)}</Grid>
                </Grid>))
        }
        <NoRecords message="No more drivers" active />
    </div>
});

export default DriverList;
import * as React from 'react';
import { observer } from 'mobx-react';
import { Model } from '../../stores/domain/model';
import { INumberChangedEvent } from '../NumberField/NumberField';

interface Injected {
    model?: Model
}

export interface IAsFormProps {
    handleChange?(e: React.ChangeEvent<HTMLInputElement>): void
    handleSelectChanged?(e: React.ChangeEvent<HTMLSelectElement>): void
    handleSetProperty?(property: string, value: any): void
    handleNumberChanged?(e: INumberChangedEvent): void
}

export const asForm = <P extends object>(Component: React.ComponentType<P>) => {
    @observer
    class AsForm extends React.Component<P & Injected> {

        constructor(props: P & Injected) {
            super(props);
            this.handleChange = this.handleChange.bind(this);
            this.handleSelectChanged = this.handleSelectChanged.bind(this);
            this.handleSetProperty = this.handleSetProperty.bind(this);
            this.handleNumberChanged = this.handleNumberChanged.bind(this);
        }
        
        public render() {
            const p = {
                ...(this.props as object),
                handleChange: this.handleChange,
                handleSelectChanged: this.handleSelectChanged,
                handleSetProperty: this.handleSetProperty,
                handleNumberChanged: this.handleNumberChanged
            } as P;

            return <React.Fragment>
                <Component {...p} />
            </React.Fragment>
        }
    
        public handleChange(e: React.ChangeEvent<HTMLInputElement>) {
            if (!this.props.model) return;
            this.props.model.updateAndValidate(e.currentTarget.name, e.currentTarget.value);
        }

        public handleSetProperty(property: string, value: any) {
            if (!this.props.model) return;
            this.props.model.updateAndValidate(property, value);
        }
    
        public handleSelectChanged(e: React.ChangeEvent<HTMLSelectElement>) {
            if (!this.props.model) return;
            this.props.model.updateAndValidate(e.target.name, e.target.value);
        }

        public handleNumberChanged(e: INumberChangedEvent) {
            if (!this.props.model) return;
            this.props.model.updateAndValidate(e.target, e.parsedValue);
        }
    }
    return AsForm;
};
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { RouteComponentProps } from 'react-router';
import { Grid } from '@material-ui/core';
import { ScheduleStore } from '../../stores/schedule-store';
import { computed } from 'mobx';
import ScheduleList from './ScheduleList';
import tracker from '../Loader/loader-tracker';
import {PublishStatus, Schedule} from '../../stores/domain/schedule';
import { UiStore } from '../../stores/ui-store';
import { JobStore } from '../../stores/job-store';
import JobList from '../Jobs/JobList';
import SpareVehicles from './SpareVehicles';
import UnassignedDrivers from './UnassignedDrivers';
import ExpandingSection from '../ExpandingSection/ExpandingSection';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { ActionItem } from '../ActionsMenu/ActionItem';
import GetApp from '@material-ui/icons/GetApp';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Publish from '@material-ui/icons/Publish';
import Undo from '@material-ui/icons/Undo';
import { IJobSummary } from '../../stores/domain/job-summary';
import ChangeList from './ChangeList';
import AssignDriverDialog from './AssignDriverDialog';
import TransferDriverDialog from './TransferDriverDialog';
import { JobStatus } from '../../stores/domain/job';
import { ScheduleTimeoutOptions } from '../ScheduleTimeoutOptions/ScheduleTimeoutOptions';

interface IDraftScheduleProps {
    jobStore?: JobStore
    scheduleStore?: ScheduleStore
    uiStore?: UiStore
}
type Props = IDraftScheduleProps & RouteComponentProps<{}>;

@inject('jobStore', 'scheduleStore', 'uiStore')
@observer
class DraftSchedule extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.handlePush = this.handlePush.bind(this);
        this.handleDiscard = this.handleDiscard.bind(this);
        this.handleDownloadCsv = this.handleDownloadCsv.bind(this);
        this.handleRunSchedule = this.handleRunSchedule.bind(this);
    }

    public render() {
        const { scheduleStore } = this.props;
        const canUndo = scheduleStore!.currentSchedule.runId.length > 0;
        const schedule = scheduleStore!.currentDraftSchedule;
        const scheduleState = this.props.uiStore!.scheduleUiState;
        const isTodayOrFuture = scheduleState.scheduleDate >= scheduleState.today
        
        if (scheduleStore!.isGenerating) {
            return <div className="no-schedule">
                <Grid container className="schedule" alignContent={'center'} alignItems={'center'}>
                    <Grid item xs={1} />
                    <Grid item xs={10} className="no-schedule-header">We're generating the schedule!</Grid>
                    <Grid item xs={1} />
                    <Grid item xs={12} className="no-schedule-content">Please stand by</Grid>
                    <Grid item xs={12} className="center">
                        <img className="no-schedule-image" src={process.env.PUBLIC_URL + '/draft-robo.png'} alt="Robot Image" />
                    </Grid>
                </Grid>
            </div>;
        }
        else {
            if (schedule.runId.length === 0) {
                return <div className="schedule-container draft-schedule">
                {this.props.jobStore!.jobsRequiringAttention.length > 0 &&
                    <ExpandingSection
                        title="Jobs requiring attention"
                        highlight={true}
                        contentCount={this.props.jobStore!.jobsRequiringAttention.length}>
                        {{
                            content: <JobList {...this.props}
                                jobs={this.props.jobStore!.jobsRequiringAttention}
                                showNoMoreRecords />
                        }}
                    </ExpandingSection>
                }
                <ExpandingSection
                    title="Unassigned Jobs"
                    highlight={true}
                    contentCount={this.unassignedJobs.length}>
                    {{
                        content: <JobList {...this.props}
                            jobs={this.unassignedJobs}
                            showNoMoreRecords />
                    }}
                </ExpandingSection>
                    
                {isTodayOrFuture && <ActionsMenu>
                    <ScheduleTimeoutOptions />
                    <ActionItem
                        enabled={true}
                        label="Run Schedule"
                        onClick={this.handleRunSchedule}
                        tooltip="Run Schedule">
                        <PlayArrow />
                    </ActionItem>
                </ActionsMenu>}
            </div>;
            } else {
                return <React.Fragment>
                <div className="schedule-container draft-schedule">
                    {this.props.scheduleStore!.jobChanges.length > 0 &&
                        <ExpandingSection
                            title="Jobs changes"
                            highlight={true}
                            contentCount={this.props.scheduleStore!.jobChanges.length}>
                            {{
                                content: <ChangeList {...this.props} />
                            }}
                        </ExpandingSection>
                    }
                    {this.props.jobStore!.jobsRequiringAttention.length > 0 &&
                        <ExpandingSection
                            title="Jobs requiring attention"
                            highlight={true}
                            contentCount={this.props.jobStore!.jobsRequiringAttention.length}>
                            {{
                                content: <JobList {...this.props}
                                    jobs={this.props.jobStore!.jobsRequiringAttention}
                                    showNoMoreRecords />
                            }}
                        </ExpandingSection>
                    }
                    <ExpandingSection
                        title="Unassigned Jobs (this draft)"
                        highlight={true}
                        contentCount={this.unassignedJobs.length}>
                        {{
                            content: <JobList {...this.props}
                                jobs={this.unassignedJobs}
                                showNoMoreRecords />
                        }}
                    </ExpandingSection>
                    <ExpandingSection
                        title="Unassigned Drivers"
                        highlight={true}
                        contentCount={scheduleStore!.currentDraftSchedule.unassignedDrivers.length}>
                        {{
                            content: <UnassignedDrivers />
                        }}
                    </ExpandingSection>
                    <ExpandingSection
                        title="Spare Vehicles"
                        highlight={true}
                        contentCount={scheduleStore!.availableVehicles.length}>
                        {{
                            content: <SpareVehicles />
                        }}
                    </ExpandingSection>
                    <ScheduleList {...this.props} />
                    <TransferDriverDialog />
                    <AssignDriverDialog />
                    {isTodayOrFuture && <ActionsMenu>
                        <ScheduleTimeoutOptions />
                        <ActionItem
                            enabled={true}
                            label="Run Again"
                            onClick={this.handleRunSchedule}
                            tooltip="Run Again">
                            <PlayArrow />
                        </ActionItem>
                        <ActionItem
                            enabled={canUndo}
                            label="Discard"
                            onClick={this.handleDiscard}
                            tooltip={canUndo ? 'Revert this to the live schedule' : 'There is no live schedule to revert to'}>
                            <Undo />
                        </ActionItem>
                        <ActionItem
                            enabled={schedule.canPush}
                            label="Push"
                            onClick={this.handlePush}
                            tooltip={DraftSchedule.getSchedulePushTooltip(schedule)}>
                            <Publish />
                        </ActionItem>
                        <ActionItem
                            enabled={true}
                            label="Export as CSV"
                            onClick={this.handleDownloadCsv}>
                            <GetApp />
                        </ActionItem>
                    </ActionsMenu>}
                </div>
            </React.Fragment>
            }
        }
    }

    /** Gets jobs which have not been assigned to the current draft */
    @computed
    private get unassignedJobs(): IJobSummary[] {
        const schedule = this.props.scheduleStore!.currentDraftSchedule;
        return computed(() => this.props.jobStore!.jobs.filter(j => !j.subcontract
            && j.status < JobStatus.Complete
            && !schedule.jobIds.includes(j.id))).get();
    }

    private async handleDiscard() {
        const { scheduleStore, uiStore } = this.props;
        const ok = await tracker.track(scheduleStore!.discardDraft(), 'actions-menu')
        if (ok) {
            uiStore!.setActionsMenuActive(!uiStore!.actionsMenuActive);
        }
    }
    
    private async handleDownloadCsv() {
        const { scheduleStore } = this.props;
        const scheduleId = scheduleStore!.currentDraftSchedule.id;

        this.props.scheduleStore!.downloadDraftScheduleCsv(scheduleId)
			.then((blob: File) => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = blob.name;
                    a.click();
            });
    }
    
    private async handlePush() {
        const { jobStore, scheduleStore, uiStore } = this.props;
        const schedule = uiStore!.scheduleUiState.publishStatus === PublishStatus.Live ? scheduleStore!.currentSchedule : scheduleStore!.currentDraftSchedule;

        const ok = await tracker.track(scheduleStore!.push(schedule), 'actions-menu');
        if (ok) {
            uiStore!.scheduleUiState.setPublishStatus(PublishStatus.Live);
            await jobStore!.getJobs();
        }
    }

    private async handleRunSchedule() {
        await tracker.track(
            this.props.scheduleStore!.sendCreateScheduleRequest(
                this.props.uiStore!.scheduleUiState.scheduleDate, this.props.uiStore!.scheduleUiState.runTimeoutInSeconds
            ), 'actions-menu'
        );
    }
    
    private static getSchedulePushTooltip(schedule: Schedule): string
    {
        if (schedule.canPush) return 'Push to live'
        
        return schedule.draftNeedsUpdate
            ? 'Schedule needs to run again as jobs have changed'
            : 'Check and unassign invalid tasks before pushing'
    }
}

export default DraftSchedule;
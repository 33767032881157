import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import {observer} from 'mobx-react';
import './styles.scss';
import scheduleTimerNotification, {TimerType} from './schedule-timer-notification';
import Countdown, {CountdownRenderProps} from 'react-countdown';

@observer
class ScheduleTimerMessage extends React.Component {

    countDownCompletedAt: number = 0;

    constructor(props: any) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleIconClose = this.handleIconClose.bind(this);
        this.renderer = this.renderer.bind(this);
    }

    private handleClose(event: any, reason: string) {
        if (reason === 'clickaway') return;
        scheduleTimerNotification.close();
    }

    private handleIconClose() {
        scheduleTimerNotification.close();
    }

    public render() {
        return (
            <div>
                <Snackbar
                    className={`notification info`}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={scheduleTimerNotification.active}
                    onClose={this.handleClose}
                    message={<span>
                        <Countdown
                            date={Date.now() + (scheduleTimerNotification.timeOutInSeconds * 1000)}
                            renderer={this.renderer} onComplete={() => this.countDownCompletedAt = Date.now()}
                            overtime={true}
                        />
                    </span>}
                    action={[
                        <IconButton
                            key="close"
                            color="inherit"
                            onClick={this.handleIconClose}>
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            </div>
        );
    }

    private renderer(props: CountdownRenderProps) {
        if (props.completed) {
            return this.countDownCompletedAt && Date.now() - this.countDownCompletedAt > 10000
                ?
                <span>
                {`Schedule ${scheduleTimerNotification.timerType === TimerType.Generating ? 'generation' : 'update'} timed out!`}
            </span> : 'Loading results ...';
        } else {
            const scheduleDate = scheduleTimerNotification.date.toFormat('dd/MM/yyyy');
            return <span>
                {`${scheduleTimerNotification.timerType === TimerType.Generating ? 'Generating' : 'Updating'} Schedule (${scheduleDate}) ${this.pad(props.minutes)}:${this.pad(props.seconds)}`}
            </span>;
        }
    };

    private pad(value: number) {
        return value < 10 ? `0${value}` : String(value);
    }
}

export default ScheduleTimerMessage;
import { parse } from 'content-disposition';
import { DateTime } from 'luxon';
import { adalApiFetch } from '../adalConfig';
import { DriverAssignment } from '../stores/domain/driver-assignment';
import { DriverTransfer } from '../stores/domain/driver-transfer';
import { Schedule } from '../stores/domain/schedule';
import { ScheduleToRoute } from '../stores/domain/schedule-to-route';
import { OnTime, Task, TaskType } from '../stores/domain/task';
import { UnassignedJobs } from '../stores/domain/unassigned-jobs';
import { UnscheduledDelay } from '../stores/domain/unscheduled-delay';
import { TruckType } from '../stores/domain/vehicle';
import { IDriverResponse } from './drivers-api';
import { IValidationErrorsResponse } from '../types/IValidationErrorsResponse';
import { IJobResponse } from './jobs-api';
import { IVehicleResponse } from './vehicles-api';
import {JobChangeType} from "../stores/domain/job-change-summary";

export class ScheduleApi {
    public async addUnscheduledDelay(unscheduledDelay: UnscheduledDelay): Promise<boolean> {
        const response = await adalApiFetch(`api/schedule/addunscheduleddelay`, {
            method: 'post',
            body: JSON.stringify({
                delayInMinutes: unscheduledDelay.delayInMinutes,
                newDriverId: unscheduledDelay.newDriverId === 0 ? null : unscheduledDelay.newDriverId,
                newVehicleId: unscheduledDelay.newVehicleId === 0 ? null : unscheduledDelay.newVehicleId,
                routeId: unscheduledDelay.routeId,
            })
        });
        return response.ok;
    }

    public async assignDriver(driverAssignment: DriverAssignment): Promise<boolean> {
        const response = await adalApiFetch(`api/schedule/assigndriver`, {
            method: 'post',
            body: driverAssignment.serialized
        });
        return response.ok;
    }

    public async completePickupTask(task: Task): Promise<boolean> {
        const response = await adalApiFetch(`api/schedule/completePickup/${task.id}`, { method: 'patch' });
        return response.ok;
    }

    public async completeDeliveryTask(task: Task): Promise<boolean> {
        const response = await adalApiFetch(`api/schedule/completeDelivery/${task.id}`, { method: 'patch' });
        return response.ok;
    }

    public async discardDraft(runId: string): Promise<boolean> {
        const response = await adalApiFetch(`api/schedule/discard?runid=${runId}`, {
            method: 'post'
        });
        return response.ok;
    }

    public async downloadDraftScheduleCsv(id: number): Promise<File> {
        const response = await adalApiFetch(`api/schedule/download/${id}`);
        const blob = await response.blob();
        const contentDisposition = parse(response.headers.get('content-disposition'));
        const contentType = response.headers.get('content-type');
        const fileName = contentDisposition.parameters.filename;

        return new File([blob], fileName, { type: contentType });
    }
    
    public async downloadLiveScheduleCsv(id: number): Promise<File> {
        const response = await adalApiFetch(`api/schedule/download/${id}/live`);
        const blob = await response.blob();
        const contentDisposition = parse(response.headers.get('content-disposition'));
        const contentType = response.headers.get('content-type');
        const fileName = contentDisposition.parameters.filename;

        return new File([blob], fileName, { type: contentType });
    }

    public async downloadLoadinSheetZip(routeId: number): Promise<File> {
        const response = await adalApiFetch(`api/schedule/downloadloadingsheet/${routeId}`);
        const blob = await response.blob();
        const contentDispotion = parse(response.headers.get('content-disposition'));
        const contentType = response.headers.get('content-type');
        const fileName = contentDispotion.parameters.filename;

        return new File([blob], fileName, { type: contentType });
    }

    public async getAvailableRoutes(jobId: number): Promise<IScheduleAvailabilityResponse[]> {
        const response = await adalApiFetch(`api/routes`, {
            method: 'post',
            body: JSON.stringify({
                jobId
            })
        });
        return (response.ok ? await response.json() : []) as IScheduleAvailabilityResponse[];
    }

    public async lockRoute(runId: string, routeGuid: string) {
        const response = await adalApiFetch(`api/routes/lock`, {
            method: 'post',
            body: JSON.stringify({
                routeGuid,
                runId
            })
        });
        return response.ok;
    }

    public async push(schedule: Schedule): Promise<IValidationErrorsResponse> {
        const response = await adalApiFetch(`api/schedule/push?runid=` + schedule.runId, {
            method: 'post'
        });
        return (response.ok ? await response.json() : {}) as IValidationErrorsResponse
    }

    public async saveUnassignedJobs(routeChange: UnassignedJobs, directRemove: boolean): Promise<boolean> {
        const response = await adalApiFetch(`api/schedule/unassignjobs`, {
            method: 'post',
            body: JSON.stringify({
                runId: routeChange.runId,
                routeGuid: routeChange.routeGuid,
                jobIdsToRemove: routeChange.jobIdsToRemove,
                directRemove
            })
        });
        return response.ok;
    }

    public async scheduleJobToRoute(scheduleToRoute: ScheduleToRoute) {
        const response = await adalApiFetch(`api/routes/schedule`, {
            method: 'post',
            body: scheduleToRoute.serialized
        });
        return response.ok;
    }

    public async sendCreateScheduleRequest(date: DateTime, timeoutInSeconds: number) {
        const response = await adalApiFetch(`api/schedule/create`, {
            method: 'post',
            body: JSON.stringify({
                date: date.toISODate(),
                timeoutInSeconds: timeoutInSeconds
            })
        });
        return response.ok;
    }


    public async transferDriver(driverTransfer: DriverTransfer): Promise<boolean> {
        const response = await adalApiFetch(`api/schedule/transferdriver`, {
            method: 'post',
            body: driverTransfer.serialized
        });
        return response.ok;
    }

    public async getScheduleForDate(date: DateTime) {
        const response = await adalApiFetch(`api/schedule?date=` + date.toISODate());
        return (response.ok ? await response.json() : undefined) as IScheduleResponse
    }

    public async getAllSchedulesForDate(date: DateTime) {
        const response = await adalApiFetch(`api/schedule/all?date=` + date.toISODate());
        return (response.ok ? await response.json() : undefined) as ICurrentSchedulesResponse
    }

    public async recalculateVehicleRoute(runId: string, vehicleRouteGuid: string, ordering: number[]) {
        const response = await adalApiFetch(`api/schedule/recalculatevehicleroute`, {
            method: 'post',
            body: JSON.stringify({
                ordering,
                runId,
                vehicleRouteGuid
            })
        });
        return (response.ok ? await response.json() : undefined) as  IVehicleRouteReponse;
    }

    public async scheduleJobsToVehicle(date: DateTime, jobs: number[], vehicleId: number) {
        const response = await adalApiFetch(`api/schedule/schedulejobstovehicle`, {
            method: 'post',
            body: JSON.stringify({
                date,
                jobIds: jobs,
                vehicleId
            })
        });
        return response.ok;
    }

    public async saveVehicleRoute(runId: string, vehicleRouteGuid: string, ordering: number[]): Promise<boolean> {
        const response = await adalApiFetch(`api/schedule/savevehicleroute`, {
            method: 'post',
            body: JSON.stringify({
                ordering,
                runId,
                vehicleRouteGuid
            })
        });

        return response.ok;
    }

    public async unlockRoute(runId: string, routeGuid: string) {
        const response = await adalApiFetch(`api/routes/unlock`, {
            method: 'post',
            body: JSON.stringify({
                routeGuid,
                runId
            })
        });
        return response.ok;
    }
}

export interface ICurrentSchedulesResponse {
    isGenerating: boolean;
    jobChanges: IJobChangeSummaryResponse[];
    scheduleAvailabilities: IScheduleAvailabilityResponse[];
    draftSchedule: IScheduleResponse;
    schedule: IScheduleResponse;
}

export interface IScheduleResponse {
    id: number;
    date: string;
    runId: string;
    vehicleRoutes: IVehicleRouteReponse[];
}

export interface ITaskResponse {
    addressText: string;
    capacityProportionChange: number;
    completedDateTimeUtc: string;
    completedDispatchTime: string;
    date: string;
    distanceInKm: number;
    etaDispatchTime: string;
    etdDispatchTime: string;
    etaUtc: string;
    etdUtc: string;
    guid: string;
    id: number;
    job: IJobResponse;
    loadChanged: boolean;
    onTime: OnTime;
    packChange: number;
    packLoad: number;
    palletChange: number;
    palletLoad: number;
    remainingPalletCapacity: number;
    sequence: number;
    serviceTimeInMinutes: number;
    serviceWindowOffsetDuration: string;
    status: number;
    timeOnVehicleInMinutes: number;
    travelTimeInMinutes: number;
    type: TaskType;
    usedCapacityProportion: number;
}

export interface IVehicleRouteReponse {
    driver: IDriverResponse;
    guid: string;
    id: number;
    isLocked: boolean;
    loadScore: number;
    tasks: ITaskResponse[];
    totalHours: number;
    vehicle: IVehicleResponse;
}

export interface IScheduleAvailabilityResponse {
    driverId: number;
    driverName: string;
    registration: string;
    scheduleId: number;
    startDispatchTime: string;
    endDispatchTime: string;
    routeGuid: string;
    truckType: TruckType;
    vehicleId: number;
}

export interface IJobChangeSummaryResponse {
    changeType: JobChangeType;
    driver: string;
    pickupAddress: string;
    deliveryAddress: string;
    jobId: number;
    timeOfChange: string;
    newValue: string;
    orderNumber: string;
    previousValue: string;
}

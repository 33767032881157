import * as React from 'react';
import './styles.scss';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';

interface ISectionProps {
    title?: string
}

@observer
class Section extends React.Component<ISectionProps> {

    public render() {
        return <section className="abc-section">
            <Grid container>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    {
                        this.props.title
                        && <h2>{this.props.title}</h2>
                    }
                    {this.props.children}
                </Grid>
            </Grid>
        </section>
    }
}

export default Section;
export interface IJobChangeSummary {
    changeType: JobChangeType;
    driver: string;
    pickupAddress: string;
    deliveryAddress: string;
    jobId: number;
    timeOfChange: string;
    newValue: string;
    orderNumber: string;
    previousValue: string;
}

export enum JobChangeType {
    None = 0,
    Quantities = 1,
    DeliveryAddress = 2,
    DeliveryDate = 3,
    ContactName = 4,
    ContactEmail = 5,
    ContactPhone = 6,
    CustomerName = 7,
    CustomerReference = 8,
    PurchaseOrderNumber = 9,
    SpecialInstructions = 10,
    Timeslot = 11,
    Delete = 12,
    IsCrane = 13,
    SubContract = 14,
    PickupAddress = 15,
    BodyTruck = 16
}
import * as React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles, Grid, Hidden } from '@material-ui/core';
import { DriverUnavailability } from '../../stores/domain/driver-unavailability';
import { globalStyles } from '../../theme';
import { dateFormattedShort } from '../../datetime';
import PopupMenu from '../PopupMenu/PopupMenu';
import { DayOfWeekFlags } from '../../stores/domain/day-of-week-flags';

interface IDriverUnavailabilityItemProps extends WithStyles<typeof globalStyles> {
    onOpenDialog(): void
    onDeleteDriverUnavailability(model: DriverUnavailability): void
    model: DriverUnavailability
    readOnly?: boolean
}
const DriverUnavailabilityItem: React.SFC<IDriverUnavailabilityItemProps> = observer((props) => {

    return (
        <Grid container className="unavailability">
            <Grid item xs={1} className="more">
                {
                    !props.readOnly
                    && <PopupMenu items={[
                        {name: 'Edit', onClick: props.onOpenDialog},
                        {name: 'Delete', onClick: () => props.onDeleteDriverUnavailability(props.model)}
                    ]} />
                }
            </Grid>
            <Grid item xs={10}>
                <Grid container>
                    <Hidden mdUp>
                        <Grid item xs={10} className="main-text">{props.model.reason}</Grid>
                        <Grid item xs={1} />
                        {
                            (DayOfWeekFlags.None === props.model.repeatDayFlags) &&
                            <Grid item xs={10} className="sub-text">
                                {`From: ${dateFormattedShort(props.model.startDate)} ${props.model.startDispatchTime} to ${dateFormattedShort(props.model.endDate)} ${props.model.endDispatchTime}`}
                            </Grid> ||
                            <React.Fragment>
                                <Grid item xs={1} />
                                <Grid item xs={10} className="sub-text">
                                    {`From: ${props.model.startDispatchTime} to ${props.model.endDispatchTime}`}
                                </Grid>      
                                <Grid item xs={1} />
                                <Grid item xs={10} className="sub-text">
                                    {`Every: ${props.model.getRepeatWeekDays}`}
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs={10} className="sub-text">
                                    {`Starting from ${dateFormattedShort(props.model.startDate)} until ${dateFormattedShort(props.model.endDate)}`}
                                </Grid>
                            </React.Fragment>
                        }
                    </Hidden>
                    <Hidden smDown>
                        <Grid item xs={12} className="main-text">
                            {props.model.reason} {`(${dateFormattedShort(props.model.startDate)} - ${dateFormattedShort(props.model.endDate)})`}
                        </Grid>
                        {
                            (DayOfWeekFlags.None === props.model.repeatDayFlags) &&
                            <Grid item xs={10} className="sub-text">
                                {`From: ${dateFormattedShort(props.model.startDate)} ${props.model.startDispatchTime} to ${dateFormattedShort(props.model.endDate)} ${props.model.endDispatchTime}`}
                            </Grid> ||
                            <React.Fragment>
                                <Grid item xs={2} className="sub-text">
                                    {`From: ${props.model.startDispatchTime} to ${props.model.endDispatchTime}`}
                                </Grid>      
                                <Grid item xs={10} className="sub-text">
                                    {`Every: ${props.model.getRepeatWeekDays}`}
                                </Grid>
                            </React.Fragment>
                        }
                    </Hidden>
                </Grid>
            </Grid>
        </Grid>)
})

export default withStyles(globalStyles)(DriverUnavailabilityItem);
import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { observer } from 'mobx-react';
import './styles.scss';
import notification from './notification';

@observer
class Message extends React.Component {

    constructor(props: any) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    private handleClose() {
        notification.close();
    };

    public render() {
        const content = notification.message
            .split('\n')
            .map((line, i) => line.startsWith("*") 
                ? <React.Fragment>{line}<br key={i} /></React.Fragment> 
                : <p key={i}>{line}</p>);
        return (
            <div>
                <Snackbar
                    className={`notification ${notification.type}`}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={notification.active}
                    onClose={this.handleClose}
                    message={<div className="message-content">{content}</div>}
                    action={[
                        <IconButton
                            key="close"
                            color="inherit"
                            onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </div>
        );
    }
}

export default Message;
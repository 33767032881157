import { Model } from './model';
import { observable, runInAction } from 'mobx';
import { moreThanZero, todayOrInTheFuture } from '../../validate';
import { DateTime } from 'luxon';
import { IVehicleSummary } from './vehicle-summary';
import { SuggestionItem } from '../../components/Autocomplete/Autocomplete';

export class PreLoad extends Model {
    @observable public jobId: number = 0;
    @observable public deliveryDate: DateTime | null = null;
    @observable public preLoadDate: DateTime = DateTime.local().startOf('day').plus({ day: 1 });
    @observable public vehicleId: number = 0;
    @observable public vehicleRegistration: string = '';

    constructor(obj: Partial<PreLoad>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'jobId': (val: number) => {
            this.errors.jobId = moreThanZero(val);
            return this.errors.jobId === null;
        },
        'preLoadDate': (val: DateTime) => {
            this.errors.preLoadDate = todayOrInTheFuture(val);
            if (this.errors.preLoadDate === null) {
                this.errors.preLoadDate = val < this.deliveryDate! ? null : 'Must be before delivery date';
            }
            return this.errors.preLoadDate === null;
        },
        'vehicleId': (val: number) => {
            this.errors.vehicleId = val > 0 ? null : 'Must be a valid vehicle';
            return this.errors.vehicleId === null;
        }
    }

    public toVehicleSuggestion () : SuggestionItem<IVehicleSummary> {
        return { label: this.vehicleRegistration, value: {
            availableFromUtc: '',
            defaultDriverName: '',
            suburb: '',
            registration: this.vehicleRegistration,
            id: this.vehicleId,
            validAddress: true }};
    }
}
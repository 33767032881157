import * as React from 'react';
import './styles.scss';
import { observer, inject } from 'mobx-react';
import { UiStore } from '../../stores/ui-store';
import { Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

export interface IHeaderProps {
    title?: string;
    tooltip?: string;
    navAsTitleFullScreen?: boolean;
    uiStore?: UiStore;
}

@inject('uiStore')
@observer
export class Header extends React.Component<IHeaderProps> {
    constructor(props: IHeaderProps) {
        super(props);
        this.handleNavToggle = this.handleNavToggle.bind(this);
    }

    public render() {
        const { title, tooltip, navAsTitleFullScreen } = this.props;
        return <React.Fragment>
            { title && <Grid container className={`header${navAsTitleFullScreen ? ' hidden' : ''}`}>
                <Grid item xs={1} className="center">
                    <MenuIcon 
                        onClick={this.handleNavToggle} 
                        className="menu action" />
                </Grid>
                <Grid item xs={10}>
                    <h2 title={tooltip || ""}>{title}</h2>
                </Grid> 
            </Grid>}
        </React.Fragment>
    }

    public handleNavToggle() {
        this.props.uiStore!.setNavActive(true);
    }
}
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { WithStyles, withStyles, Grid, TextField } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { asForm, IAsFormProps } from '../Form/AsForm';
import { JobStore } from '../../stores/job-store';
import { JobItem } from '../../stores/domain/job-item';
import { UiStore } from '../../stores/ui-store';
import NumberField from '../NumberField/NumberField';
import { hasValue } from '../../validate';

interface IJobOrderItemProps extends WithStyles<typeof globalStyles> {
    jobStore?: JobStore
    uiStore?: UiStore
    model: JobItem
}

type Props = IJobOrderItemProps & IAsFormProps;

@inject('jobStore', 'uiStore')
@observer
class JobOrderItem extends React.Component<Props> {

    public render() {
        const { model } = this.props;
        const { jobFormState } = this.props.uiStore!;
        return <div className="job-order-item">
            <Grid container>
                <Grid xs={4} item>
                    <TextField
                        className={this.props.classes.textFieldFullWidth}
                        InputProps={{ disableUnderline: true }}
                        label={model.code}
                        value={model.description}
                        disabled />
                </Grid>
                <Grid xs={2} item>
                    <TextField
                        className={this.props.classes.textFieldFullWidth}
                        InputProps={{ disableUnderline: true }}
                        label={`Units ${hasValue(model.unit) ? `(${model.unit})` : ''}`}
                        value={model.quantity}
                        disabled />
                </Grid>
                <Grid xs={2} item>
                    {
                        model.requiresDemand &&
                        <NumberField
                            name="numberOfPallets"
                            InputProps={{ disableUnderline: true }}
                            InputLabelProps={{ shrink: true, error: model.missingDemand }}
                            value={model.numberOfPallets}
                            onChange={this.props.handleNumberChanged!}
                            error={model.errors.numberOfPallets}
                            textProps={{
                                className: this.props.classes.textFieldFullWidth,
                                label: 'Pallets',
                                disabled: jobFormState.readOnly
                            }}
                        />
                    }
                </Grid>
                <Grid xs={2} item>
                    {
                        model.requiresDemand &&
                        <NumberField
                            name="numberOfPacks"
                            InputProps={{ disableUnderline: true }}
                            InputLabelProps={{ shrink: true, error: model.missingDemand }}
                            value={model.numberOfPacks}
                            onChange={this.props.handleNumberChanged!}
                            error={model.errors.numberOfPacks}
                            textProps={{
                                className: this.props.classes.textFieldFullWidth,
                                label: 'Packs',
                                disabled: jobFormState.readOnly
                            }}
                        />
                    }
                </Grid>
                <Grid xs={2} item>
                    {
                        model.requiresDemand &&
                        <NumberField
                            name="weightInTonnes"
                            InputProps={{ disableUnderline: true }}
                            InputLabelProps={{ shrink: true, error: !model.weightInTonnes }}
                            value={model.weightInTonnes}
                            onChange={this.props.handleNumberChanged!}
                            error={model.errors.weightInTonnes}
                            textProps={{
                                className: this.props.classes.textFieldFullWidth,
                                label: 'Tonnes',
                                disabled: jobFormState.readOnly
                            }}
                        />
                    }
                </Grid>

            </Grid>
        </div>
    }
}

export default (asForm(withStyles(globalStyles)(JobOrderItem)));
import * as React from 'react';
import { Grid } from '@material-ui/core';
import './styles.scss';

interface INoRecordsProps {
    active: boolean;
    message?: string;
}

export const NoRecords: React.SFC<INoRecordsProps> = (props) => {
    const message = props.message
        ? props.message
        : 'No more records';

    return <React.Fragment>
    {   
        props.active
        && <Grid container className="no-records" alignContent="center" alignItems="center">
            <Grid item xs={1} />
            <Grid item xs={10} className="message">{message}</Grid>
        </Grid>
    }
    </React.Fragment>
};

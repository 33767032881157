import { DateTime, Duration } from 'luxon';
import { computed, observable, runInAction } from 'mobx';
import { toDate } from '../../datetime';
import { ITaskResponse } from '../../services/schedule-api';
import { Job, JobStatus, JobType } from './job';
import { Model } from './model';

export class Task extends Model {
    @observable public addressText: string = '';
    @observable public capacityProportionChange: number = 0;
    @observable public completeDateTime: DateTime | undefined = undefined;
    @observable public distanceInKm: number = 0;
    @observable public etaDispatchTime: DateTime | undefined = undefined;
    @observable public etdDispatchTime: DateTime | undefined = undefined;
    @observable public etaUtc: DateTime | undefined = undefined;
    @observable public etdUtc: DateTime | undefined = undefined;
    @observable public guid: string = '';
    @observable public id: number = 0;
    @observable public job: Job | undefined = undefined;
    @observable public loadChanged: boolean = false;
    @observable public onTime: OnTime = OnTime.None;
    @observable public packChange: number = 0;
    @observable public packLoad: number = 0;
    @observable public palletChange: number = 0;
    @observable public palletLoad: number = 0;
    @observable public remainingPalletCapacity: number = 0;
    @observable public sequence: number = 0;
    @observable public serviceTimeInMinutes: number = 0;
    @observable public serviceWindowOffsetDuration: Duration = Duration.fromISO('PT0S');
    @observable public status: TaskStatus = TaskStatus.None;
    @observable public timeOnVehicleInMinutes: number = 0;
    @observable public travelTimeInMinutes: number = 0;
    @observable public type: TaskType = TaskType.None;
    @observable public usedCapacityProportion: number = 0;

    constructor(obj: Partial<Task>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    public static fromResponse(r: ITaskResponse) {
        const task = new Task({
            ...r,
            job: r.job && Job.fromResponse(r.job),
            etaDispatchTime: toDate(r.etaDispatchTime),
            etaUtc: toDate(r.etaUtc),
            etdDispatchTime: toDate(r.etdDispatchTime),
            completeDateTime: r.completedDispatchTime ? toDate(r.completedDispatchTime) : undefined,
            etdUtc: toDate(r.etdUtc),
            serviceWindowOffsetDuration: Duration.fromISO(r.serviceWindowOffsetDuration)
        });
        return task;
    }

    public validate(): boolean {
        const validation = [
            super.validate()
        ];
        return validation.every(v => v);
    }

    @computed
    public get canCompleteManually(): boolean {
        return this.status === TaskStatus.OnRoute ||
            this.status === TaskStatus.Incomplete
    }
    
    @computed get capacityPercent(): string {
        return `${(this.usedLoadProportion * 100).toFixed(0)} %`;
    }

    @computed get isOverCapacity(): boolean {
        return this.usedLoadProportion > 1;
    }
    
    @computed get loadText(): string {
        return `${this.palletLoad} PLT ${Math.round(this.packLoad * 10) / 10} PK`;
    }

    @computed
    public get onTimeText(): string {
        switch (this.onTime) {
            case OnTime.Late:
                return 'Late';
            case OnTime.Early:
                return 'Early';
            case OnTime.OnTime:
                return 'On Time';
            case OnTime.None:
                return '';
        }

        return 'Invalid';
    }
    
    @computed
    get serviceWindowOffsetString(): string {
        let text = '';
        const minutes = this.serviceWindowOffsetDuration.minutes;
        const hours = this.serviceWindowOffsetDuration.hours;
        if (hours > 0) {
            text = `${hours} hour${hours > 1 ? 's' : ''} `;
        }
        return `${text}${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    
    @computed
    public get serviceWindowOffsetText(): string {
        if (this.onTime === OnTime.OnTime) return '';
        if (this.onTime === OnTime.Late) return `Running late by ${this.serviceWindowOffsetString}`;
        return `Running early by ${this.serviceWindowOffsetString}`
    }

    @computed get suburbText() {
        return (this.type === TaskType.Pickup && this.job) ? this.job!.deliveryAddress.suburb : '';
    }

    @computed
    public get statusText() {
        switch (this.status) {
            case TaskStatus.None:
                return '';
            case TaskStatus.Incomplete:
                return 'Incomplete';
            case TaskStatus.OnRoute:
                return 'On-route';
            case TaskStatus.Complete:
                return 'Complete';
            case TaskStatus.Cancelled:
                return 'Cancelled';
            case TaskStatus.OrderPendingApproval:
                return 'Pending Approval';
        }

        return 'Invalid';
    }

    @computed
    public get statusTooltipText() {
        switch (this.status) {
            case TaskStatus.OrderPendingApproval:
                return 'The Sales Order is pending approval';
            case TaskStatus.DateChanged:
                return 'Date changed, task needs to be removed from schedule';
            case TaskStatus.JobUpdated:
                return 'Job has been updated, task needs to be removed from schedule';
            case TaskStatus.Cancelled:
                return this.job && this.job.type === JobType.ReturnToAbcForRedelivery ?
                                                     'Job is rescheduled and items will be returned to ABC for redelivery' :
                                                     'Task is cancelled, task needs to be removed from schedule';
            case TaskStatus.Deleted:
                return 'Job is deleted, task needs to be removed from schedule';
        }

        if (this.loadChanged) {
            return 'Load changed';
        }

        return '';
    }

    @computed
    public get taskTypeText() {
        switch (this.type) {
            case TaskType.None:
                return 'None'
            case TaskType.Pickup:
                return 'Pickup'
            case TaskType.Delivery:
                return 'Delivery'
        }

        return 'None'
    }

    @computed
    public get isJobLive() {
        return this.job!.status >= JobStatus.Scheduled;
    }
}

export enum OnTime {
    None = 0,
    Early = 1,
    OnTime = 2,
    Late = 3
}

export enum TaskStatus {
    None = 0,
    Incomplete = 1,
    OnRoute = 2,
    Complete = 3,
    DateChanged = 4,
    Deleted = 5,
    Cancelled = 6,
    OrderPendingApproval = 7,
    JobUpdated = 8
}

export enum TaskType {
    None = 0,
    Pickup = 1,
    Delivery = 2,
    Start = 3,
    End = 4
}
import * as React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles, Grid, Hidden, TextField } from '@material-ui/core';
import { Unavailability } from '../../stores/domain/unavailability';
import { globalStyles } from '../../theme';
import { dateFormattedShort, timeFormatted, dateTimeFormattedShort } from '../../datetime';
import PopupMenu from '../PopupMenu/PopupMenu';

interface IUnavailabilityItemProps extends WithStyles<typeof globalStyles> {
    onOpenDialog(): void
    onDeleteUnavailability(model: Unavailability): void
    model: Unavailability
    readOnly?: boolean
}
const UnavailabilityItem: React.SFC<IUnavailabilityItemProps> = observer((props) => {

    return (
        <Grid container className="unavailability">
            <Grid item xs={1} className="more">
                {
                    !props.readOnly
                    && <PopupMenu items={[
                        {name: 'Edit', onClick: props.onOpenDialog},
                        {name: 'Delete', onClick: () => props.onDeleteUnavailability(props.model)}
                    ]} />
                }
            </Grid>
            <Hidden mdUp>
                <Grid item xs={10} className="main-text">{props.model.reason}</Grid>
                <Grid item xs={1} />
                <Grid item xs={1} />
                <Grid item xs={10} className="sub-text">
                    <b>{timeFormatted(props.model.startDispatchTime)}</b> {dateFormattedShort(props.model.startDispatchTime)}
                    &nbsp; to &nbsp;
                    <b>{timeFormatted(props.model.endDispatchTime)}</b> {dateFormattedShort(props.model.endDispatchTime)}
                </Grid>
            </Hidden>
            <Hidden smDown>
                <Grid item xs={4}>
                    <TextField
                        label="Reason"
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        value={props.model.reason}
                        disabled />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Start"
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        value={dateTimeFormattedShort(props.model.startDispatchTime)}
                        disabled />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="End"
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        value={dateTimeFormattedShort(props.model.endDispatchTime)}
                        disabled />
                </Grid>
            </Hidden>
        </Grid>)
})

export default withStyles(globalStyles)(UnavailabilityItem);
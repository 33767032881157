import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';
import { VehicleStore } from '../../stores/vehicle-store';
import Grid from '@material-ui/core/Grid';
import { hasValue } from '../../validate';
import { NoRecords } from '../NoRecords/NoRecords';
import { IVehicleSummary } from '../../stores/domain/vehicle-summary';
import LocationCity from '@material-ui/icons/LocationCity';
import { IconTooltip } from '../IconTooltip/IconTooltip';
import { utcToLocal } from '../../datetime';

interface IVehiclesListProps {
    vehicleStore?: VehicleStore
}

type Props = IVehiclesListProps & RouteComponentProps<{}>;

const VehiclesList: React.SFC<Props> = observer((props: Props) => {

    const getAvailabilityText = (v: IVehicleSummary) => {
        if (!hasValue(v.availableFromUtc)) return '';

        const availableFrom = utcToLocal(v.availableFromUtc);
        
        return `Next available from: ${availableFrom.toFormat('dd/LL/yyyy')} at ${availableFrom.toFormat('hh:mm a')}`;
    }

    const getIcons = (j: IVehicleSummary) => {
        return (<React.Fragment>
           {
                !j.validAddress
                && <IconTooltip title={`Location is missing key information from Google. Please ensure the location is correct and searchable in Google.`}>
                    {{ icon: <LocationCity /> }}
                </IconTooltip>
            }
        </React.Fragment>)
    };

    const getVehicleText = (v: IVehicleSummary) => {
        return `${v.registration} (${hasValue(v.defaultDriverName) ? v.defaultDriverName : 'NO DEFAULT DRIVER'}: ${v.suburb})`
    }

    return <div className="vehicles-list page">
        {
            props.vehicleStore!.vehicles.map((v, i) => (
            <Grid container 
                key={i} 
                onClick={() => props.history.push(`view-vehicle/${v.id}`)}
                className="vehicle">
                <Grid item xs={1} />
                <Grid item xs={10} className="main-text">{getVehicleText(v)}</Grid>
                <Grid item xs={1} > {getIcons(v)}</Grid>
                <Grid item xs={1} />
                <Grid item xs={10} className="sub-text">{getAvailabilityText(v)}</Grid>
            </Grid>))
        }
        <NoRecords message="No more vehicles" active />
    </div>
});

export default VehiclesList;
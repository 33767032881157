export class Picture {
    public src: string = '';
    public id: string = '';

    constructor(src : string, id: string){
        this.src = src;
        this.id = id;
    }
}

export class Signature extends Picture {
    public signatory: string;

    constructor(src : string, id: string, signatory : string){
        super(src,id);
        this.signatory = signatory;
    }
}
import * as React from 'react';
import { UiStore } from '../../stores/ui-store';
import { observer, inject } from 'mobx-react';
import './styles.scss'
import { observable, action, runInAction } from 'mobx';
import { Link, RouteComponentProps } from 'react-router-dom';
import { authContext } from '../../adalConfig';
import { build } from '../../build'

interface INavProps {
    uiStore?: UiStore;
}

type Props = INavProps & RouteComponentProps<{}>;

@inject('uiStore')
@observer
export class Nav extends React.Component<Props> {

    private links: Array<{ name: string, route: string }> = [
        { name: 'Schedule', route: '/schedule' },
        { name: 'Jobs', route: '/' },
        { name: 'Vehicles', route: '/vehicles' },
        { name: 'Drivers', route: '/drivers' }
    ];

    @observable private activeRoute: string = '';

    constructor(props: Props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        runInAction(() => this.activeRoute = this.props.location.pathname);
    }

    public render() {
        return (
            <div className={`lg-nav${this.props.uiStore!.navActive ? ' active' : ''}`} 
                onClick={this.handleClose}>
                <div className="inner">
                    <h2>ASAP</h2>
                    <ul>
                        {
                            this.links
                                .map(l => {
                                    return <li
                                        key={l.name}
                                        className={this.activeRoute === l.route ? 'active' : ''}
                                        onClick={() => { this.handleSelection(l.route) }}>
                                        <Link to={l.route}>{l.name}</Link>
                                    </li>
                                })
                        }
                        <li onClick={() => authContext.logOut()}>
                            <Link to="">
                                Logout
                                {/* <div className="username">
                                    {this.props.authStore!.userName}
                                </div> */}
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="logo-container">
                    <img src={process.env.PUBLIC_URL + '/logo.png'} alt="ABC Building Products" />
                    <p className="nav-footer">
                        Asap v{build.version}
                    </p>
                </div>
            </div>
        );
    }

    public handleClose() {
        this.props.uiStore!.setNavActive(false);
    }

    @action
    public handleSelection(link: string) {
        this.activeRoute = link;
    }
}

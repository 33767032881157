import React  from 'react';
import { Route, RouteProps } from 'react-router';
import Unauthorised from './Unauthorised/Unauthorised';
import { authContext } from '../adalConfig';

export const ProtectedRoute: React.SFC<RouteProps> = (props: RouteProps) => {
    const isAuthorised = () => {
        const dispatch = window.config.groups.find(g => g.name === 'Dispatch');
        const user = authContext.getCachedUser();

        return dispatch && user && user.profile && user.profile.groups
            && user.profile.groups.includes(dispatch.id)
    }
    
    return isAuthorised()
        ? <Route {...props} />
        : <Unauthorised />
};


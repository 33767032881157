import React from 'react';
import { DateTime } from 'luxon';

interface IState {
    date: DateTime;
}
interface ITaskListProps {
    key?: number
}
class Clock extends React.Component<ITaskListProps, IState> {
    timerID!: NodeJS.Timeout;
    constructor(props: ITaskListProps) {
        super(props);
        this.state = { date: DateTime.local() };
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: DateTime.local() 
        });
    }

    render() {
        return (
            <div key='0'>
                {this.state.date.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
            </div>
        );
    }       
}

export default Clock;
 
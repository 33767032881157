import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { asForm, IAsFormProps } from '../Form/AsForm';
import { JobStore } from '../../stores/job-store';
import { UiStore } from '../../stores/ui-store';
import Carousel, { Modal, ModalGateway } from 'react-images';

interface ISignatureFieldProps extends WithStyles<typeof globalStyles> {
    jobStore?: JobStore
    uiStore?: UiStore
}

type Props = ISignatureFieldProps & IAsFormProps;

@inject('jobStore', 'uiStore')
@observer
class SignatureField extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.toggleSignatureViewer = this.toggleSignatureViewer.bind(this);
    }

    public render() {
        const { jobFormState } = this.props.uiStore!;
        const { signature } = this.props.jobStore!.job;
        return (
            <div>
                <p className="photoTitle">
                    Signature
                </p>
                {Boolean(signature)
                    &&
                    <React.Fragment>
                        <div
                            className="signatureContainer"
                            onClick={() => this.toggleSignatureViewer()} key={signature!.src}>
                            <img
                                src={signature!.src}
                                className="signature"
                            />
                        </div>

                        <ModalGateway>
                            {jobFormState.signatureViewerOpen ? (
                                <Modal onClose={this.toggleSignatureViewer}
                                    closeOnBackdropClick={true}
                                    allowFullscreen={false}
                                    styles={{
                                        blanket: (base: any) => ({
                                            ...base,
                                            backgroundColor: 'rgba(255,255,255,0.8)',
                                        }),
                                        dialog: (base: any) => ({
                                            ...base,
                                            maxWidth: '50%',
                                        })
                                    }}>
                                    <Carousel
                                        currentIndex={jobFormState.currentImageIndex}
                                        styles={{
                                            footer: (base: any) => ({
                                                ...base,
                                                background: 'none !important',
                                                color: 'gray',
                                                padding: 0,
                                                paddingTop: 20,
                                                position: 'static',
        
                                                '& a': {
                                                    color: 'black',
                                                },
                                            }),
                                            header: (base: any) => ({
                                                ...base,
                                                background: 'none !important',
                                                padding: 0,
                                                paddingBottom: 10,
                                                position: 'static',
                                            }),
                                            headerClose: (base: any) => ({
                                                ...base,
                                                color: 'gray',
        
                                                ':hover': { color: 'red' },
                                            }),
                                            view: (base: any) => ({
                                                ...base,
                                                backgroundColor: '#F4F5F7',
                                                alignItems: 'center',
                                                display: 'flex ',
                                                height: 'calc(80vh - 150px)',
                                                justifyContent: 'center',
                                                '& > img': {
                                                    maxHeight: 'calc(80vh - 120px)',
                                                },
                                            }),
                                        }}
                                        views={[{
                                            ...signature,
                                            srcset: signature!.src,
                                            caption: signature ? 'Signed by: ' + signature!.signatory + '. ' : '' 
                                        }]
                                        }
                                    />
                                </Modal>
                            ) : null}
                        </ModalGateway>
                    </React.Fragment>
                }
            </div>


        )

    }

    public toggleSignatureViewer() {
        this.props.uiStore!.jobFormState.toggleSignatureViewer();
    }
}

export default (asForm(withStyles(globalStyles)(SignatureField)));
import { DateTime } from 'luxon';
import { action, observable, runInAction } from 'mobx';
import { SuggestionItem } from '../components/Autocomplete/Autocomplete';
import notification from '../components/Notification/notification';
import scheduleTimerNotification from '../components/ScheduleTimerNotification/schedule-timer-notification';
import { DriversApi } from '../services/drivers-api';
import { IScheduleResponse, ScheduleApi } from '../services/schedule-api';
import { VehiclesApi } from '../services/vehicles-api';
import { JobsApi } from '../services/jobs-api';
import { UnscheduledDelay } from './domain//unscheduled-delay';
import { DriverAssignment } from './domain/driver-assignment';
import { IDriverSummary } from './domain/driver-summary';
import { DriverTransfer } from './domain/driver-transfer';
import { IJobChangeSummary } from './domain/job-change-summary';
import { Schedule } from './domain/schedule';
import { ScheduleAvailability } from './domain/schedule-availability';
import { ScheduleToRoute } from './domain/schedule-to-route';
import { Task, TaskType } from './domain/task';
import { UnassignedJobs } from './domain/unassigned-jobs';
import { VehicleRoute } from './domain/vechicle-route';
import { TruckType } from './domain/vehicle';

export class ScheduleStore {
    constructor(private driversApi: DriversApi, private scheduleApi: ScheduleApi, private vehiclesApi: VehiclesApi, private jobsApi: JobsApi) { }

    @observable public jobChanges: IJobChangeSummary[] = [];
    @observable public currentSchedule: Schedule = new Schedule({});
    @observable public currentDraftSchedule: Schedule = new Schedule({});
    @observable public availableVehicles: ScheduleAvailability[] = [];
    @observable public isGenerating: boolean = false;

    @action
    public async addUnscheduledDelay(unscheduledDelay: UnscheduledDelay): Promise<boolean> {
        const ok = await this.scheduleApi.addUnscheduledDelay(unscheduledDelay);
        if (ok) {
            await this.loadSchedules(this.currentSchedule.date!);
        }
        return ok;
    }

    @action
    public async assignDriver(driverAssignment: DriverAssignment) {
        const ok = await this.scheduleApi.assignDriver(driverAssignment);
        notification.showInfo('Assigning driver')
        if (ok) {
            await this.loadSchedules(this.currentSchedule.date!);
        }
        return ok;
    }

    @action
    public async discardDraft() {
        const ok = await this.scheduleApi.discardDraft(this.currentDraftSchedule.runId);
        if (ok) {
            await this.loadSchedules(this.currentSchedule.date!);
        }
        return ok;
    }

    @action
    public async downloadDraftScheduleCsv(id: number): Promise<File> {
        return this.scheduleApi.downloadDraftScheduleCsv(id);
    }

    @action
    public async downloadLiveScheduleCsv(id: number): Promise<File> {
        return this.scheduleApi.downloadLiveScheduleCsv(id);
    }

    @action
    public async downloadLoadingSheetZip(routeId: number): Promise<File> {
        return this.scheduleApi.downloadLoadinSheetZip(routeId);
    }

    @action
    public async lockRoute(routeGuid: string): Promise<boolean> {
        const route = this.currentDraftSchedule.vehicleRoutes.find(r => r.guid === routeGuid);
        if (!route) return false;

        const ok = await this.scheduleApi.lockRoute(this.currentDraftSchedule.runId, routeGuid);

        runInAction(() => route.isLocked = ok);

        return ok;
    }

    @action
    public async transferDriver(driverTransfer: DriverTransfer) {
        const ok = await this.scheduleApi.transferDriver(driverTransfer);
        notification.showInfo('Transferring driver')
        if (ok) {
            await this.loadSchedules(this.currentSchedule.date!);
        }
        notification.close();
        return ok;
    }

    @action
    public async sendCreateScheduleRequest(date: DateTime, runTimeoutInSeconds: number) {
        await this.scheduleApi.sendCreateScheduleRequest(date, runTimeoutInSeconds);
        runInAction(() => {
            this.isGenerating = true;
        });
        notification.showInfo('Create Schedule Request Sent');
        return;
    }

    @action
    public async saveUnassignedJobs(routeChange: UnassignedJobs, directRemove: boolean): Promise<boolean> {
        const ok = await this.scheduleApi.saveUnassignedJobs(routeChange, directRemove);
        if (!directRemove) {
            notification.showInfo('Recalculating vehicle route')
        }
        return ok;
    }

    @action
    public async scheduleJobToRoute(scheduleToRoute: ScheduleToRoute) {
        notification.showInfo('Scheduling job to route, please stand by');
        const ok = await this.scheduleApi.scheduleJobToRoute(scheduleToRoute);
        if (ok) {
            await this.loadSchedules(this.currentSchedule.date!);
        }
        return ok;
    }

    @action
    public async unlockRoute(routeGuid: string): Promise<boolean> {
        const route = this.currentDraftSchedule.vehicleRoutes.find(r => r.guid === routeGuid);
        if (!route) return false;

        const ok = await this.scheduleApi.unlockRoute(this.currentDraftSchedule.runId, routeGuid);
        runInAction(() => route.isLocked = !ok);

        return ok;
    }

    @action
    public async closeNotifications() {
        notification.close();
        scheduleTimerNotification.close();
    }

    @action
    public async push(schedule: Schedule) {
        if (!schedule.validate) {
            notification.showError('Invalid schedule')
        }
        const result = await this.scheduleApi.push(schedule)
        if (result.success) {
            await this.loadSchedules(schedule.date!)
        } else {
            if (result.errors.length > 0) {
                notification.showError(result.errors.join('\n'))
            }
        }
    }

    @action
    public async loadSchedules(date: DateTime) {
        const currentSchedule = await this.scheduleApi.getAllSchedulesForDate(date);

        if (currentSchedule) {
            runInAction(() => {
                this.availableVehicles = currentSchedule.scheduleAvailabilities.map(r => ScheduleAvailability.fromResponse(r));
                this.isGenerating = currentSchedule.isGenerating;
                this.jobChanges = currentSchedule.jobChanges;
                if (currentSchedule!.schedule) {
                    this.currentSchedule = Schedule.fromResponse(currentSchedule!.schedule as IScheduleResponse);
                } else {
                    this.currentSchedule = new Schedule({ date });
                }
                if (currentSchedule!.draftSchedule) {
                    this.currentDraftSchedule = Schedule.fromResponse(currentSchedule!.draftSchedule as IScheduleResponse);
                } else {
                    this.currentDraftSchedule = new Schedule({ date });
                }
            });
        }
        return;
    }

    public async completeDeliveryTask(task: Task) {
        if (task.type !== TaskType.Delivery) return;

        const ok = await this.scheduleApi.completeDeliveryTask(task);
        if (ok) {
            await this.loadSchedules(this.currentSchedule.date!);
            notification.showInfo('Delivery Task completed');
        }
        return ok;
    }

    public async completePickupTask(task: Task) {
        if (task.type !== TaskType.Pickup) return;

        const ok = await this.scheduleApi.completePickupTask(task);
        if (ok) {
            await this.loadSchedules(this.currentSchedule.date!);
            notification.showInfo('Pickup Task completed');
        }
        return ok;
    }

    public async getAvailableRoutes(jobId: number) {
        const suggestions = await this.scheduleApi.getAvailableRoutes(jobId);
        return suggestions.map(r => ScheduleAvailability.fromResponse(r));
    }
    
    @action
    public async lockToVehicle(jobId: number, vehicleId: number | null) {
        const ok = await this.jobsApi.lockToVehicle(jobId, vehicleId);
        if (ok) {
            await this.loadSchedules(this.currentSchedule.date!);
        }
        return ok;
    }

    @action
    public async recalculateVehicleRoute(runId: string, vehicleRouteGuid: string, indexes: number[]) {
        const response = await this.scheduleApi.recalculateVehicleRoute(runId, vehicleRouteGuid, indexes);
        const updatedRoute = VehicleRoute.fromResponse(response!);

        const indexToReplace = this.currentDraftSchedule.vehicleRoutes.findIndex(vr => vr.guid === updatedRoute.guid);

        runInAction(() => this.currentDraftSchedule.vehicleRoutes.splice(indexToReplace, 1, updatedRoute));
    }

    @action
    public async saveVehicleRoute(vehicleRouteGuid: string) {
        const scheduleDate = this.currentDraftSchedule.date;
        const runId = this.currentDraftSchedule.runId;
        const vehicleRoute = this.currentDraftSchedule.vehicleRoutes.find(vr => vr.guid === vehicleRouteGuid);
        const ordering = vehicleRoute!.tasks.map(vr => vr.sequence);
        const ok = await this.scheduleApi.saveVehicleRoute(runId, vehicleRouteGuid, ordering);
        if (ok) {
            this.loadSchedules(scheduleDate!);
            notification.showInfo('Vehicle route updated');
        }
        return ok;
    }

    public async searchActiveDrivers(query: string) {
        const suggestions = await this.driversApi.searchDrivers(query, TruckType.None);
        return suggestions.map(s => {
            return { label: s.name, value: { ...s, currentUnavailability: [] } } as SuggestionItem<IDriverSummary>
        })
    }

    public async addVehicleAvailability(availability: ScheduleAvailability) {
        const ok = await this.vehiclesApi.addVehicleAvailability(availability);
        if (ok) {
            this.reloadVehicleAvailabilities();
            notification.showInfo('Added vehicle availability');
        }
        return ok;
    }

    private async reloadVehicleAvailabilities() {
        const availableVehicles = await this.vehiclesApi.getVehicleAvailabilities(this.currentDraftSchedule.id!);
        runInAction(() => {
            this.availableVehicles = availableVehicles.map(r => ScheduleAvailability.fromResponse(r));
        });
    }

    public async deleteVehicleAvailability(availability: ScheduleAvailability) {
        const ok = await this.vehiclesApi.deleteVehicleAvailability(availability.id);
        if (ok) {
            this.reloadVehicleAvailabilities();
            notification.showInfo('Deleted vehicle availability');
        }
        return ok;
    }
}
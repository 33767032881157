import * as React from 'react';
import './styles.scss';
import { observer, inject } from 'mobx-react';
import { ContextItem } from './ContextItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { observable, action } from 'mobx';

export interface IContextMenuProps {
    onHide?: () => void
    onShow?: () => void
    title: string
}
  
@inject('uiStore')
@observer
export class ContextMenu extends React.Component<IContextMenuProps, {}> {
    
    private actionHeight: number = 38;
    @observable private enabled: boolean = false;

    constructor(props: IContextMenuProps) {
        super(props);
        this.handleHide = this.handleHide.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    @action
    public handleHide(): void {
        if (this.props.onHide) {
            this.props.onHide();
        }
        this.enabled = false;
    }

    @action
    public handleShow(): void {
        if (this.props.onShow) {
            this.props.onShow();
        }
        this.enabled = true;
    }

    public render() {
        return(
            <span className={this.enabled ? 'context-menu-host' : ''}>
                <MoreVertIcon
                    className="action"
                    onClick={this.handleShow} />
                {this.enabled && <div className="context-menu"
                    onMouseLeave={this.handleHide}>
                    <div className="context-actions"
                        style={{height: this.contextContainerHeight}}>
                        <h1>{this.props.title}</h1>
                        <div className="items">
                            <ul>
                                { this.props.children }
                            </ul>
                        </div>
                    </div>
                </div>}
            </span>
        );
    }

    public componentWillUnmount() {
        this.handleHide();
    }

    private get numOfContextActionItem() {
        return React.Children.map((this.props.children as React.ReactElement), (child: React.ReactElement<any>) => {
            return (child && child.type === ContextItem) ? 1 : 0;
        }).reduce((acc: number, curr: number) => acc + curr, 0);
    }

    private get contextContainerHeight() {
        const paddingHeight = 40;
        return this.numOfContextActionItem * this.actionHeight + paddingHeight;
    }
}
import { Model } from './model';
import { observable, runInAction } from 'mobx';
import { moreThanZero, todayOrInTheFuture } from '../../validate';
import { DateTime } from 'luxon';
import { ILocationSummary } from './location-summary';
import { SuggestionItem } from '../../components/Autocomplete/Autocomplete';

export class TransShip extends Model {
    @observable public jobId: number = 0;
    @observable public transShipLocationId: number = 0;
    @observable public transShipLocationName: string = '';
    @observable public deliveryDate: DateTime = DateTime.local().startOf('day').plus({ day: 1 });

    constructor(obj: Partial<TransShip>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'transShipLocationId': (val: number) => {
            this.errors.transShipLocationId = val > 0 ? null : 'Must be a valid trans-ship location';
            return this.errors.transShipLocationId === null;
        },
        'deliveryDate': (val: DateTime) => {
            this.errors.deliveryDate = todayOrInTheFuture(val);
            return this.errors.deliveryDate === null;
        },
        'jobId': (val: number) => {
            this.errors.jobId = moreThanZero(val);
            return this.errors.jobId === null;
        }
    }

    public toLocationSuggestion () : SuggestionItem<ILocationSummary> {
        return { label: this.transShipLocationName, value: { name: this.transShipLocationName, id: this.transShipLocationId }};
    }
}
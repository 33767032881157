import { observable, action } from 'mobx'
import { TransShip } from '../domain/trans-ship';
import { DateTime } from 'luxon';

export class TransShipDialogState {
    @observable public maxDate: DateTime = DateTime.local().startOf('day').minus({day: 1});
    @observable public transShip? : TransShip;
    
    @action
    public init(jobId: number, deliveryDate: DateTime) {
        const maxDate = deliveryDate;
        this.maxDate = maxDate;
        this.transShip = new TransShip({jobId, deliveryDate: maxDate});
    }
    
    @action
    public reset() {
        this.transShip = undefined;
    }
}
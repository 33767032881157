import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const abcOrange = '#EA592C';

export const theme = createMuiTheme({
        overrides: {
            MuiCheckbox: {
                colorSecondary: {
                    color: abcOrange,
                    '&$checked': {
                        color: abcOrange,
                    },
                },
            },
        },
        typography: {
            useNextVariants: true,
        }
    });

export const globalStyles = {
    textField: {
        marginBottom: 30,
        width: '315px',
        display: 'block'
    },
    inlineDateField: {
        width: '245px',
        marginBottom: 30
    },
    numberFieldWidth: {
        width: '64px'
    },
    textFieldFullWidth: {
        width: '100%',
        marginBottom: 30
    },
    highlightText: {
        color: abcOrange
    },
    radio: {
        '&$checked': {
          color: abcOrange
        }
    },
    checked: {

    }
};
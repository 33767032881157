import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';

interface IState {
    anchorEl: React.ReactNode;
}

interface IPopupMenuProps {
    items: Array<{ name: string, onClick: () => void }>
}

class PopupMenu extends React.Component<IPopupMenuProps, IState> {

    constructor(props: IPopupMenuProps) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    public state = {
        anchorEl: null,
    };

    private handleClick(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
        this.setState({ anchorEl: event.target });
    };

    private handleClose() {
        this.setState({ anchorEl: null });
    };

    public render() {
        const { anchorEl } = this.state;
        return (
            <div>
                <MoreVert onClick={this.handleClick} className="action" />
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}>
                    {
                        this.props.items.map(i => (<MenuItem key={i.name} onClick={() => {
                            this.handleClose();
                            i.onClick();
                        }}>{i.name}</MenuItem>))
                    }
                </Menu>
            </div>
        );
    }
}

export default PopupMenu;
import * as React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles, TextField } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { Unavailability } from '../../stores/domain/unavailability';
import Dialog from '../Dialog/Dialog';
import { CloseButton, SaveButton } from '../Dialog/Buttons/Buttons';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { InlineDateTimePicker } from 'material-ui-pickers';
import { DateTime } from 'luxon';
import { dateTimeFormattedShort } from '../../datetime';
import LuxonUtils from '@date-io/luxon';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { IAsFormProps, asForm } from '../Form/AsForm';
import AddressSearchBox from '../AddressSearch/AddressSearchBox';
import { Address } from '../../stores/domain/address';

interface IUnavailablityDialogProps extends WithStyles<typeof globalStyles> {
    model: Unavailability
    active: boolean
    readOnly?: boolean
    onSave: (model: Unavailability) => void
    onClose: () => void
}

type Props = IUnavailablityDialogProps & IAsFormProps;

@observer
class UnavailabilityDialog extends React.Component<Props> {

    public render() {
        return <Dialog
            title={`${!this.props.model.id ? 'Add' : 'Edit'} Unavailability`}
            type="slide"
            active={this.props.active}
            onClose={this.props.onClose}>
            {{
                content: <React.Fragment>
                    <TextField
                        className={this.props.classes.textFieldFullWidth}
                        InputProps={{ disableUnderline: true }}
                        label="Reason"
                        value={this.props.model.reason}
                        disabled={this.props.readOnly}
                        onChange={this.props.handleChange}
                        name="reason"
                        error={!!this.props.model.errors.reason}
                        helperText={this.props.model.errors.reason || null}
                        autoFocus
                    />
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <InlineDateTimePicker
                            className={this.props.classes.inlineDateField}
                            label="Start"
                            value={this.props.model.startDispatchTime}
                            onChange={(date: DateTime) => this.props.handleSetProperty!('startDispatchTime', date)}
                            labelFunc={(date: DateTime) => dateTimeFormattedShort(date)}
                            InputProps={{ disableUnderline: true }}
                            leftArrowIcon={<KeyboardArrowLeft />}
                            rightArrowIcon={<KeyboardArrowRight />}
                            error={!!this.props.model.errors.startDispatchTime}
                            helperText={this.props.model.errors.startDispatchTime || null}
                            disabled={this.props.readOnly}
                            disablePast
                            showTabs={false}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <InlineDateTimePicker
                            className={this.props.classes.inlineDateField}
                            label="End"
                            value={this.props.model.endDispatchTime}
                            onChange={(date: DateTime) => this.props.handleSetProperty!('endDispatchTime', date)}
                            labelFunc={(date: DateTime) => dateTimeFormattedShort(date)}
                            InputProps={{ disableUnderline: true }}
                            leftArrowIcon={<KeyboardArrowLeft />}
                            rightArrowIcon={<KeyboardArrowRight />}
                            error={!!this.props.model.errors.endDispatchTime}
                            helperText={this.props.model.errors.endDispatchTime || null}
                            disabled={this.props.readOnly}
                            disablePast
                            showTabs={false}
                        />
                    </MuiPickersUtilsProvider>
                    <AddressSearchBox
                            className={this.props.classes.textFieldFullWidth}
                            address={this.props.model.address.formattedAddress}
                            handleAddressSelected={(address: Address) => this.handleAddressSet!(address)}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleAddressSet(new Address(
                                { ...this.props.model.address, formattedAddress: e.currentTarget.value })
                            )}
                            label="Resuming from"
                            fieldName="address"
                            error={this.props.model.address.errors && this.props.model.address.errors.formattedAddress ? this.props.model.address.errors.formattedAddress : null}
                            disabled={this.props.model.readOnly}
                        />
                </React.Fragment>,
                actions: <React.Fragment>
                    <CloseButton onClick={this.props.onClose} />
                    <SaveButton onClick={() => {
                        if (!this.props.model.validate()) return;
                        this.props.onSave(this.props.model);
                    }} />
                </React.Fragment>
            }}
        </Dialog>
    }

    public handleAddressSet(value: any) {
        if (!this.props.model) return;
        this.props.model.updateAndValidate('address', value);
    }
}

export default (asForm(withStyles(globalStyles)(UnavailabilityDialog)));
import * as React from 'react';
import './styles.scss';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Loader } from '../Loader/Loader';

interface IDialogProps {
    active: boolean
    title: string
    className?: string
    type: 'slide' | 'full-screen'
    loaderAreaName?: string
    onClose(): void
    onOpen?(): void
    children: {
        content: React.ReactNode,
        actions: React.ReactNode
    };
}

@observer
class Dialog extends React.Component<IDialogProps> {
    constructor(props: IDialogProps) {
        super(props);
    }

    public componentDidUpdate(prevProps: IDialogProps) {
        if (prevProps.active === this.props.active) return;
        if (this.props.active && this.props.onOpen)
            this.props.onOpen();
    }

    public render() {
        return this.props.active
            && <div className={`dialog ${this.props.type} ${this.props.className} ${this.props.active ? ' active' : ''}`}>
                <Loader area={this.props.loaderAreaName} />
                <Grid container>
                    <Grid item xs={1} className="arrow-container">
                        <ArrowBack onClick={this.props.onClose} className="arrow action" />
                    </Grid>
                    <Grid item xs={10}>
                        <div className="head">
                            <h2>{this.props.title}</h2>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <div className="content">
                            {this.props.children.content}
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <div className="actions">
                            {this.props.children.actions}
                        </div>
                    </Grid>
                </Grid>
            </div>
    }
}

export default Dialog;
import * as React from 'react';

interface IButtonProps {
    label: string;
    onClick(): void
    className?: string;
    children: {
        icon: React.ReactNode
    };
}

export const Button: React.SFC<IButtonProps> = (props: IButtonProps) => {
    return <div className={`action ${props.className}`} onClick={props.onClick}>
        <span>{props.label}</span>
        <span className="icon">
            { props.children.icon }
        </span>
    </div>
};

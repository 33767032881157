import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid/Grid';
import { observer, inject } from 'mobx-react';
import { JobStore } from '../../stores/job-store';
import { hasValue } from '../../validate';

interface IFilterStatusProps {
    jobStore?: JobStore;
    onFilterRemoved(): void;
}

@inject('jobStore')
@observer
class FilterStatus extends React.Component<IFilterStatusProps> {
    public render() {
        const { jobStore, onFilterRemoved } = this.props;
        return <Grid container className="filter-state">
            <Grid item xs={1} />
            <Grid item xs={10}>
                <div className="chips">
                    {
                        !jobStore!.jobsFilter.isFiltered
                        && <Chip className="chip" label="Today &amp; Tomorrow (default)" />
                    }
                    {
                        hasValue(jobStore!.jobsFilter.address)
                        && <Chip className="chip" label="Address" onDelete={() => {
                            jobStore!.jobsFilter.setAddress('');
                            onFilterRemoved();
                        }} />
                    }
                    {
                        hasValue(jobStore!.jobsFilter.customerName)
                        && <Chip className="chip" label="Customer" onDelete={() => {
                            jobStore!.jobsFilter.setCustomerName('');
                            onFilterRemoved();
                        }} />
                    }
                    {
                        hasValue(jobStore!.jobsFilter.driver)
                        && <Chip className="chip" label="Driver" onDelete={() => {
                            jobStore!.jobsFilter.setDriver('');
                            onFilterRemoved();
                        }} />
                    }
                    {
                        hasValue(jobStore!.jobsFilter.orderNumber)
                        && <Chip className="chip" label="Order #" onDelete={() => {
                            jobStore!.jobsFilter.setOrderNumber('');
                            onFilterRemoved();
                        }} />
                    }
                    {
                        hasValue(jobStore!.jobsFilter.purchaseOrderNumber)
                        && <Chip className="chip" label="Purchase Order #" onDelete={() => {
                            jobStore!.jobsFilter.setPurchaseOrderNumber('');
                            onFilterRemoved();
                        }} />
                    }
                    {
                        jobStore!.jobsFilter.startDate && jobStore!.jobsFilter.endDate
                        && <Chip className="chip" label="Delivery Date" onDelete={() => {
                            jobStore!.jobsFilter.setStartDate(null);
                            jobStore!.jobsFilter.setEndDate(null);
                            onFilterRemoved();
                        }} />
                    }
                </div>
            </Grid>
        </Grid>
    }
}

export default FilterStatus;

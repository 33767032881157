import { Model } from './model';
import { observable, runInAction } from 'mobx';
import { IUnavailabilityResponse } from '../../services/vehicles-api';
import { required } from '../../validate';
import { DateTime } from 'luxon';
import { utcToLocal } from '../../datetime';
import { Address } from './address';

export class Unavailability extends Model {
    @observable public id: number = 0;
    @observable public reason: string = '';
    @observable public startDispatchTime: DateTime | null = null;
    @observable public endDispatchTime: DateTime | null = null;
    @observable public lineId: number = 0;
    @observable public address: Address = new Address({});
    
    constructor(obj: Partial<Unavailability>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    public static fromResponse(r: IUnavailabilityResponse, index: number) {
        return new Unavailability({
            ...r,
            startDispatchTime: utcToLocal(r.startDateTimeUtc),
            endDispatchTime: utcToLocal(r.endDateTimeUtc),
            lineId: index
        });
    }

    public clone() {
        return new Unavailability({id: this.id, reason: this.reason, startDispatchTime: this.startDispatchTime, endDispatchTime: this.endDispatchTime, lineId: this.lineId, address: this.address});
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'reason': (val: number) => {
            this.errors.reason = required(val);

            return this.errors.reason === null;
        },
        'startDispatchTime': (val: DateTime) => {
            this.errors.startDispatchTime = required(val);

            if (!this.errors.startDispatchTime && this.endDispatchTime) {
                this.errors.startDispatchTime = this.endDispatchTime > val 
                    ? null : 'Start date cannot be greater than End date';
            }

            return this.errors.startDispatchTime === null;
        },
        'endDispatchTime': (val: DateTime) => {
            this.errors.endDispatchTime = required(val);

            return this.errors.endDispatchTime === null;
        },
        'address': (val: Address) =>  val.validate()
    }
}
import { Model } from './model';
import { observable, runInAction } from 'mobx';
import { moreThanZero, inTheFuture, required } from '../../validate';
import { DateTime } from 'luxon';
import { ILocationSummary } from './location-summary';
import { SuggestionItem } from '../../components/Autocomplete/Autocomplete';

export class AbcReturn extends Model {
    @observable public abcLocationId: number = 0;
    @observable public abcLocationName: string = '';
    @observable public deliveryDate: DateTime | null = null;
    @observable public taskId: number = 0;
    @observable public unload: boolean = false;
    @observable public vehicleDepot: string = '';

    constructor(obj: Partial<AbcReturn>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'abcLocationName': (val: string) => {
            this.errors.abcLocationName = required(val);
            return this.errors.abcLocationName === null;
        },
        'deliveryDate': (val: DateTime) => {
            this.errors.date = inTheFuture(val);
            return this.errors.date === null;
        },
        'taskId': (val: number) => {
            this.errors.taskId = moreThanZero(val);
            return this.errors.taskId === null;
        }
    }

    public toLocationSuggestion(): SuggestionItem<ILocationSummary> {
        return { label: this.abcLocationName, value: { name: this.abcLocationName, id: this.abcLocationId }};
    }
}
import { observable, action } from 'mobx'
import { DateTime } from 'luxon';
import { PreLoad } from '../domain/pre-load';

export class PreLoadDialogState {
    @observable public preLoad? : PreLoad;
    @observable public maxDate: DateTime = DateTime.local().startOf('day').minus({day: 1});
    
    @action
    public init(jobId: number, deliveryDate: DateTime) {
        const maxDate = deliveryDate.startOf('day').minus({day: 1});
        let defaultDate = maxDate.startOf('day');
        // Do not set the default value to the weekend
        if (defaultDate.weekday === 7) {
            defaultDate = defaultDate.minus({day: 2});
        }
        if (defaultDate.weekday === 6) {
            defaultDate = defaultDate.minus({day: 1});
        }
        this.maxDate = maxDate;
        this.preLoad = new PreLoad({jobId, deliveryDate: deliveryDate.startOf('day'), preLoadDate: defaultDate});
    }
    
    @action
    public reset() {
        this.preLoad = undefined;
    }
}
import { DateTime } from 'luxon';

export const utcToLocal = (datetime: string) : DateTime => {
    return DateTime.fromISO(datetime, {zone: 'utc'}).toLocal();
}

export const toDate = (datetime: string) : DateTime => {
    return DateTime.fromISO(datetime);
}

export const toISODate = (datetime: DateTime | null | undefined) : string | null => {
    if (!datetime) return null;
    return datetime.toISODate();
}

export const dateTimeFormatted = (datetime: DateTime | null) : string => {
    return datetime
        ? datetime.toFormat('cccc dd LLLL yyyy HH:mm') 
        : '';
}

export const dateTimeFormattedShort = (datetime: DateTime | null) : string => {
    return datetime
        ? datetime.toFormat('dd/MM/yy HH:mm ') 
        : '';
}

export const dateFormattedShort = (datetime: DateTime | null) : string => {
    return datetime
        ? datetime.toFormat('dd/MM/yy') 
        : '';
}

export const timeFormatted = (datetime: DateTime | null) : string => {
    return datetime
        ? datetime.toFormat('HH:mm') 
        : '';
}

export const dateFormatted = (date: DateTime | null) : string => {
    return date
        ? date.toFormat('cccc dd LLLL yyyy')
        : '';
}
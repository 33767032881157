import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { observer, inject } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { ScheduleStore } from '../../stores/schedule-store';
import {IJobChangeSummary, JobChangeType} from "../../stores/domain/job-change-summary";

interface IChangeListProps {
    scheduleStore?: ScheduleStore
}

type Props = IChangeListProps & RouteComponentProps<{}>;

@inject('scheduleStore')
@observer
class ChangeList extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    public render() {
        const { scheduleStore } = this.props;

        const getChangeTypeText = (jobChange: IJobChangeSummary) => {
            switch (jobChange.changeType) {
                case JobChangeType.Quantities:
                    return 'Quantity'
                case JobChangeType.DeliveryAddress:
                    return 'Delivery'
                case JobChangeType.DeliveryDate:
                    return 'Date'
                case JobChangeType.ContactName:
                case JobChangeType.ContactEmail:
                case JobChangeType.ContactPhone:
                    return 'Contact'
                case JobChangeType.CustomerName:
                case JobChangeType.CustomerReference:
                    return 'Customer'
                case JobChangeType.PurchaseOrderNumber:
                    return 'PO'
                case JobChangeType.SpecialInstructions:
                    return 'Instructions'
                case JobChangeType.Timeslot:
                    return 'Timeslot'
                case JobChangeType.Delete:
                    return 'Delete'
                case JobChangeType.IsCrane:
                    return 'Crane'
                case JobChangeType.SubContract:
                    return 'Sub-contract'
                case JobChangeType.PickupAddress:
                    return 'Pickup'
                case JobChangeType.BodyTruck:
                    return 'Body truck'
                default:
                    return 'Other'
            }
        }

        return <div className="change-list">
            {
                scheduleStore!.jobChanges.map((j, index) => <Grid container
                    key={index}
                    className="item">
                        <Grid item xs={1} />
                        <Grid item xs={1}>
                            <div className="detail">{j.orderNumber}</div>
                            <div className="headers">Order #</div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="detail">{j.pickupAddress}</div>
                            <div className="headers">{`to ${j.deliveryAddress}`}</div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="detail">{getChangeTypeText(j)}</div>
                            <div className="headers">Change</div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="detail">{j.previousValue}</div>
                            <div className="headers">From</div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="detail">{j.newValue}</div>
                            <div className="headers">To</div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="detail">{j.driver}</div>
                            <div className="headers">Driver</div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="detail">{j.timeOfChange}</div>
                            <div className="headers">Time of change</div>
                        </Grid>
                </Grid>)
            }
        </div>;
    }
}

export default ChangeList;
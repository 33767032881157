import { observable, action } from 'mobx'
import { hasValue } from '../../validate';
import { Job } from '../domain/job';
import { TaskToMove } from '../domain/task-to-move';
import { UnassignedJobs } from '../domain/unassigned-jobs';
import { UnscheduledDelay } from '../domain/unscheduled-delay';
import { VehicleRoute } from '../domain/vechicle-route';

export enum ScheduleListAction {
    None = 0,
    UnscheduledDelay = 1,
    ReorganizeVehicleRoute = 3,
    UnassignJobs = 4,
    TransferDriver = 5
}

export class ScheduleListState {
    @observable public activeAction: ScheduleListAction = ScheduleListAction.None;
    @observable public jobToAccept: Job | null = null;
    @observable public jobToReturnToAbc: Job | null = null;
    @observable public taskToMove: TaskToMove = new TaskToMove({});
    @observable public jobToRemove: Job | null = null;
    @observable public reorganizingVehicleRouteGuid: string = '';
    @observable public unassignedJobs: UnassignedJobs = new UnassignedJobs({});
    @observable public unscheduledDelay: UnscheduledDelay = new UnscheduledDelay({});
            
    @action
    public addUnscheduledDelay(route: VehicleRoute) {
        this.resetCurrentAction();
        
        this.unscheduledDelay = new UnscheduledDelay({ routeId: route.id, liveVehicleId: route.vehicle!.id });

        if (route.id > 0) {
            this.activeAction = ScheduleListAction.UnscheduledDelay;
        } else {
            this.activeAction = ScheduleListAction.None;
        }
    }

    @action
    public reorganizeVehicleRoute(vehicleRouteGuid: string) {
        this.reorganizingVehicleRouteGuid = vehicleRouteGuid;

        if (hasValue(vehicleRouteGuid)) {
            this.activeAction = ScheduleListAction.ReorganizeVehicleRoute;
        } else {
            this.activeAction = ScheduleListAction.None;
        }
    }

    @action
    public resetCurrentAction() {
        this.unscheduledDelay = new UnscheduledDelay({});
        this.jobToAccept = null;
        this.jobToRemove = null;
        this.reorganizingVehicleRouteGuid = '';
        this.taskToMove = new TaskToMove({ guid: '', index: 0 });
        this.unassignedJobs = new UnassignedJobs({});

        this.activeAction = ScheduleListAction.None;
    }

    @action
    public resetTaskToMove() {
        this.taskToMove = new TaskToMove({ guid: '', index: 0 });
    }

    @action
    public setTaskToMove(guid: string, index: number) {
        this.taskToMove = new TaskToMove({ guid, index })
    }

    @action
    public unassignJobs(unassignedJobs: UnassignedJobs) {
        this.unassignedJobs = unassignedJobs;

        if (hasValue(unassignedJobs.runId)) {
            this.activeAction = ScheduleListAction.UnassignJobs;
        } else {
            this.activeAction = ScheduleListAction.None;
        }
    }
}
import * as React from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { JobStore } from '../../stores/job-store';
import JobList from './JobList';
import SearchDialog from './SearchDialog';
import { RouteComponentProps } from 'react-router';
import tracker from '../Loader/loader-tracker';
import FilterStatus from './FilterStatus';
import { HeaderWithSearch } from '../Header/HeaderWithSearch';
import { SignalRClient } from '../../services/signalRClient';
import TransShipDialog from './TransShipDialog';
import PreLoadDialog from './PreLoadDialog';
import UndoDialog from './UndoDialog';
import ExpandingSection from '../ExpandingSection/ExpandingSection';
import { Loader } from '../Loader/Loader';

interface IJobsProps {
    jobStore?: JobStore
}
type Props = IJobsProps & RouteComponentProps<{}>;

@inject('jobStore')
@observer
class Jobs extends React.Component<Props> {
    private signalRClient: SignalRClient = new SignalRClient(window.config.apiUrl);
    @observable private searchDialogActive: boolean = false;

    constructor(props: Props) {
        super(props);
        this.handleSearchOpen = this.handleSearchOpen.bind(this);
        this.handleSearchClose = this.handleSearchClose.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleUpdateFilter = this.handleUpdateFilter.bind(this);
        this.updateJobs = this.updateJobs.bind(this);
    }

    public async componentDidMount() {
        await this.updateJobs();

        this.signalRClient.onJobsUpdate = this.updateJobs;
        this.signalRClient.onScheduleGenerated = this.updateJobs;
        this.signalRClient.onScheduleGenerationStarted = this.updateJobs;
        this.signalRClient.start();
    }

    public async componentWillUnmount() {
        this.signalRClient.stop();
    }

    private async updateJobs() {
        await tracker.track(this.props.jobStore!.getJobs());
    }

    public render() {
        return <div className="jobs">
            <HeaderWithSearch
                title="Jobs"
                onClickSearch={this.handleSearchOpen} />
            <FilterStatus onFilterRemoved={this.handleUpdateFilter} />
            <div className="jobs-container">
                <Loader area="jobs-container-loader" />
                {
                    this.props.jobStore!.actionableJobs &&
                    <ExpandingSection
                        title="Jobs requiring attention"
                        highlight={true}
                        contentCount={this.props.jobStore!.actionableJobs.length}>
                        {{
                            content: <JobList {...this.props}
                                jobs={this.props.jobStore!.actionableJobs}
                                showNoMoreRecords={false}/>
                        }}
                    </ExpandingSection>
                }
                <ExpandingSection
                    title="Jobs"
                    contentCount={this.props.jobStore!.orderedJobs.length}>
                    {{
                        content: <JobList {...this.props}
                            jobs={this.props.jobStore!.orderedJobs}
                            showNoMoreRecords={true} />
                    }}
                </ExpandingSection>
            </div>
            <SearchDialog
                onClose={this.handleSearchClose}
                onSearch={this.handleSearch}
                active={this.searchDialogActive} />
            <PreLoadDialog />
            <TransShipDialog />
            <UndoDialog />
        </div>
    }

    @action
    private handleSearchOpen() {
        this.searchDialogActive = true;
    }

    @action
    private handleSearchClose() {
        this.searchDialogActive = false;
    }

    private async handleSearch() {
        await tracker.track(this.props.jobStore!.getJobs(), 'jobs-container-loader');
    }

    private async handleUpdateFilter() {
        await tracker.track(this.props.jobStore!.getJobs(), 'jobs-container-loader');
    }
}

export default Jobs;
import { observer, inject } from 'mobx-react';
import React from 'react';
import tracker from '../Loader/loader-tracker';
import { UiStore } from '../../stores/ui-store';
import { WithStyles, withStyles } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { JobStore } from '../../stores/job-store';
import { CloseButton, OkButton } from '../Dialog/Buttons/Buttons';
import Dialog from '../Dialog/Dialog';

interface ISupplierReturnDialog extends WithStyles<typeof globalStyles> {
    jobStore?: JobStore
    uiStore?: UiStore
}

@inject('jobStore', 'uiStore')
@observer
class SupplierReturnDialog extends React.Component<ISupplierReturnDialog> {

    constructor(props: ISupplierReturnDialog) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public render() {
        const { supplierReturnDialogState } = this.props.uiStore!;

        return supplierReturnDialogState.taskId > 0 && <Dialog
            title="Return to Supplier"
            type="slide"
            onClose={this.closeDialog}
            active={true}
            className="supplier-return-dialog"
            loaderAreaName="supplier-return-loader">
            {{
                content: <React.Fragment>
                    Are you sure you want to return this delivery to the supplier?
                </React.Fragment>,
                actions: <React.Fragment>
                    <CloseButton onClick={this.closeDialog} />
                    <OkButton onClick={this.handleSubmit} />
                </React.Fragment>
            }}
        </Dialog>
    }

    public closeDialog(): void {
        const { scheduleListState } = this.props.uiStore!;
        scheduleListState.resetCurrentAction();
    }

    private async handleSubmit() {
        const { supplierReturnDialogState } = this.props.uiStore!;

        const ok = await tracker.track(this.props.jobStore!.returnToSupplier(supplierReturnDialogState.taskId), 'supplier-return-loader');
        if (ok === true) {
            this.props.uiStore!.supplierReturnDialogState.reset();
        }
    }
}

export default withStyles(globalStyles)(SupplierReturnDialog);
import { observable, action, computed, runInAction } from 'mobx'
import { ScheduleAvailability } from '../domain/schedule-availability';
import { DriverStore } from '../driver-store';
import { VehicleRoute } from '../domain/vechicle-route';
import { DriverAssignment } from '../domain/driver-assignment';

export class AssignDriverDialogState {
    public driverStore: DriverStore;

    @observable public driverAssignment: DriverAssignment = new DriverAssignment({});
    @observable public spareDrivers: ScheduleAvailability[] = [];
    @observable public driverIndex: number = -1;
    @observable public route: VehicleRoute = new VehicleRoute({});

    constructor(driverStore: DriverStore) {
        this.driverStore = driverStore;
    }

    @computed
    public get selectedDriver() {
        if (!this.spareDrivers.length) return;
        return this.spareDrivers[this.driverIndex];
    }
    
    @action
    public async init(runId: string, route: VehicleRoute) {
        this.route = route;
        this.driverAssignment = new DriverAssignment({ runId, routeGuid: route.guid });
        await this.getAvailableDrivers();
    }

    @action
    public reset() {
        this.driverAssignment = new DriverAssignment({});
        this.route = new VehicleRoute({});
        this.spareDrivers = [];
    }

    @action
    public async updateDriver(index: number) {
        this.driverIndex = index;
        const driver = this.selectedDriver;

        this.driverAssignment.driverId = driver ? driver.driverId : 0;
    }

    @action
    private async getAvailableDrivers() {
        const drivers = await this.driverStore!.getSpareDriversForRoute(this.route.guid, this.driverAssignment.runId);
        runInAction(() => this.spareDrivers = drivers);
    }
}
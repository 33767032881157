import React, { Component } from 'react';
import { Switch, RouteComponentProps, withRouter } from 'react-router';
import Jobs from './components/Jobs/Jobs';
import Schedules from './components/Schedule/Schedules';
import Vehicles from './components/Vehicles/Vehicles';
import ViewVehicle from './components/Vehicles/ViewVehicle';
import NewVehicle from './components/Vehicles/NewVehicle';
import Drivers from './components/Drivers/Drivers';
import NewDriver from './components/Drivers/NewDriver';
import ViewDriver from './components/Drivers/ViewDriver';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Nav } from './components/Nav/Nav';
import { UiStore } from './stores/ui-store';
import { Loader } from './components/Loader/Loader';
import Message from './components/Notification/Message';
import ScheduleTimerMessage from './components/ScheduleTimerNotification/ScheduleTimerMessage';
import ViewJob from './components/Jobs/ViewJob';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';
import { Environment } from './components/Environment/Environment';

export interface IAppProps {
    uiStore?: UiStore;
}
type Props = IAppProps & RouteComponentProps<{}>;

class App extends Component<Props> {
    public render() {
        return (
            <div className="app">
                <MuiThemeProvider theme={theme}>
                    <Environment />
                    <Nav {...this.props} />
                    <Loader />
                    <div className="main-container">
                        <Switch>
                            <ProtectedRoute exact path="/" component={Jobs} />
                            <ProtectedRoute exact path="/schedule" component={Schedules} />
                            <ProtectedRoute exact path="/view-job/:id" component={ViewJob} />
                            <ProtectedRoute exact path="/vehicles" component={Vehicles} />
                            <ProtectedRoute exact path="/new-vehicle" component={NewVehicle} />
                            <ProtectedRoute exact path="/view-vehicle/:id" component={ViewVehicle} />
                            <ProtectedRoute exact path="/drivers" component={Drivers} />
                            <ProtectedRoute exact path="/new-driver" component={NewDriver} />
                            <ProtectedRoute exact path="/view-driver/:id" component={ViewDriver} />
                        </Switch>
                    </div>
                    <Message />
                    <ScheduleTimerMessage />
                </MuiThemeProvider>
            </div>
        );
    }
}

export default withRouter(App);

import { UiStore } from './stores/ui-store';
import { JobStore } from './stores/job-store';
import { DriverStore } from './stores/driver-store';
import { VehicleStore } from './stores/vehicle-store';
import { ScheduleStore } from './stores/schedule-store';
import { JobsApi } from './services/jobs-api';
import { VehiclesApi } from './services/vehicles-api';
import { DriversApi } from './services/drivers-api';
import { ScheduleApi } from './services/schedule-api';


export interface IRootStore {
    uiStore: UiStore
    jobStore: JobStore
    vehicleStore: VehicleStore
    driverStore: DriverStore
    scheduleStore: ScheduleStore
}

export class RootStore implements IRootStore {
    public uiStore: UiStore;
    public jobStore: JobStore
    public vehicleStore: VehicleStore
    public driverStore: DriverStore
    public scheduleStore: ScheduleStore

    constructor() {
        const driversApi = new DriversApi();
        const jobsApi = new JobsApi();
        const vehiclesApi = new VehiclesApi();
        this.uiStore = new UiStore(this);
        this.jobStore = new JobStore(jobsApi);
        this.vehicleStore = new VehicleStore(vehiclesApi);
        this.driverStore = new DriverStore(driversApi);
        this.scheduleStore = new ScheduleStore(driversApi, new ScheduleApi(), vehiclesApi, jobsApi);
    }
}
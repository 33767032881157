import * as React from 'react';
import './styles.scss';

interface IContextItemProps {
    label: string;
    onClick: React.MouseEventHandler<Element>;
}
export const ContextItem: React.SFC<IContextItemProps> = (props) => {
    return <li>
        <span className="context-action"
            onClick={props.onClick}>
            {props.label}
        </span>
    </li>
};
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import './styles.scss';
import { RouteComponentProps } from 'react-router';
import { Grid } from '@material-ui/core';
import { ScheduleStore } from '../../stores/schedule-store';
import ScheduleList from './ScheduleList';
import { UiStore } from '../../stores/ui-store';
import JobList from '../Jobs/JobList';
import { JobStore } from '../../stores/job-store';
import { ScheduleDay } from '../../stores/ui-state/schedule-ui-state';
import ExpandingSection from '../ExpandingSection/ExpandingSection';
import UnscheduledDelayDialog from './UnscheduledDelayDialog';
import UnassignLiveJobDialog from './UnassignLiveJobDialog';
import {ActionItem} from "../ActionsMenu/ActionItem";
import GetApp from "@material-ui/icons/GetApp";
import {ActionsMenu} from "../ActionsMenu/ActionsMenu";

interface ILiveScheduleProps {
    jobStore?: JobStore
    scheduleStore?: ScheduleStore
    uiStore?: UiStore
}
type Props = ILiveScheduleProps & RouteComponentProps<{}>;

@inject('jobStore', 'scheduleStore', 'uiStore')
@observer
class LiveSchedule extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.handleDownloadLiveCsv = this.handleDownloadLiveCsv.bind(this);
    }

    public render() {
        const { scheduleStore, uiStore } = this.props;
        const dayText = uiStore!.scheduleUiState.dayText;
        const schedule = scheduleStore!.currentSchedule;

        return <React.Fragment>
            {schedule.vehicleRoutesWithTasks.length > 0 ?
                <div className="schedule-container live-schedule">
                    <ExpandingSection
                        title="Subcontracted Jobs"
                        contentCount={this.props.jobStore!.subcontractedJobs.length}>
                        {{
                            content: <JobList {...this.props}
                                jobs={this.props.jobStore!.subcontractedJobs}
                                showNoMoreRecords />
                        }}
                    </ExpandingSection>
                    <ScheduleList {...this.props} />
                    <UnscheduledDelayDialog />
                    <UnassignLiveJobDialog {...this.props} />
                    <ActionsMenu>
                        <ActionItem
                            enabled={true}
                            label="Export as CSV"
                            onClick={this.handleDownloadLiveCsv}>
                            <GetApp />
                        </ActionItem>
                    </ActionsMenu>
                </div> :
                <div className="no-schedule">
                    <Grid container className="schedule" alignContent={'center'} alignItems={'center'}>
                        <Grid item xs={1} />
                        <Grid item xs={10} className="no-schedule-header">So empty</Grid>
                        <Grid item xs={1} />
                        <Grid item xs={12} className="no-schedule-content">It looks like theres no live schedule for {dayText}{uiStore!.scheduleUiState.scheduleDay !== ScheduleDay.Other || uiStore!.scheduleUiState.otherScheduleDate >= uiStore!.scheduleUiState.today ? ', why not switch over to the draft and publish one!' : '.'}</Grid>
                        <Grid item xs={12} className="center"><img className="no-schedule-image" src={process.env.PUBLIC_URL + '/live-missing.png'} alt="Live missing Image" />
                        </Grid>
                    </Grid>
                </div>
            }
        </React.Fragment>
    }

    private async handleDownloadLiveCsv() {
        const { scheduleStore } = this.props;
        const scheduleId = scheduleStore!.currentSchedule.id;

        scheduleStore!.downloadLiveScheduleCsv(scheduleId)
            .then((blob: File) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = blob.name;
                a.click();
            });
    }
}

export default LiveSchedule;
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { VehicleStore } from '../../stores/vehicle-store';
import { RouteComponentProps } from 'react-router';
import { Header } from '../Header/Header';
import VehicleForm from './VehicleForm';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { ActionItem } from '../ActionsMenu/ActionItem';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import Delete from '@material-ui/icons/Delete';
import { UiStore } from '../../stores/ui-store';
import tracker from '../Loader/loader-tracker';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import { hasValue } from '../../validate';

interface IViewVehicleProps {
    vehicleStore?: VehicleStore
    uiStore?: UiStore
}

interface MatchParams {
    id: string;
}

type Props = IViewVehicleProps & RouteComponentProps<MatchParams>;

@inject('vehicleStore', 'uiStore')
@observer
class ViewVehicle extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.updateVehicle = this.updateVehicle.bind(this);
        this.deleteVehicle = this.deleteVehicle.bind(this);
    }

    public async componentDidMount() {
        const vechileId = this.props.match.params.id;
        const vehicle = await tracker.track(this.props.vehicleStore!.loadVehicle(Number(vechileId)));
        this.props.uiStore!.vehicleFormState.initView(vehicle);
    }

    public render() {
        const { vehicleFormState } = this.props.uiStore!;
        const { vehicle } = this.props.vehicleStore!;
        return <div className="view-vehicle">
            <Header title="Vehicle" />
            <VehicleForm />
            <ActionsMenu>
                {
                    vehicleFormState.readOnly
                    && <ActionItem
                        enabled={true}
                        label="Edit"
                        onClick={() => vehicleFormState.setReadOnly(false)}>
                        <Edit />
                    </ActionItem>
                }
                {
                    vehicleFormState.readOnly
                    && <ActionItem
                        enabled={true}
                        label="Delete"
                        onClick={() => vehicleFormState.setDeleteDialogActive(true)}>
                        <Delete />
                    </ActionItem>
                }
                {
                    !vehicleFormState.readOnly
                    && <ActionItem
                    enabled={true}
                        label="Submit"
                        onClick={this.updateVehicle}>
                        <Save />
                    </ActionItem>
                }
            </ActionsMenu>

            <ConfirmDialog
                active={vehicleFormState.deleteDialogActive}
                title="Delete Vehicle" 
                message={`Are you sure you want to delete ${vehicle.registration}? 
                    ${hasValue(vehicle.defaultDriverName) ? `${vehicle.defaultDriverName} will be unassigned.` : ''} `}
                onClose={() => vehicleFormState.setDeleteDialogActive(false)} 
                onOk={this.deleteVehicle}/>
        </div>
    }

    private async updateVehicle() {
        const ok = await tracker.track(this.props.vehicleStore!.updateVehicle());
        if (ok) this.props.history.push('/vehicles');
    }

    private async deleteVehicle() {
        const ok = await tracker.track(this.props.vehicleStore!.deleteVehicle(), 'confirm-dialog');
        if (ok) this.props.history.push('/vehicles');
    }
}

export default ViewVehicle;
import { Hidden, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Assignment from '@material-ui/icons/Assignment';
import Block from '@material-ui/icons/Block';
import Error from '@material-ui/icons/Error';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Lock from '@material-ui/icons/Lock';
import Repeat from '@material-ui/icons/Repeat';
import Replay from '@material-ui/icons/Replay';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import VerticalAlignTop from '@material-ui/icons/VerticalAlignTop';
import Warning from '@material-ui/icons/Warning';
import { DateTime } from 'luxon';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Job, JobStatus, JobType, OrderApprovalStatus, TimeslotType, JobDraftStatus } from '../../stores/domain/job';
import { IJobSummary } from '../../stores/domain/job-summary';
import { PublishStatus } from '../../stores/domain/schedule';
import { JobStore } from '../../stores/job-store';
import { ScheduleStore } from '../../stores/schedule-store';
import { UiStore } from '../../stores/ui-store';
import { ContextItem } from '../ContextMenu/ContextItem';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { IconTooltip } from '../IconTooltip/IconTooltip';
import { NoRecords } from '../NoRecords/NoRecords';

const MAXDISTANCE: number = 500;

interface IJobListProps {
    jobStore?: JobStore
    jobs: IJobSummary[]
    scheduleStore?: ScheduleStore
    showNoMoreRecords: boolean
    uiStore?: UiStore
}

type Props = IJobListProps & RouteComponentProps<{}>;

@inject('jobStore', 'scheduleStore', 'uiStore')
@observer
class JobList extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.getIcons = this.getIcons.bind(this);
        this.getTimeslotName = this.getTimeslotName.bind(this);
        this.handleScheduleToRoute = this.handleScheduleToRoute.bind(this);
        this.handlePreLoad = this.handlePreLoad.bind(this);
        this.handleTransShip = this.handleTransShip.bind(this);
        this.handleViewJob = this.handleViewJob.bind(this);
        this.handleLockJob = this.handleLockJob.bind(this);
        this.renderJob = this.renderJob.bind(this);
    }

    public handleViewJob(jobId: number): void {
        this.props.history.push(`view-job/${jobId}`);
    }

    public getErrors = (j: IJobSummary) => {
        let errorText = null;
        if (j.isMissingDemands) {
            errorText = 'Missing Number of Pallets or Packs';
        }
        else if (j.exceedsShippingLimit) {
            errorText = 'Job Exceeds Shipping Limit';
        }
        else if (j.orderApprovalStatus === OrderApprovalStatus.PricingApprovalPending) {
            errorText = 'Sales Order pending pricing approval';
        }
        else if (j.orderApprovalStatus === OrderApprovalStatus.CreditApprovalPending) {
            errorText = 'Sales Order pending credit approval';
        }

        return <React.Fragment>
            {
                errorText !== null && <IconTooltip title={errorText}>
                    {{ icon: <Error className="error" /> }}
                </IconTooltip>
            }
        </React.Fragment>;
    };

    public getIcons = (j: IJobSummary) => {
        return (<React.Fragment>
            {this.getErrors(j)}
            {this.getWarnings(j)}
            {
                j.subcontract
                && <IconTooltip title={`Job is subcontracted`}>
                    {{ icon: <SupervisorAccount /> }}
                </IconTooltip>
            }
            {
                j.updatedInstructions
                && <IconTooltip title={`The job's special instructions have been updated.`}>
                    {{ icon: <Assignment /> }}
                </IconTooltip>
            }
            {
                j.type === JobType.TransShipPickup
                && <IconTooltip title={`Trans-Ship Pickup`}>
                    {{ icon: <ArrowUpward /> }}
                </IconTooltip>
            }
            {
                j.type === JobType.TransShipDelivery
                && <IconTooltip title={`Trans-Ship Delivery`}>
                    {{ icon: <ArrowDownward /> }}
                </IconTooltip>
            }
            {
                j.type === JobType.PreLoadPickup
                && <IconTooltip title={`Pre-Load Pickup ${j.vehicleRegistration}`}>
                    {{ icon: <ArrowUpward /> }}
                </IconTooltip>
            }
            {
                j.type === JobType.PreLoadDelivery
                && <IconTooltip title={`Pre-Load Delivery ${j.vehicleRegistration}`}>
                    {{ icon: <ArrowDownward /> }}
                </IconTooltip>
            }
            {
                j.type === JobType.ReturnToAbcForRedelivery || j.type === JobType.ReturnToAbcAndUnload
                && <IconTooltip title={`Return to ABC`}>
                    {{ icon: <Repeat /> }}
                </IconTooltip>
            }
            {
                j.type === JobType.Redelivery
                && <IconTooltip title={`Redelivery`}>
                    {{ icon: <Replay /> }}
                </IconTooltip>
            }
            {
                j.type === JobType.ReturnToSupplier
                && <IconTooltip title={`Return to Supplier`}>
                    {{ icon: <Repeat /> }}
                </IconTooltip>
            }
            {
                j.bodyTruck
                && <IconTooltip title={`Body truck required`}>
                    {{ icon: <LocalShipping /> }}
                </IconTooltip>
            }
            {
                j.isCrane
                && <IconTooltip title={`Meet crane on site`}>
                    {{ icon: <VerticalAlignTop /> }}
                </IconTooltip>
            }
            {
                j.isLocked
                && <IconTooltip title={`Job is locked`}>
                    {{ icon: <Lock /> }}
                </IconTooltip>
            }
        </React.Fragment>)
    };

    public getClassName = (job: IJobSummary) => {
        let className = "job"

        switch (job.deliveryTimeslot) {
            case TimeslotType.FirstUp:
                className = `${className} firstup-job`;
                break;
            case TimeslotType.ASAP:
                className = `${className} asap-job`;
                break;
        }

        return className;
    }
    
    public getDemand = (job: IJobSummary) => {
        return job.isMissingDemands ? `? PK/? PLT` : `${job.packs} PK/${job.pallets} PLT`
    }

    public getTimeslotName = (value: TimeslotType) => {
        switch (value) {
            case TimeslotType.AM:
                return 'AM'
            case TimeslotType.PM:
                return 'PM'
            case TimeslotType.ASAP:
                return 'Asap'
            case TimeslotType.AnyTime:
                return 'Any Time'
            case TimeslotType.FirstUp:
                return 'First Up'
            case TimeslotType.None:
                return 'None'
        }

        return 'None';
    }

    public getPickUpTimeInfo = (job: IJobSummary) => job.pickupWindow ?
        job.pickupWindow : 'not constrained';

    public getDeliveryTimeInfo = (job: IJobSummary) => job.deliveryWindow ?
        job.deliveryWindow : 'not constrained';

    public getDistanceInfo = (job: IJobSummary) => !!job.expectedDistanceInKms ?
        `${job.expectedDistanceInKms} km` : 'Not known yet';

    public getWarnings = (j: IJobSummary) => {
        const isDraftMode = this.props.location.pathname === '/schedule' &&
            this.props.uiStore!.scheduleUiState.publishStatus === PublishStatus.Draft;
        let warningText = null;
        if (isDraftMode && j.status >= JobStatus.Scheduled) {
            warningText = 'Job will be removed from live schedule when draft is pushed.';
        }
        if (j.expectedDistanceInKms > MAXDISTANCE && j.status < JobStatus.OnRoute) {
            warningText = `Job exceeds the distance limit of ${MAXDISTANCE} km.`;
        }

        return <React.Fragment>
            {
                warningText !== null && <IconTooltip title={warningText}>
                    {{ icon: <Warning className="warning-icon" /> }}
                </IconTooltip>
            }
        </React.Fragment>;
    };

    public renderJob = (job: IJobSummary, index: number) => {
        const reference = job.isFreightOnly ? job.customerReference : job.purchaseOrderNumber;
        return (
            <Grid container
                key={index}
                onClick={() => this.handleViewJob(job.id)}
                className={this.getClassName(job)}>
                <Hidden mdUp>
                    {this.contextMenu(job)}
                    <Grid item xs={11} className="address"><span>{job.pickupAddress}</span></Grid>
                    <Grid item xs={1} />
                    <Grid item xs={11} className="address"><span>{job.deliveryAddress}</span></Grid>
                    <Grid item xs={1} />
                    <Grid item xs={11}> {job.draftStatus === JobDraftStatus.Unschedulable ?
                        <div className="unschedulable">
                            {Job.getShortStatusText(job.status, job.draftStatus)}
                        </div> : <div>
                            {Job.getShortStatusText(job.status, job.draftStatus)}
                        </div>
                    }</Grid>
                    <Grid item xs={1} />
                    <Grid item xs={8} className="customer sub-text"><span>{job.orderNumber}: {job.customerName} {reference}</span></Grid>
                    <Grid item xs={3} className="status-icons">{this.getIcons(job)}</Grid>
                </Hidden>
                <Hidden smDown>
                    {this.contextMenu(job)}
                    <Grid item xs={1}>
                        <div>
                            <div>{job.deliveryDate.toLocaleString({ month: 'short', day: '2-digit' })}</div>
                            <div className="headers">Date</div>
                        </div>
                    </Grid>
                    <Grid item xs={3} className="address">
                        <Tooltip title={job.pickupAddress} placement="bottom-start"><div className="detail">{job.pickupAddress}</div></Tooltip>
                        <Tooltip title={job.deliveryAddress} placement="bottom-start"><div className="detail">{job.deliveryAddress}</div></Tooltip>
                    </Grid>
                    <Grid item xs={2} className="customer">
                        <Tooltip title={job.customerName} placement="bottom-start">
                            <div className="detail">{job.customerName}</div>
                        </Tooltip>
                        <div className="headers">Customer</div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className={job.isMissingDemands ? 'warning' : ''}>{this.getDemand(job)}</div>
                        <div className="headers">Demand</div>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title={
                            <React.Fragment>
                                <p>
                                    <b>Job ID : </b>{job.id}
                                </p>
                            </React.Fragment>
                        } placement="bottom-start">
                            <div>
                                <div>{job.orderNumber}</div>
                                <div className="headers">{reference}</div>
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title={
                            <React.Fragment>
                                <p>
                                    <b>Pickup : </b>{this.getPickUpTimeInfo(job)}
                                </p>
                                <p>
                                    <b>Delivery : </b>{this.getDeliveryTimeInfo(job)}
                                </p>
                                <p>
                                    <b>Expected Distance : </b>{this.getDistanceInfo(job)}
                                </p>
                            </React.Fragment>
                        } placement="bottom-start">
                            <div>
                                <div>{this.getTimeslotName(job.deliveryTimeslot)}</div>
                                <div className="headers">Timeslot</div>
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <div>
                            {Job.getStatusText(job.status)}
                        </div>
                        {job.draftStatus === JobDraftStatus.Unschedulable ?
                            <Tooltip title={`The job could not be scheduled`}>
                                <div className="headers">
                                    <div className="unschedulable">
                                        {Job.getDraftStatusText(job.draftStatus)}
                                    </div>
                                </div>
                            </Tooltip> : <div className="headers">
                                {job.draftStatus === JobDraftStatus.None 
                                ? ''
                                : Job.getDraftStatusText(job.draftStatus)}
                            </div>
                        }
                    </Grid>
                    <Grid item xs={1} className="status-icons">
                        {this.getIcons(job)}
                    </Grid>
                </Hidden>
            </Grid>
        )
    }

    private async handleLockJob(job: IJobSummary, lock: boolean) {
        await this.props.jobStore!.lockJob(job.id, lock);
    }
    
    public handleScheduleToRoute(job: IJobSummary): void {
        this.props.uiStore!.resetDialogs();
        this.props.uiStore!.scheduleToRouteDialogState.init(job);
    }

    public handlePreLoad(job: IJobSummary): void {
        this.props.uiStore!.resetDialogs();
        this.props.uiStore!.preLoadDialogState.init(job.id, job.deliveryDate);
    }

    public handleTransShip(job: IJobSummary): void {
        this.props.uiStore!.resetDialogs();
        this.props.uiStore!.transShipDialogState.init(job.id, job.deliveryDate);
    }

    public handleUndoJob(job: IJobSummary): void {
        this.props.uiStore!.resetDialogs();
        this.props.uiStore!.undoDialogState.init(job);
    }
    
    public render() {
        return <div className="job-list">
            {
                this.props.jobs.map((j, i) => (this.renderJob(j, i)))
            }
            {this.props.showNoMoreRecords && this.props.jobs.length === 0 &&
                <NoRecords message="No more jobs" active />}
        </div>
    }

    private contextMenu(job: IJobSummary) {
        const today = DateTime.local().startOf('day');
        const isDraftMode = this.props.location.pathname === '/schedule' &&
            this.props.uiStore!.scheduleUiState.publishStatus === PublishStatus.Draft;

        if (job.status === JobStatus.Complete) {
            return <Grid item xs={1}
                className="icon-container"
                title="Job is complete" >
                <Block className="action disabled" />
            </Grid>
        }

        if (job.isMissingDemands) {
            return <Grid item xs={1}
                className="icon-container"
                title="Job is missing packs or pallets" >
                <Block className="action disabled" />
            </Grid>
        }

        if (job.subcontract) {
            return <Grid item xs={1}
                className="icon-container"
                title="Job is sub contracted" >
                <Block className="action disabled" />
            </Grid>
        }

        if (job.deliveryDate < today) {
            return <Grid item xs={1}
                className="icon-container"
                title="Job is in the past" >
                <Block className="action disabled" />
            </Grid>
        }

        return <Grid item xs={1}
            className="icon-container"
            onClick={(e: React.MouseEvent<Element, MouseEvent>) => e.stopPropagation()}>
            <ContextMenu title="job options">
                {
                    !isDraftMode &&
                    (job.type === JobType.Default || job.type === JobType.TransShipDelivery) &&
                    job.deliveryDate > today &&
                    !job.isMissingDemands &&
                    <ContextItem label="Pre-Load"
                        onClick={() => this.handlePreLoad(job)} />
                }
                {
                    !isDraftMode &&
                    (job.type === JobType.Default || job.type === JobType.PreLoadDelivery) &&
                    job.deliveryDate >= today &&
                    !job.isMissingDemands &&
                    <ContextItem label="Trans-Ship"
                        onClick={() => this.handleTransShip(job)} />
                }
                {
                    !isDraftMode &&
                    job.type === JobType.PreLoadPickup &&
                    job.deliveryDate >= today &&
                    <ContextItem label="Cancel Pre-Load"
                        onClick={() => this.handleUndoJob(job)} />
                }
                {
                    !isDraftMode &&
                    job.type === JobType.TransShipPickup &&
                    job.deliveryDate >= today &&
                    <ContextItem label="Cancel Trans-Ship"
                        onClick={() => this.handleUndoJob(job)} />
                }
                {
                    isDraftMode && this.props.scheduleStore!.currentDraftSchedule.runId !== '' && !job.isLocked &&
                    <ContextItem label="Schedule to Route"
                        onClick={() => this.handleScheduleToRoute(job)} />
                }
                {
                    isDraftMode && !job.isLocked &&
                    <ContextItem label="Lock Job"
                                 onClick={async () => await this.handleLockJob(job, true)} />
                }
                {
                    isDraftMode && job.isLocked &&
                    <ContextItem label="Unlock Job"
                                 onClick={async () => await this.handleLockJob(job, false)} />
                }
            </ContextMenu>
        </Grid>
    }
}

export default JobList;
import { Model } from './model';
import { observable, runInAction, computed, action } from 'mobx';
import { IDriverUnavailabilityResponse } from '../../services/drivers-api';
import { required, hasValue, getTime } from '../../validate';
import { DateTime } from 'luxon';
import { toDate } from '../../datetime';
import { DayOfWeekFlags } from './day-of-week-flags';

export class DriverUnavailability extends Model {
    @observable public id: number = 0;
    @observable public reason: string = '';
    @observable public startDate: DateTime | null = null;
    @observable public endDate: DateTime | null = null;
    @observable public lineId: number = 0;
    @observable public repeatDayFlags: DayOfWeekFlags = DayOfWeekFlags.None;
    @observable public startDispatchTime: string = '';
    @observable public endDispatchTime: string = '';
    
    constructor(obj: Partial<DriverUnavailability>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    public static fromResponse(r: IDriverUnavailabilityResponse, index: number) {
        return new DriverUnavailability({
            ...r,
            startDate: toDate(r.startDate),
            endDate: toDate(r.endDate),
            lineId: index
        });
    }

    @computed
    public get isRepeat() {
        return (DayOfWeekFlags.None !== this.repeatDayFlags);
    }

    @computed
    public get getRepeatWeekDays() {
        if (DayOfWeekFlags.None === this.repeatDayFlags) {
            return 'Never';
        }
        
        const resultItems = [];

        if (DayOfWeekFlags.Sunday === (this.repeatDayFlags & DayOfWeekFlags.Sunday)) {
            resultItems.push('Sunday');
        }
        if (DayOfWeekFlags.Monday === (this.repeatDayFlags & DayOfWeekFlags.Monday)) {
            resultItems.push('Monday');
        }
        if (DayOfWeekFlags.Tuesday === (this.repeatDayFlags & DayOfWeekFlags.Tuesday)) {
            resultItems.push('Tuesday');
        }
        if (DayOfWeekFlags.Wednesday === (this.repeatDayFlags & DayOfWeekFlags.Wednesday)) {
            resultItems.push('Wednesday');
        }
        if (DayOfWeekFlags.Thursday === (this.repeatDayFlags & DayOfWeekFlags.Thursday)) {
            resultItems.push('Thursday');
        }
        if (DayOfWeekFlags.Friday === (this.repeatDayFlags & DayOfWeekFlags.Friday)) {
            resultItems.push('Friday');
        }
        if (DayOfWeekFlags.Saturday === (this.repeatDayFlags & DayOfWeekFlags.Saturday)) {
            resultItems.push('Saturday');
        }

        return resultItems.join(' ');
    }

    @action
    public setRepeatDays(dayOfWeek: DayOfWeekFlags, checked: boolean) {
        if (checked) {
            this.repeatDayFlags |= dayOfWeek;
        } else {
            this.repeatDayFlags &= ~dayOfWeek;
        }
        this.validate();
    }

    public clone() {
        return new DriverUnavailability({id: this.id,
            reason: this.reason,
            startDate: this.startDate,
            startDispatchTime: this.startDispatchTime,
            endDate: this.endDate,
            endDispatchTime: this.endDispatchTime,
            lineId: this.lineId,
            repeatDayFlags: this.repeatDayFlags
        });
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        'reason': (val: number) => {
            this.errors.reason = required(val);
            
            return this.errors.reason === null;
        },
        'startDate': (val: DateTime) => {
            this.errors.startDate = required(val);

            if (!this.errors.startDate && this.endDate) {
                this.errors.startDate = this.endDate >= val 
                    ? null : 'Start date cannot be greater than End date';
            }
            return this.errors.startDate === null;
        },
        'endDate': (val: DateTime) => {
            this.errors.endDate = required(val);

            return this.errors.endDate === null;
        },
        'endDispatchTime': (val: string) => {
            if (!hasValue(val)) {
                this.errors.endDispatchTime = 'Required';
                return false;
            }

            const endTime = getTime(val);
            if (isNaN(endTime)) {
                this.errors.endDispatchTime = 'not a valid 24hr time (eg. 9:00)';
                return false;    
            }

            const startTime = getTime(this.startDispatchTime);
            if (isNaN(startTime)) {
                this.errors.endDispatchTime = null;
                return true;
            }
            const isRepeat = this.repeatDayFlags !== DayOfWeekFlags.None;
            const isSameDay = this.startDate && this.endDate && this.startDate.toISODate() === this.endDate.toISODate();

            if ((isRepeat || isSameDay) && (endTime - startTime) < 60) {
                this.errors.endDispatchTime = 'minimum window is 1 hr';
                return false;
            }

            this.errors.endDispatchTime = null;
            return this.errors.endDispatchTime === null;
        },
        'startDispatchTime': (val: string) => {
            if (!hasValue(val)) {
                this.errors.startDispatchTime = 'Required';
                return false;
            }

            const startTime = getTime(val);
            if (isNaN(startTime)) {
                this.errors.startDispatchTime = 'not a valid 24hr time (eg. 7:00)';
                return false;
            }

            const endTime = getTime(this.endDispatchTime);
            if (isNaN(endTime)) {
                this.errors.startDispatchTime = null;
                return true;
            }

            const isRepeat = this.repeatDayFlags !== DayOfWeekFlags.None;
            const isSameDay = this.startDate && this.endDate && this.startDate.toISODate() === this.endDate.toISODate();

            if ((isRepeat || isSameDay) && (endTime - startTime) < 60) {
                this.errors.startDispatchTime = 'minimum window is 1 hr'
                return false;
            }

            this.errors.startDispatchTime = null;
            return this.errors.startDispatchTime === null;
        }
    }
}
import { action, computed, observable } from 'mobx'
import { JobType } from '../domain/job';
import { IJobSummary } from '../domain/job-summary';

export class UndoDialogState {
    @observable public jobId: number = 0;
    @observable public jobType: JobType = JobType.Default;
    
    @computed get jobTypeText(): string {
        switch (this.jobType) {
            case JobType.PreLoadPickup:
                return 'Pre-Load';
            case JobType.TransShipDelivery:
                return 'Trans-Ship';
            default:
                return '';
        }
    }

    @action
    public reset() {
        this.jobId = 0;
        this.jobType = JobType.Default;
    }

    @action
    public init(job: IJobSummary) {
        this.jobId = job.id;
        this.jobType = job.type;
    }
}
import * as React from 'react';
import './styles.scss';
import { observer, inject } from 'mobx-react';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { UiStore } from '../../stores/ui-store';
import { ActionItem } from './ActionItem';
import { Loader } from '../Loader/Loader';
import { ScheduleTimeoutOptions } from '../ScheduleTimeoutOptions/ScheduleTimeoutOptions';

export interface IActionsMenuProps {
    uiStore?: UiStore;
}
  
@inject('uiStore')
@observer
export class ActionsMenu extends React.Component<IActionsMenuProps, {}> {
    
    private uiStore?: UiStore;
    private actionHeight: number = 35;

    constructor(props: any) {
        super(props);
        this.toggleActions = this.toggleActions.bind(this);
        this.uiStore = this.props.uiStore;
    }

    public render() {
        return <div className="actions-menu">
            <div className={`icon-container ${(this.uiStore!.actionsMenuActive ? 'active ' : '')}`} 
                onClick={this.toggleActions}
                style={(this.uiStore!.actionsMenuActive) ? { bottom: this.actionMenuButtonBottom } : {}}>
                <ArrowForward className="icon" />
            </div>
            <div className={`actions ${(this.uiStore!.actionsMenuActive ? 'active ' : '')}`}
                style={{height: this.actionContainerHeight}}>
                <Loader area="actions-menu" />
                <div className="items">
                    { this.props.children }
                </div>
            </div>
        </div>
    }

    private toggleActions() {
        this.uiStore!.setActionsMenuActive(!this.uiStore!.actionsMenuActive);
    }

    public componentWillUnmount() {
        this.uiStore!.setActionsMenuActive(false);
    }

    private get numberOfMenuItems() {
        return React.Children.map((this.props.children as React.ReactElement), (child: React.ReactElement<any>) => {
            return (child && (child.type === ActionItem || child.type === ScheduleTimeoutOptions)) ? 1 : 0;
        }).reduce((acc: number, curr: number) => acc + curr, 0);
    }

    private get actionContainerHeight() {
        const paddingItems = 2
        return (this.numberOfMenuItems + paddingItems) * this.actionHeight;
    }

    private get actionMenuButtonBottom() {
        const offSet = 45;
        return (this.numberOfMenuItems * this.actionHeight) + offSet;
    }
}
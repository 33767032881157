import { IDriverSummary } from './domain/driver-summary';
import { DriversApi } from '../services/drivers-api';
import { SuggestionItem } from '../components/Autocomplete/Autocomplete';
import { observable, action, runInAction } from 'mobx';
import { Driver } from './domain/driver';
import notification from '../components/Notification/notification';
import { Unavailability } from './domain/unavailability';
import { TruckType } from './domain/vehicle';
import { ScheduleAvailability } from './domain/schedule-availability';
import { DateTime } from 'luxon';

export class DriverStore {

    @observable public driver: Driver = new Driver({});
    @observable public drivers: IDriverSummary[] = [];

    constructor(private driversApi: DriversApi) { }

    @action
    public async getDrivers(): Promise<IDriverSummary[]> {
        const drivers = await this.driversApi.getDrivers();
        runInAction(() => this.drivers = drivers.map(d => ({
            ...d,
            defaultVehicle: d.defaultVehicle ? {
                ...d.defaultVehicle,
                nextUnavailability: d.defaultVehicle.nextUnavailability.map((u, i) => Unavailability.fromResponse(u, i))
            } : undefined
        })));
        return this.drivers;
    }

    @action
    public async loadDriver(driverId: number): Promise<Driver> {
        const driver = await this.driversApi.getDriver(driverId);
        runInAction(() => {
            this.driver = Driver.fromResponse(driver);
            this.driver.track();
        });
        return this.driver;
    }

    @action
    public async deleteDriver() {
        const ok = await this.driversApi.deleteDriver(this.driver.id);
        if (ok) {
            notification.showInfo('Driver deleted');
            this.unloadDriver();
        }
        return ok;
    }

    @action
    public unloadDriver() {
        this.driver = new Driver({});
    }

    public async searchDrivers(query: string, truckType: TruckType = TruckType.None) {
        const suggestions = await this.driversApi.searchDrivers(query, truckType);
        return suggestions.map(s => {
            return { label: s.name, value: { ...s, currentUnavailability: [] } } as SuggestionItem<IDriverSummary>
        })
    }

    public async getSpareDrivers(vehicleAvailability: ScheduleAvailability, runId: string) {
        const suggestions = await this.driversApi.getSpareDrivers(vehicleAvailability, runId);
        return suggestions.map(r => ScheduleAvailability.fromResponse(r));
    }

    public async getSpareDriversForLive(scheduleId: number, vehicleId: number, startUtc: DateTime) {
        const suggestions = await this.driversApi.getSpareDriversForLive(scheduleId, vehicleId, startUtc);
        return suggestions.map(r => ScheduleAvailability.fromResponse(r));
    }

    public async getSpareDriversForRoute(routeGuid: string, runId: string) {
        const suggestions = await this.driversApi.getSpareDriversForRoute(routeGuid, runId);
        return suggestions.map(r => ScheduleAvailability.fromResponse(r));
    }
    
    public async createDriver() {
        if (!this.driver.validate()) return;
        const ok = await this.driversApi.createDriver(this.driver);
        if (ok) {
            notification.showInfo('Driver created');
            this.driver.untrack();
        }
        return ok;
    }

    public async updateDriver() {
        if (!this.driver.validate()) return;
        const ok = await this.driversApi.updateDriver(this.driver);
        if (ok) {
            notification.showInfo('Driver updated');
            this.driver.untrack();
        }
        return ok;
    }
}
import { observable, action, computed } from 'mobx'
import { DateTime } from 'luxon';
import { ScheduleAvailability } from '../domain/schedule-availability';
import { getTime } from '../../validate';

export class SpareVehicleDialogState {
    private readonly maxStartTime: number = 1200;

    @observable public active: boolean = false;
    @observable public currentMinStartTime: number = 0;
    @observable public currentMaxEndTime: number = 1200;
    @observable public driverIndex: number = -1;
    @observable public vehicleIndex: number = -1;
    @observable public spareDrivers: ScheduleAvailability[] = [];
    @observable public spareVehicles: ScheduleAvailability[] = [];
    @observable public spareVehicleAvailability: ScheduleAvailability = new ScheduleAvailability({});

    @computed
    public get selectedVehicle() {
        if (!this.spareVehicles.length) return;
        return this.spareVehicles[this.vehicleIndex];
    }

    @action
    public init(scheduleId: number, date: DateTime | null) {
        this.spareVehicleAvailability = date === null ? new ScheduleAvailability({ scheduleId }) : new ScheduleAvailability({ scheduleId, dispatchDate: date});
        this.driverIndex = -1;
        this.vehicleIndex = -1;
        this.setSpareDrivers([]);
        this.setSpareVehicles([]);
        this.active = true;
    }

    @action 
    public setSpareDrivers(spareDrivers: ScheduleAvailability[]) {
        this.spareDrivers = spareDrivers;
    }

    @action 
    public setSpareVehicles(spareVehicles: ScheduleAvailability[]) {
        this.spareVehicles = spareVehicles;
    }

    @action
    public setActive(active: boolean) {
        this.active = active;
    }

    @action 
    public updateDriver(driver: ScheduleAvailability, index: number) {
        let driverStartDispatchTime = driver.startDispatchTime;
        let driverStartTimeInMinutes = getTime(driver.startDispatchTime);
        const driverEndTimeInMinutes = getTime(driver.endDispatchTime);

        // Daylight savings, just set to midnight dispatch time
        if (driverStartTimeInMinutes >= this.maxStartTime) {
            driverStartDispatchTime = '00:00';
            driverStartTimeInMinutes = 0;
        }

        if (driverStartTimeInMinutes > this.spareVehicleAvailability.vehicleStartTimeInMinutes) {
            this.spareVehicleAvailability.updateAndValidate('startDispatchTime', driverStartDispatchTime);
        }

        if (driverEndTimeInMinutes < this.spareVehicleAvailability.vehicleEndTimeInMinutes) {
            this.spareVehicleAvailability.updateAndValidate('endDispatchTime', driver.endDispatchTime);
        }

        this.spareVehicleAvailability.driverStartTimeInMinutes = driverStartTimeInMinutes;
        this.spareVehicleAvailability.driverEndTimeInMinutes = driverEndTimeInMinutes;
        this.spareVehicleAvailability.updateAndValidate('driverId', driver.driverId);
        this.spareVehicleAvailability.validate();

        this.driverIndex = index;
    }

    @action 
    public updateVehicle(vehicle: ScheduleAvailability, index: number) {
        let vehicleStartDispatchTime = vehicle.startDispatchTime;
        let vehicleStartTimeInMinutes = getTime(vehicle.startDispatchTime);
        const vehicleEndTimeInMinutes = getTime(vehicle.endDispatchTime);

        // Daylight savings, just set to midnight dispatch time
        if (vehicleStartTimeInMinutes >= this.maxStartTime) {
            vehicleStartDispatchTime = '00:00';
            vehicleStartTimeInMinutes = 0;
        }

        this.spareVehicleAvailability.vehicleStartTimeInMinutes = vehicleStartTimeInMinutes;
        this.spareVehicleAvailability.vehicleEndTimeInMinutes = vehicleEndTimeInMinutes;
        // prevent false validation on driver
        this.spareVehicleAvailability.driverStartTimeInMinutes = vehicleStartTimeInMinutes;
        this.spareVehicleAvailability.driverEndTimeInMinutes = vehicleEndTimeInMinutes;

        this.spareVehicleAvailability.updateAndValidate('vehicleId', vehicle.vehicleId);
        this.spareVehicleAvailability.updateAndValidate('startDispatchTime', vehicleStartDispatchTime);
        this.spareVehicleAvailability.updateAndValidate('endDispatchTime', vehicle.endDispatchTime);
        this.spareVehicleAvailability.validate();

        this.vehicleIndex = index;
    }
}
import { observable, action } from 'mobx';
import { DateTime } from 'luxon';

export enum TimerType {
    None = 0,
    Generating = 1,
    Updating = 2
}

export class ScheduleTimerNotification {
    @observable public active: boolean = false;
    @observable public date: DateTime = DateTime.local().startOf('day');
    @observable public timeOutInSeconds: number = 30;
    @observable public timerType: TimerType = TimerType.None;

    @action
    public close() {
        this.active = false;
    }

    @action
    public show(timeOutInSeconds: number, timerType: TimerType, date: DateTime) {
        this.active = true;
        this.date = date;
        this.timeOutInSeconds = timeOutInSeconds;
        this.timerType = timerType;
    }
}

const scheduleTimerNotification = new ScheduleTimerNotification();
export default scheduleTimerNotification;


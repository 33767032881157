import { action, computed, observable } from 'mobx'
import { AbcReturn } from '../domain/abc-return';
import { DateTime } from 'luxon';
import { Task } from '../domain/task';

export class AbcReturnDialogState {
    @observable public abcReturn: AbcReturn = new AbcReturn({});

    @computed public get minDate(): DateTime {
        return DateTime.local().startOf('day').plus({day: 1});
    }
    
    @action
    public reset() {
        this.abcReturn  = new AbcReturn({ deliveryDate: this.minDate });
    }

    @action
    public init(task: Task, vehicleDepot: string) {
        this.abcReturn = new AbcReturn({
            deliveryDate: this.minDate,
            taskId: task.id,
            vehicleDepot: vehicleDepot
        });
    }
}
import { Model } from './model';
import { moreThanZero, required } from '../../validate';
import { observable, runInAction } from 'mobx';

export class DriverTransfer extends Model {
    @observable public taskGuid: string = '';
    @observable public routeGuid: string = '';
    @observable public runId: string = '';
    @observable public transferLoad: boolean = false;
    @observable public vehicleId: number = 0;
    
    constructor(obj: Partial<DriverTransfer>) {
        super();
        runInAction(() => {
            Object.assign(this, obj);
        } );
    }
    
    protected validators: { [name: string]: (val: any) => boolean } = {
        'routeGuid': (val: string) => {
            this.errors.routeGuid = required(val);
            return this.errors.routeGuid === null;
        },
        'runId': (val: string) => {
            this.errors.runId = required(val);
            return this.errors.runId === null;
        },
        'taskGuid': (val: string) => {
            this.errors.taskGuid = required(val);
            return this.errors.taskGuid === null;
        },
        'vehicleId': (val: number) => {
            this.errors.vehicleId = moreThanZero(val);
            return this.errors.vehicleId === null;
        }
    }
}
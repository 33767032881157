import { observer, inject } from 'mobx-react';
import { ScheduleStore } from '../../stores/schedule-store';
import { UiStore } from '../../stores/ui-store';
import { withStyles, WithStyles, Checkbox, FormControl, InputLabel, 
    Select, MenuItem, createStyles, Theme, TextField, FormHelperText, 
    FormControlLabel, Typography } from '@material-ui/core';
import { globalStyles } from '../../theme';
import React from 'react';
import { VehicleStore } from '../../stores/vehicle-store';
import Dialog from '../Dialog/Dialog';
import { CloseButton, OkButton } from '../Dialog/Buttons/Buttons';
import { IAsFormProps } from '../Form/AsForm';
import { DriverStore } from '../../stores/driver-store';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import tracker from '../Loader/loader-tracker';
import StarIcon from "@material-ui/icons/Star";
import AddIcon from '@material-ui/icons/Add';

interface IScheduleToRouteDialogProps extends WithStyles<typeof styles> {
    scheduleStore?: ScheduleStore
    uiStore?: UiStore
    vehicleStore?: VehicleStore
    driverStore?: DriverStore
}

type Props = IScheduleToRouteDialogProps & IAsFormProps;

@inject('scheduleStore', 'uiStore', 'vehicleStore', 'driverStore')
@observer
class ScheduleToRouteDialog extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.getAvailableRoutes = this.getAvailableRoutes.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    public render() {
        const { scheduleToRouteDialogState } = this.props.uiStore!;
        const { scheduleToRoute: model } = scheduleToRouteDialogState;
        return <React.Fragment>
            <Dialog
                title="Schedule to Route"
                type="slide"
                className={"schedule-to-route-dialog"}
                onClose={() => this.handleCancel()}
                onOpen={async () => await this.getAvailableRoutes()}
                active={scheduleToRouteDialogState.dialogOpen}
                loaderAreaName="assign-dialog">
                {{
                    content: <React.Fragment>
                        <FormControl
                            className={this.props.classes.textFieldFullWidth}>
                            <InputLabel htmlFor="route">Route</InputLabel>
                            <Select
                                disableUnderline
                                value={scheduleToRouteDialogState.routeIndex}
                                onChange={(e) => {
                                    const index = Number(e.target.value);
                                    const selected = scheduleToRouteDialogState.availableRoutes[index];
                                    if (!selected) return;
                                    scheduleToRouteDialogState.updateRoute(selected, index);
                                }}
                                inputProps={{ name: 'route', id: 'route' }}>
                                {
                                    [<MenuItem value={-1} key={-1}>Select Route</MenuItem>]
                                    .concat(scheduleToRouteDialogState.availableRoutes.map((t, i) => (
                                        <MenuItem classes={{root: this.props.classes.menuItemExpand}} value={i} key={i}>
                                            <span>
                                                <div>{`${t.driverName}: ${t.registration}`}</div>
                                                <div className="sub-text">From {t.startDispatchTime} to {t.endDispatchTime}</div>
                                            </span>
                                        </MenuItem>)))
                                }
                            </Select>
                            {
                                model!.errors.driverId
                                && <FormHelperText error>
                                    {model!.errors.route}
                                </FormHelperText>
                            }
                        </FormControl>

                        <TextField
                            label="Job"
                            fullWidth
                            InputProps={{ disableUnderline: true }}
                            value={scheduleToRouteDialogState.job ? scheduleToRouteDialogState.job.orderNumber : ''}
                            disabled
                        />
                        <FormControlLabel
                            className={"checkbox-control"}
                            control={
                                <React.Fragment>
                                    <Checkbox
                                        checked={model.force}
                                        name="force"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                            model.update('force', checked);
                                        }}
                                    />
                                    <StarIcon className={"checkbox-icon"} />                                            
                                </React.Fragment>
                            }
                            label={<Typography className={this.props.classes.checkBoxLabel}>Ignore job pickup and delivery time, vehicle time and distance limits, and driver time limits?</Typography>}
                        />
                        <FormControlLabel
                            className={"checkbox-control"}
                            control={
                                <React.Fragment>
                                    <Checkbox
                                        checked={model.directAdd}
                                        name="directAdd"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                            model.update('directAdd', checked);
                                        }}
                                    />
                                    <AddIcon className={"checkbox-icon"} />                                            
                                </React.Fragment>
                            }
                            label={<Typography className={this.props.classes.checkBoxLabel}>Add directly to the end of the route without rescheduling (ignoring all limits)?</Typography>}
                        />
                   </React.Fragment>,
                    actions: <React.Fragment>
                        <CloseButton onClick={this.handleCancel} />
                        <OkButton onClick={this.handleSave} />
                    </React.Fragment>
                }}

            </Dialog>
            <ConfirmDialog
                active={scheduleToRouteDialogState.confirmDialogOpen}
                title="Confirm Add Job"
                message={`Are you sure you want to try to add the job for Order# ${scheduleToRouteDialogState.job ? scheduleToRouteDialogState!.job.orderNumber : ''} 
                to the schedule of vehicle ${scheduleToRouteDialogState.selectedVehicle}`}
                onClose={() => scheduleToRouteDialogState.toggleConfirmDialog()}
                onOk={this.handleConfirm} />
        </React.Fragment>
    }

    private async getAvailableRoutes() {
        const { scheduleToRouteDialogState } = this.props.uiStore!;
        const jobId = scheduleToRouteDialogState.scheduleToRoute.jobId;
        const routes = await tracker.track(this.props.scheduleStore!.getAvailableRoutes(jobId), 'spare-dialog');
        scheduleToRouteDialogState.setAvailableRoutes(routes);
    }
    
    private handleCancel() {
        const { scheduleToRouteDialogState } = this.props.uiStore!;
        scheduleToRouteDialogState.reset();
    }

    private handleSave() {
        const { scheduleToRouteDialogState } = this.props.uiStore!;

        if (!scheduleToRouteDialogState.scheduleToRoute.validate()) {
            return;
        }
        scheduleToRouteDialogState.toggleConfirmDialog();
    }

    private async handleConfirm() {
        const { scheduleToRouteDialogState } = this.props.uiStore!;
        await tracker.track(this.props.scheduleStore!.scheduleJobToRoute(scheduleToRouteDialogState.scheduleToRoute));

        scheduleToRouteDialogState.reset();
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    menuItemExpand: {
        height: 'auto'
    },
    checkBox: {
        marginTop: -14,
        marginRight: 0
    },
    checkBoxLabel: {
        fontSize: '1rem',
        color: theme.palette.text.secondary
    }
});

export default withStyles(styles)(ScheduleToRouteDialog);